import { Box } from '@veracity/vui'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from '../../../hooks/useDebounce'
import {
  DataGroupListItem,
  useGetDataGroupsQuery
} from '../../../store/services/DataGroupsService'
import DataGroupsManagementHeader from './Header'
import DataGroupsList from './List'
import useDeleteDataGroup from './useDeleteDataGroup'

const DataGroupsManagementPage = () => {
  const navigate = useNavigate()
  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 20
  const form = useForm({ defaultValues: { search: '' } })
  const search = useDebounce(form.watch('search'), 300)

  const { data, isFetching } = useGetDataGroupsQuery({
    search,
    pageNumber,
    pageSize
  })

  const dataGroups = data?.items ?? []
  const total = data?.total ?? 0

  const handleEdit = (dataGroup: DataGroupListItem) => {
    navigate(`/management/data-groups/${dataGroup.id}`)
  }

  const handleDelete = useDeleteDataGroup()

  return (
    <Box column w="100%">
      <FormProvider {...form}>
        <DataGroupsManagementHeader />
      </FormProvider>
      <DataGroupsList
        dataGroups={dataGroups}
        total={total}
        isLoading={isFetching}
        page={pageNumber}
        pageSize={pageSize}
        onPageChange={setPageNumber}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </Box>
  )
}

export default DataGroupsManagementPage
