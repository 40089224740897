import { createApi } from '@reduxjs/toolkit/query/react'
import { UserData } from './UsersService'
import { axiosBaseQuery } from './utils/axiosBaseQuery'

type MyAccountResponse = UserData

const accountApi = createApi({
  reducerPath: 'accountApi',
  tagTypes: ['MyAccount'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/Account/'
  }),
  endpoints: builder => ({
    getMyAccount: builder.query<MyAccountResponse, void>({
      query: () => ({ url: 'MyAccount' }),
      providesTags: ['MyAccount']
    })
  })
})

export const {
  reducerPath: accountApiReducerPath,
  reducer: accountApiReducer,
  middleware: accountApiMiddleware,
  useGetMyAccountQuery
} = accountApi
