import { useMatch, useNavigate } from 'react-router-dom'
import BasicTab from '../BasicTab'

interface NavigationTabProps {
  label: string
  href: string
}
const NavigationTab = ({ label, href }: NavigationTabProps) => {
  const navigate = useNavigate()
  const match = useMatch(href)
  return (
    <BasicTab
      text={label}
      onClick={() => navigate(href)}
      isActive={Boolean(match)}
    />
  )
}

export default NavigationTab
