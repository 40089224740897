import { Box, Button } from '@veracity/vui'
import { useEffect, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import CompactTagList from '../../../../components/CompactTagList'
import useUserGroupPicker from './useUserGroupPicker'

const CustomerKeysFilters = () => {
  const form = useFormContext()
  const openUserGroupPicker = useUserGroupPicker()
  const {
    field: { value: userGroups, onChange }
  } = useController({ name: 'userGroups', control: form.control })
  const [userGroupOptions, setUserGroupOptions] = useState<
    { text: string; value: number }[]
  >([])

  useEffect(() => {
    setUserGroupOptions(current =>
      current.filter(({ value }) => userGroups.includes(value))
    )
  }, [userGroups])

  const handlePickUserGroup = () => {
    openUserGroupPicker(userGroupOptions, 'User Group').then(values => {
      if (values) {
        onChange(values.userGroupIds)
        setUserGroupOptions(values.userGroupOptions)
      }
    })
  }

  const handleClearFilters = () => {
    form.reset({
      userGroups: [],
      search: ''
    })
  }

  return (
    <Box px={8} py={2} alignItems="center" justifyContent="space-between">
      <Box alignItems="center" gap={1}>
        <Button
          text="User Group"
          variant="tertiaryDark"
          bg="#F1F3F5"
          borderRadius={6}
          color="hsl(223,67%,18%)"
          fontWeight={600}
          onClick={handlePickUserGroup}
        />
        <CompactTagList
          tags={userGroupOptions.map(({ text }) => text)}
          tooltipProps={{ placement: 'bottom' }}
        />
      </Box>
      <Button
        text="Clear filters"
        variant="tertiaryDark"
        onClick={handleClearFilters}
      />
    </Box>
  )
}

export default CustomerKeysFilters
