import { useDialog } from '@veracity/vui'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useChangeUserRoleMutation } from '../../../store/services/UsersService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useChangeUserRole = (userId: number) => {
  const [changeUserRole] = useChangeUserRoleMutation()
  const { showConfirm } = useDialog()
  const { setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'change-user-role-' + userId,
      loadingMessage: 'Changing role',
      successMessage: 'Role changed successfully',
      errorMessage: 'Changing role failed'
    })

  return (roleId: number, onCancel?: () => void) => {
    showConfirm(
      'Change user role',
      'Do you want to change this user role?'
    ).then(confirm => {
      if (confirm) {
        setLoading(true)
        changeUserRole({ userId, roleId })
          .then(
            handlePromiseError(
              () => operationSuccess(),
              () => operationFail()
            )
          )
          .finally(() => setLoading(false))
      } else {
        onCancel?.()
      }
    })
  }
}

export default useChangeUserRole
