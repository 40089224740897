import { useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { uploadFile } from '../../../api/files'
import { invalidateContentTags } from '../../../store/services/ContentService'
import { UploadingFileType } from '../../../components/FileList/UploadingFile'
import useContentStructure from '../../../hooks/useContentStructure'

interface UploadingFilesAction {
  type: 'ADD' | 'REMOVE' | 'UPDATE'
  payload: any
}
const uploadingFilesReducer = (
  state: UploadingFileType[],
  action: UploadingFilesAction
) => {
  switch (action.type) {
    case 'ADD':
      return [action.payload, ...state]
    case 'REMOVE':
      return state.filter(file => file.id !== action.payload)
    case 'UPDATE':
      return state.map(file => {
        if (file.id === action.payload.id) {
          return { ...file, ...action.payload }
        }

        return file
      })
    default:
      return state
  }
}

const useFileUpload = () => {
  const dispatch = useDispatch()
  const { tab: activeTab } = useContentStructure()
  const [uploadingFiles, dispatchUploadingFilesAction] = useReducer(
    uploadingFilesReducer,
    []
  )

  return {
    handler: (files: File[]) => {
      const dataToUpload = files.map(file => {
        const fd = new FormData()
        if (activeTab) fd.append('TabId', activeTab.id.toString())
        fd.append('DisplayName', file.name)
        fd.append('Content', file)

        return fd
      })

      dataToUpload.forEach(fd => {
        const id = Math.random()
        const file = fd.get('Content') as File
        dispatchUploadingFilesAction({
          type: 'ADD',
          payload: { id, displayName: file.name, type: file.type, progress: 0 }
        })
        uploadFile(fd, progress =>
          dispatchUploadingFilesAction({
            type: 'UPDATE',
            payload: { id, progress }
          })
        ).then(() => {
          setTimeout(() => {
            dispatchUploadingFilesAction({ type: 'REMOVE', payload: id })
            dispatch(
              invalidateContentTags([{ type: 'TabContent', id: activeTab?.id }])
            )
          }, 1000)
        })
      })
    },
    uploadingFiles
  }
}

export default useFileUpload
