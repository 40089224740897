import { Box, Button, Heading } from '@veracity/vui'
import { useState } from 'react'
import CollapseWrapper from '../../../../../components/CollapseWrapper'
import {
  ContentTreeSection,
  UserAccess
} from '../../../../../store/services/ContentAccessService'
import SubSectionAccessTree from './SubSectionAccessTree'
import TabNode from './TabNode'

interface SectionAccessTreeProps {
  countryId: number
  chapterId: number
  section: ContentTreeSection
  userAccess: UserAccess[]
  globalAccess: boolean
}
const SectionAccessTree = ({
  countryId,
  chapterId,
  section,
  userAccess,
  globalAccess
}: SectionAccessTreeProps) => {
  const [collapsed, setCollapsed] = useState(true)

  const parentAccess = userAccess.some(
    ua =>
      ua.countryId === countryId &&
      ua.chapterId === chapterId &&
      !ua.sectionId &&
      !ua.subSectionId &&
      !ua.tabId
  )
  const disabled = !(
    globalAccess ||
    parentAccess ||
    userAccess.some(ua => ua.sectionId === section.sectionId)
  )

  return (
    <Box column>
      <Box alignItems="center" gap={0.5}>
        <Button
          size="sm"
          icon="falChevronDown"
          variant="tertiaryDark"
          transform={`rotate(${collapsed ? '0deg' : '180deg'})`}
          isRound
          onClick={() => setCollapsed(state => !state)}
          disabled={disabled}
        />
        <Heading
          size="h5"
          text={section.title}
          color={disabled ? 'grey.40' : undefined}
        />
      </Box>
      <CollapseWrapper column collapsed={collapsed}>
        <Box column ml={4}>
          {section.tabs.map(tab => (
            <TabNode
              key={tab.tabId}
              countryId={countryId}
              chapterId={chapterId}
              sectionId={section.sectionId}
              tab={tab}
              userAccess={userAccess}
            />
          ))}
          {section.subSections.map(subSection => (
            <SubSectionAccessTree
              key={subSection.subSectionId}
              countryId={countryId}
              chapterId={chapterId}
              sectionId={section.sectionId}
              subSection={subSection}
              userAccess={userAccess}
              globalAccess={parentAccess || globalAccess}
            />
          ))}
        </Box>
      </CollapseWrapper>
    </Box>
  )
}

export default SectionAccessTree
