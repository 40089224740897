import { Box, Card, Grid, Heading, Icon, P } from '@veracity/vui'
import useContentStructure, {
  StructureLevel
} from '../../../hooks/useContentStructure'
import { ChapterType, ContentTab } from '../../../store/services/ContentService'

interface EmptyStructureProps {
  onTabCreate: (tab: Partial<ContentTab>) => void
  onChildCreate: () => void
}
const EmptyStructure = ({
  onTabCreate,
  onChildCreate
}: EmptyStructureProps) => {
  const { level, chapter } = useContentStructure()
  const addChildHeader = {
    [StructureLevel.Chapter]: 'Add sections to chapter',
    [StructureLevel.Section]: 'Add subsections to section',
    [StructureLevel.Subsection]: ''
  }[level ?? StructureLevel.Subsection]
  const addChildDescription = {
    [StructureLevel.Chapter]:
      'Sections enable chapters to have distinct parts which may be further divided into sub sections',
    [StructureLevel.Section]:
      'Subsections enable sections to have distinct parts',
    [StructureLevel.Subsection]: ''
  }[level ?? StructureLevel.Subsection]

  const isFlatChapter = chapter?.type === ChapterType.Files

  const handleTabCreate = () => {
    onTabCreate({ title: 'New tab' })
  }

  return (
    <Box column bg="white" w="calc(100% - 64px)" m={4}>
      <Box column alignItems="center" pt={16} pb={14}>
        <Heading size="h4" text={`This ${level} is empty`} />
        <P size="lg" text={`Decide the structure of this ${level}`} />
      </Box>
      <Grid px={8} pb={4} gridTemplateColumns={2} gap={2}>
        {!isFlatChapter && ['chapter', 'section'].includes(level ?? '') && (
          <Card
            isInteractive
            cursor="pointer"
            column
            p={2}
            onClick={onChildCreate}>
            <Box alignItems="center" justifyContent="space-between">
              <Heading size="h5" text={addChildHeader} />
              <Icon name="falChevronRight" />
            </Box>
            <P pt={3} text={addChildDescription} />
          </Card>
        )}
        <Card
          isInteractive
          cursor="pointer"
          column
          p={2}
          onClick={handleTabCreate}>
          <Box alignItems="center" justifyContent="space-between">
            <Heading size="h5" text={`Add tabs to ${level}`} />
            <Icon name="falChevronRight" />
          </Box>
          <P
            pt={3}
            text="Tabs are used when a chapter does not need to be divided into distinct parts"
          />
        </Card>
      </Grid>
    </Box>
  )
}

export default EmptyStructure
