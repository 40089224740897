import { Box, Heading, Icon, Menu, useDialog } from '@veracity/vui'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import {
  ContentLocation,
  ContentTab as ContentTabType
} from '../store/services/ContentService'
import { contentActions } from '../store/slices/content'
import { useMovePrompt } from './MovePrompt'
import UpdateIndicator from './UpdateIndicator'
const PRIMARY = '#0f214d'
const WHITE = '#ffffff'

interface ContentTabProps {
  isActive: boolean
  tab: ContentTabType
  countryCode?: string
  onClick: (tab: ContentTabType) => void
  onDelete?: (tab: ContentTabType) => void
  onEdit?: (tab: ContentTabType) => void
  onMove?: (tab: ContentTabType, location: ContentLocation) => void
  onCopyTo?: (tab: ContentTabType, location: ContentLocation) => void
  onClone?: (tab: ContentTabType) => void
  onPublish?: (tab: ContentTabType) => void
}
const ContentTab = ({
  isActive,
  tab,
  countryCode,
  onClick,
  onDelete,
  onEdit,
  onMove,
  onCopyTo,
  onClone,
  onPublish
}: ContentTabProps) => {
  const { isAccessible, modificationStatus } = tab
  const dispatch = useDispatch()
  const isEdit = Boolean(onDelete) || Boolean(onEdit)
  const { showConfirm } = useDialog()
  const showMovePrompt = useMovePrompt(countryCode)
  const isViewed = useSelector<RootState, boolean>(state =>
    state.content.viewedTabs.includes(tab.id)
  )

  const handleEdit = () => {
    if (onEdit) {
      onEdit(tab)
    }
  }

  const handleDelete = () => {
    if (onDelete) {
      showConfirm(
        'Delete tab',
        'Are you sure you want to remove this tab?'
      ).then(response => {
        if (response) {
          onDelete(tab)
        }
      })
    }
  }

  const handleClone = () => {
    if (onClone) {
      showConfirm('Clone tab', 'Are you sure you want to clone this tab?').then(
        response => {
          if (response) {
            onClone(tab)
          }
        }
      )
    }
  }

  const handleMove = () => {
    if (onMove) {
      showMovePrompt('Move tab to').then(location => {
        if (location) {
          onMove(tab, location)
        }
      })
    }
  }

  const handleCopyTo = () => {
    if (onCopyTo) {
      showMovePrompt('Copy tab to').then(location => {
        if (location) {
          onCopyTo(tab, location)
        }
      })
    }
  }

  const handlePublish = () => {
    if (onPublish) {
      onPublish(tab)
    }
  }

  const handleClick = () => {
    onClick(tab)
    dispatch(contentActions.setViewedTab(tab.id))
  }

  return (
    <Box
      bg={isAccessible ? (isActive ? PRIMARY : WHITE) : 'darkgrey'}
      px={1}
      h={32}
      alignItems="center"
      radius={15}
      onClick={handleClick}
      cursor="pointer">
      <UpdateIndicator
        isViewed={isViewed || isEdit}
        status={modificationStatus}>
        <Heading
          text={tab.title}
          size="h6"
          color={isActive ? WHITE : PRIMARY}
        />
      </UpdateIndicator>
      {isAccessible ? <></> : <Icon ml={1} name="falLockAlt" h={15} w={15} />}
      {isEdit && (
        <Box onClick={e => e.stopPropagation()}>
          <Menu>
            <Menu.Button
              icon="falChevronDown"
              size="sm"
              isRound
              variant={isActive ? 'tertiaryLight' : 'tertiaryDark'}
            />
            <Menu.List>
              {onPublish && (
                <Menu.Item iconLeft="culGlobePlus" onClick={handlePublish}>
                  Publish
                </Menu.Item>
              )}
              {onEdit && (
                <Menu.Item iconLeft="falPen" onClick={handleEdit}>
                  Edit
                </Menu.Item>
              )}
              {onClone && (
                <Menu.Item iconLeft="falCopy" onClick={handleClone}>
                  Clone
                </Menu.Item>
              )}
              {onMove && (
                <Menu.Item iconLeft="falShare" onClick={handleMove}>
                  Move to
                </Menu.Item>
              )}
              {onCopyTo && (
                <Menu.Item iconLeft="falShareAll" onClick={handleCopyTo}>
                  Copy to
                </Menu.Item>
              )}
              {onDelete && (
                <Menu.Item iconLeft="falTrashAlt" onClick={handleDelete}>
                  Delete
                </Menu.Item>
              )}
            </Menu.List>
          </Menu>
        </Box>
      )}
    </Box>
  )
}

export default ContentTab

