import ReactDOM from 'react-dom'
import ChartRenderer from '../../ChartRenderer'
import { ChartConfig } from '../../ChartRenderer/types'
import * as R from 'ramda'

interface ChartRenderProps {
  config: ChartConfig
  downloadLink?: string
  dataProvider: {
    dataSourceId?: number
    raw?: string[][]
  }
}
class ReactChartRenderer {
  private lastProps: ChartRenderProps | null = null
  private timeout?: NodeJS.Timeout
  private data: string[][] = []

  constructor(
    private rootSelector: string,
    private fetchDataSource: (
      id: number,
      cache: boolean
    ) => Promise<{ data: string[][] }>,
    private debounce: number
  ) {}

  private renderComponent(
    config: ChartConfig,
    loading?: boolean,
    downloadLink?: string
  ) {
    const root = document.querySelector(this.rootSelector)

    if (!root) return

    ReactDOM.render(
      <ChartRenderer
        config={config}
        data={this.data}
        loading={loading}
        downloadLink={downloadLink}
      />,
      root
    )
  }

  public render(props: ChartRenderProps) {
    if (props.dataProvider.raw) {
      this.data = props.dataProvider.raw
    }

    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (!this.shouldRender(props)) return

      const root = document.querySelector(this.rootSelector)

      if (!root) return

      if (this.shouldFetch(props)) {
        this.renderComponent(props.config, true)
        this.fetchDataSource(props.dataProvider.dataSourceId!, true).then(
          ({ data }) => {
            this.data = data
            this.renderComponent(props.config, false, props.downloadLink)
          }
        )
      } else {
        this.renderComponent(props.config, false, props.downloadLink)
      }
      this.lastProps = props
    }, this.debounce)
  }

  private shouldRender(newProps: ChartRenderProps) {
    const root = document.querySelector(this.rootSelector)
    if (root && !root.innerHTML) return true

    return !R.equals(newProps, this.lastProps)
  }

  private shouldFetch(newProps: ChartRenderProps) {
    if (!newProps.dataProvider.dataSourceId) return false

    return (
      newProps.dataProvider.dataSourceId !==
      this.lastProps?.dataProvider.dataSourceId
    )
  }
}

interface ChartPluginConfig {
  id: string | number
  height: string | number
  width: string | number
  chart?: ChartConfig
  dataProvider?: {
    raw?: string[][]
    dataSourceId?: number
  }
}

const getWidth = (config: ChartPluginConfig | null) => {
  if (!config || !config.width) return undefined

  if (typeof config.width === 'number') return `width: ${config.width}px;`

  return `width: ${config.width};`
}

const getHeight = (config: ChartPluginConfig | null) => {
  if (!config || !config.height) return undefined

  if (typeof config.height === 'number') return `height: ${config.height}px;`

  return `height: ${config.height};`
}

interface ChartPluginProps {
  fetchDataSource: (id: number, cache: boolean) => Promise<any>
  debounce?: number
}
const chartPlugin =
  ({ fetchDataSource, debounce = 0 }: ChartPluginProps) =>
  () => {
    const renderers: Record<string | number, ReactChartRenderer> = {}

    return {
      toHTMLRenderers: {
        chart(node: any) {
          let config: ChartPluginConfig | null = null
          try {
            config = JSON.parse(node.literal.trim())
          } catch (e: any) {
            config = null
          }

          if (config?.id !== undefined) {
            let currentRenderer = renderers[config.id]

            if (!currentRenderer) {
              const newRenderer = new ReactChartRenderer(
                `[data-chart-id="${config.id}"]`,
                fetchDataSource,
                debounce
              )
              renderers[config.id] = newRenderer
              currentRenderer = newRenderer
            }

            if (config && config.dataProvider?.dataSourceId && config.chart) {
              currentRenderer.render({
                downloadLink: `/api/DataSource/${
                  config!.dataProvider!.dataSourceId
                }/File`,
                config: config.chart,
                dataProvider: config.dataProvider
              })
            } else if (config && config.dataProvider && config.chart) {
              currentRenderer.render({
                dataProvider: config.dataProvider,
                config: config.chart
              })
            }
          }

          const style = [
            'display: inline-block;',
            getWidth(config),
            getHeight(config),
            'position: relative;',
            'overflow-x: auto;',
            'padding: 1px;'
          ]
            .filter(Boolean)
            .join(' ')

          return [
            {
              type: 'openTag',
              tagName: 'figure',
              outerNewLine: true,
              attributes: {
                class: 'chart-figure',
                'data-chart-id': config?.id ?? 'error',
                style
              }
            },
            { type: 'closeTag', tagName: 'figure', outerNewLine: true }
          ]
        }
      }
    }
  }

export default chartPlugin
