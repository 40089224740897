import { Box, Heading, Image } from '@veracity/vui'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../../store'
import CountryControl from './CountryControl'

const NavCountry = () => {
  const { country } = useParams()
  const countryName = useSelector(
    (state: RootState) => state.content.countryInfo?.name
  )
  const navigate = useNavigate()

  const handleAddChapter = () => {
    if (!country) return

    navigate(`/power-analytics/edit/${country}/add-chapter`)
  }

  return (
    <Box
      pl={2.5}
      pr={1}
      h={80}
      alignItems="center"
      justifyContent="space-between"
      borderBottom="solid 1px"
      borderBottomColor="#E1E6E9">
      <Box alignItems="center">
        {country ? (
          <Image
            h={32}
            w={50}
            src={`/assets/flags/${country}.png`}
            borderRadius={2}
            boxShadow="0px 0px 6px 0px rgba(105, 128, 145, 0.2)"
          />
        ) : (
          <Box h={32} w={50} bg="grey.30" borderRadius={2} overflow="hidden">
            <Box h={32} w={17} bg="grey.70" />
            <Box h={32} w={16} bg="grey.50" />
            <Box h={32} w={17} bg="grey.30" />
          </Box>
        )}
        <Heading
          maxW={125}
          ml={1.5}
          size="h5"
          text={countryName}
          textTransform="uppercase"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        />
      </Box>
      <Box>
        <CountryControl onAddChapter={handleAddChapter} />
      </Box>
    </Box>
  )
}

export default NavCountry

