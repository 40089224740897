import { Box, Spinner } from '@veracity/vui'
import { useEffect } from 'react'
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import Editor from '../../../components/editor/Editor'
import useContentStructure from '../../../hooks/useContentStructure'
import EditHeader from './Header'
import EditTabs from './Tabs'
import { EditorControlsProvider } from '../../../components/editor/useEditorControls'
import EmptyStructure from './EmptyStructure'
import FileListEditor from '../../../components/FileList/Editor'
import {
  ContentTab,
  TabContent,
  useGetTabContentQuery
} from '../../../store/services/ContentService'
import useTabDelete from './useTabDelete'
import useTabCreate from './useTabCreate'
import useTabEdit from './useTabEdit'
import useFileDelete from './useFileDelete'
import useFileRename from './useFileRename'
import useTabClone from './useTabClone'
import useTabMove from './useTabMove'
import useTabCopy from './useTabCopy'
import useFileUpload from './useFileUpload'
import TabForm from './TabForm'
import { useFormModal } from '../../../hooks/useFormModal'
import { Group } from '../../../store/services/GroupsService'

const PowerAnalyticsEditPage = () => {
  const navigate = useNavigate()
  const {
    tabs = [],
    chapter,
    section,
    subsection,
    pathToFirst,
    exist,
    pathToParent,
    tab
  } = useContentStructure()
  const [, setURLSearchParams] = useSearchParams()
  const { country } = useParams()
  const activeTabId = tab?.id
  const noActiveTab = !tab
  const { data: content, isFetching: contentIsLoading } = useGetTabContentQuery(
    activeTabId!,
    { skip: noActiveTab }
  )
  const markdownContent = content && content.markdown !== null
  const filesContent = content && content.files !== null

  const openForm = useFormModal<
    { title: string; groupsId: number[]; groups?: Group[] } | undefined
  >((data, onChange) => {
    const { groups = [], ...initValues } = data ?? { title: '', groupsId: [] }
    return (
      <TabForm
        initOptions={groups.map(group => ({
          value: group.id,
          text: group.name
        }))}
        initValues={initValues}
        onChange={onChange}
      />
    )
  })

  useEffect(() => {
    if (!exist && country) {
      navigate(`/power-analytics/edit/${country}/${pathToParent}`)
    }
  }, [exist, pathToParent, navigate, country])

  const noTabs = !tabs.length

  const handleTabChange = ({ slugId }: ContentTab) => {
    setURLSearchParams({ tab: slugId })
  }

  const handleTabDelete = useTabDelete()

  const tabCreate = useTabCreate(chapter?.id, section?.id, subsection?.id)

  const handleTabCreate = () => {
    openForm(undefined, 'Create tab').then(values => {
      if (!values) return

      tabCreate(values)
    })
  }

  const tabEdit = useTabEdit()

  const handleTabEdit = (tab: ContentTab) => {
    openForm(
      { ...tab, groupsId: tab.groups?.map(group => group.id) ?? [] },
      'Edit tab'
    ).then(values => {
      if (!values) return

      tabEdit({ ...tab, ...values })
    })
  }

  const handleChildCreate = () => {
    if (country && chapter && section) {
      navigate(
        `/power-analytics/edit/${country}/${chapter.slugId}/${section.slugId}/add-subsection`
      )
    } else if (country && chapter) {
      navigate(`/power-analytics/edit/${country}/${chapter.slugId}/add-section`)
    }
  }

  const { handler: handleUploadFiles, uploadingFiles } = useFileUpload()

  const handleDeleteFile = useFileDelete()

  const handleRenameFile = useFileRename()

  const handleContentCompare = (currentContent: TabContent) => {
    return {
      equalMarkdown: content?.markdown === currentContent.markdown,
      equalFiles: true
    }
  }

  const handleTabClone = useTabClone()

  const handleTabMove = useTabMove()

  const handleTabCopyTo = useTabCopy()

  if (pathToFirst) return <Navigate to={pathToFirst} />

  return (
    <Box column w="100%">
      <EditorControlsProvider>
        <EditHeader actions={markdownContent ? undefined : <div />} />
        <Box column w="100%" alignItems="center" overflow="auto">
          {!noTabs && (
            <>
              <EditTabs
                tabs={tabs}
                onContentCompare={handleContentCompare}
                onTabChange={handleTabChange}
                onTabDelete={handleTabDelete}
                onTabCreate={handleTabCreate}
                onTabEdit={handleTabEdit}
                onTabClone={filesContent ? undefined : handleTabClone}
                onTabMove={filesContent ? undefined : handleTabMove}
                onTabCopyTo={filesContent ? undefined : handleTabCopyTo}
              />
              <Box column bg="white" px={2} py={2} w="calc(100% - 64px)" mb={4}>
                {contentIsLoading && <Spinner size="md" />}
                {!contentIsLoading && markdownContent && (
                  <Editor content={content} />
                )}
                {!contentIsLoading && content && filesContent && (
                  <FileListEditor
                    content={content}
                    uploadingFiles={uploadingFiles}
                    onUpload={handleUploadFiles}
                    onDelete={handleDeleteFile}
                    onRename={handleRenameFile}
                  />
                )}
              </Box>
            </>
          )}
          {noTabs && (
            <EmptyStructure
              onTabCreate={handleTabCreate}
              onChildCreate={handleChildCreate}
            />
          )}
        </Box>
      </EditorControlsProvider>
    </Box>
  )
}

export default PowerAnalyticsEditPage

