import { Box, BoxProps, Tag, Tooltip, TooltipProps } from '@veracity/vui'
import { ReactNode } from 'react'
import { ModificationStatus } from '../../store/services/ContentService'

interface UpdateIndicatorProps extends BoxProps {
  isViewed: boolean
  status: ModificationStatus
  children: ReactNode
  tooltipProps?: TooltipProps
}
const UpdateIndicator = ({
  isViewed,
  children,
  status,
  tooltipProps,
  ...props
}: UpdateIndicatorProps) => {
  const isNew = status === ModificationStatus.IsNew
  const hasUpdates = status === ModificationStatus.HasUpdates

  return (
    <Box alignItems="center" gap={1} {...props}>
      {!isViewed && (
        <>
          {hasUpdates && (
            <Tooltip text="recently updated" {...tooltipProps}>
              <Box
                w={11}
                h={11}
                borderRadius="50%"
                borderColor="#1D71E8"
                bg="#77BAF9"
                borderWidth={1}
              />
            </Tooltip>
          )}
          {isNew && (
            <Tag minW={38} size="xs" text="New" variant="subtleGreen" />
          )}
        </>
      )}
      {children}
    </Box>
  )
}

export default UpdateIndicator
