export const handlePromiseError =
  <SuccessResponse = unknown, ErrorResponse = unknown>(
    onSuccess: (response: { data: SuccessResponse }) => void,
    onError: (response: { error: ErrorResponse }) => void
  ) =>
  (response: { data: SuccessResponse } | { error: ErrorResponse }) => {
    const successResponse = response as { data: SuccessResponse }
    const errorResponse = response as { error: ErrorResponse }
    if (errorResponse.error) {
      onError(errorResponse)
    } else {
      onSuccess(successResponse)
    }
  }
