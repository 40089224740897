import { Box, Heading } from '@veracity/vui'
import { useSelector } from 'react-redux'
import BackgroundBox from '../../components/Styled/BackgroundBox'
import { RootState } from '../../store'
import ManagementSection from './ManagementSection'
import PowerAnalyticsSection from './PowerAnalyticsSection'

const SignedInUserHome = () => {
  const username = useSelector<RootState, string>(state =>
    [state.user.userInfo?.firstName, state.user.userInfo?.lastName]
      .filter(Boolean)
      .join(' ')
  )

  return (
    <>
      <BackgroundBox url="/assets/images/jumbotron-small.jpg" ratio={0.17}>
        <Box position="absolute" bottom="24px" column w="100%">
          <Heading
            align="center"
            color="white"
            size="h2"
            text={`Welcome, ${username}.`}
          />
        </Box>
      </BackgroundBox>
      <Box center>
        <Box w="100%" maxW={1048} column>
          <ManagementSection />
          <PowerAnalyticsSection />
        </Box>
      </Box>
    </>
  )
}

export default SignedInUserHome
