import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { UserRole } from '../store/services/UsersService'

const usePermissions = () => {
  const userRole = useSelector((state: RootState) => state.user.role)
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn)
  const isCustomer = userRole?.roleId === UserRole.Customer
  const isContentManager = userRole?.roleId === UserRole.ContentManager
  const isKeymaster = userRole?.roleId === UserRole.Keymaster
  const isOneOf = useCallback(
    (roles: UserRole[]) =>
      userRole ? roles.includes(userRole?.roleId) : false,
    [userRole]
  )

  return {
    isLoggedIn,
    isCustomer,
    isContentManager,
    isKeymaster,
    isOneOf
  }
}

export default usePermissions
