import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import {
  UserAccess,
  useUpsertUserAccessMutation
} from '../../../store/services/ContentAccessService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useUpsertAccess = () => {
  const [upsertAccess] = useUpsertUserAccessMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'grant-access',
      loadingMessage: 'Granting access',
      successMessage: 'Access granted successfully',
      errorMessage: 'Granting access failed'
    })

  return (userIds: number[], items: UserAccess[]) => {
    if (isLoading) return

    setLoading(true)
    upsertAccess({ userIds, items })
      .then(
        handlePromiseError(
          () => operationSuccess(),
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default useUpsertAccess
