import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useGetMyAccountQuery } from '../store/services/AccountService'
import { userActions } from '../store/slices/user'

const useUserSession = () => {
  const dispatch = useDispatch()
  const { isLoading, isFetching, isUninitialized, isSuccess, data } =
    useGetMyAccountQuery()

  const shouldShowLoader = isLoading || isFetching || isUninitialized

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(userActions.setUser(data))
    } else {
      dispatch(userActions.setUser(null))
    }
  }, [dispatch, isSuccess, data])

  useEffect(() => {
    dispatch(userActions.setLoading(shouldShowLoader))
  }, [dispatch, shouldShowLoader])
}

export default useUserSession
