import { Box } from '@veracity/vui'
import ManualDataSourceForm from './ManualDataSourceForm'

const ManualDataSource = () => {
  return (
    <Box w="100%">
      <ManualDataSourceForm />
    </Box>
  )
}

export default ManualDataSource
