import { useDialog } from '@veracity/vui'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useDeleteSubsectionMutation } from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useDeleteSubSection = (subSectionId?: number) => {
  const { showConfirm } = useDialog()
  const [deleteSubsection] = useDeleteSubsectionMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'subsection-delete',
      loadingMessage: 'Deleting sub section',
      successMessage: 'Sub section deleted successfully',
      errorMessage: 'Deleting sub section failed'
    })

  return () => {
    if (!subSectionId || isLoading) return

    showConfirm(
      'Delete sub section',
      'Are you sure you want to delete this sub section?'
    ).then(agree => {
      if (agree) {
        setLoading(true)
        deleteSubsection(subSectionId)
          .then(
            handlePromiseError(
              () => operationSuccess(),
              () => operationFail()
            )
          )
          .finally(() => setLoading(false))
      }
    })
  }
}

export default useDeleteSubSection
