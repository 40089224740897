import { Box, Button, Image } from '@veracity/vui'
import CountryStatus from '../../../components/CountryCard/CountryStatus'
import Table from '../../../components/Table'
import { Country } from '../../../store/services/CountryService'

interface CountriesListProps {
  countries: Country[]
  isLoading: boolean
  onDelete: (id: number) => void
  onEdit: (id: number) => void
}
const CountriesList = ({
  countries,
  isLoading,
  onDelete,
  onEdit
}: CountriesListProps) => {
  const dataSource = countries.map(country => ({ key: country.id, ...country }))

  return (
    <Box column px={8} py={1.5}>
      <Table
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true
        }}
        columns={[
          {
            title: 'Country name',
            dataIndex: 'name',
            key: 'name',
            className: 'bold',
            sorter: (a, b) => a.name.localeCompare(b.name)
          },
          {
            title: 'Region',
            dataIndex: ['region', 'name'],
            key: 'region.name'
          },
          {
            title: 'Flag',
            dataIndex: 'code',
            key: 'code',
            render: code => (
              <Image
                src={`/assets/flags/${code}.png`}
                h={33}
                w={52}
                borderRadius={6}
                boxShadow="0px 0px 6px 0px rgba(105, 128, 145, 0.2)"
              />
            )
          },
          {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: state => <CountryStatus status={state} showComplete />
          },
          {
            title: null,
            dataIndex: 'id',
            key: 'actions',
            width: 32,
            render: id => (
              <Box alignItems="center" gap={1}>
                <Button
                  variant="tertiaryDark"
                  isRound
                  icon="falPen"
                  className="show-on-row-hover"
                  onClick={() => onEdit(id)}
                />
                <Button
                  variant="subtleRed"
                  isRound
                  icon="falTrashAlt"
                  className="show-on-row-hover"
                  onClick={() => onDelete(id)}
                />
              </Box>
            )
          }
        ]}
        dataSource={dataSource}
      />
    </Box>
  )
}

export default CountriesList
