import { Box, Button, Divider, Heading } from '@veracity/vui'
import React, { ReactNode } from 'react'

interface ContentHeaderProps {
  onBack?: () => void
  displayPath: string[]
  actions?: ReactNode
  variant?: 'light' | 'dark'
}
const ContentHeader = ({
  onBack,
  displayPath,
  actions,
  variant = 'light'
}: ContentHeaderProps) => {
  return (
    <Box
      minH={64}
      bg={variant === 'light' ? 'white' : '#F1F3F5'}
      px={8}
      alignItems="center"
      justifyContent="space-between">
      <Box alignItems="center" gap={2}>
        {onBack && (
          <>
            <Button
              icon="falArrowLeft"
              onClick={onBack}
              variant="tertiaryDark"
              isRound
            />
            <Divider isVertical h={24} width={2} />
          </>
        )}
        {displayPath.map((text, index) => (
          <React.Fragment key={text + index}>
            <Heading size="h3" text={text} fontWeight={600} />
            {index < displayPath.length - 1 && (
              <Divider isVertical h={24} width={2} />
            )}
          </React.Fragment>
        ))}
      </Box>
      <Box>{actions}</Box>
    </Box>
  )
}

export default ContentHeader
