import { Tag, TagProps } from '@veracity/vui'
import { CountryState } from '../../store/services/CountryService'

interface CountryStatusProps extends TagProps {
  status: CountryState
  showComplete?: boolean
}

const CountryStatus = ({
  status,
  showComplete,
  ...props
}: CountryStatusProps) => {
  switch (status) {
    case CountryState.Complete:
      return showComplete ? (
        <Tag variant="subtleGreen" size="sm" {...props}>
          Complete
        </Tag>
      ) : (
        <div />
      )
    case CountryState.ComingSoon:
      return (
        <Tag variant="subtleYellow" size="sm" {...props}>
          Coming soon
        </Tag>
      )
    case CountryState.RestrictedAccess:
      return (
        <Tag variant="subtleRed" size="sm" {...props}>
          Restricted access
        </Tag>
      )
    default:
      return <div />
  }
}

export default CountryStatus

