import { Box } from '@veracity/vui'
import { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDebounce } from '../../../hooks/useDebounce'
import { useGetCountriesListQuery } from '../../../store/services/CountryService'
import CountriesManagementHeader from './Header'
import CountriesList from './List'
import useCreateCountry from './useCreateCountry'
import useDeleteCountry from './useDeleteCountry'
import useEditCountry from './useEditCountry'

const CountriesManagementPage = () => {
  const form = useForm({
    defaultValues: {
      search: ''
    }
  })

  const search = useDebounce(form.watch('search'), 300)

  const { data, isFetching } = useGetCountriesListQuery()

  const countries = useMemo(
    () =>
      data?.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      ) ?? [],
    [search, data]
  )

  const handleCreate = useCreateCountry()
  const handleEdit = useEditCountry()
  const handleDelete = useDeleteCountry()

  return (
    <Box column w="100%">
      <FormProvider {...form}>
        <CountriesManagementHeader onCreate={handleCreate} />
      </FormProvider>
      <CountriesList
        countries={countries}
        isLoading={isFetching}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Box>
  )
}

export default CountriesManagementPage
