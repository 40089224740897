import { Box, Button, Heading, Icon, Tag } from '@veracity/vui'
import type { Key } from 'antd/es/table/interface'
import dayjs from 'dayjs'
import { useController, useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'
import FormInput from '../../../components/form/FormInput'
import Table from '../../../components/Table'
import { Group } from '../../../store/services/GroupsService'
import { UserData } from '../../../store/services/UsersService'
import { formatDateTime } from '../../../utils/format'

interface CustomerListProps {
  customers: UserData[]
  isLoading: boolean
}
const CustomerList = ({ customers, isLoading }: CustomerListProps) => {
  const form = useFormContext()
  const {
    field: { value, onChange }
  } = useController({ name: 'users', control: form.control, defaultValue: [] })

  const dataSource = customers.map(customer => ({
    ...customer,
    key: customer.id
  }))

  const handleUserCheck = (checkedKeys: Key[]) => {
    onChange(checkedKeys)
  }

  return (
    <Box column>
      <Box py={1} justifyContent="space-between" alignItems="center">
        <Heading text="Users" weight="600" size="h4" />
        <FormInput
          size="md"
          name="search"
          control={form.control}
          label=""
          iconLeft={<Icon name="falSearch" color="#698091" h={16} ml={0.5} />}
          borderRadius={6}
        />
      </Box>
      <Table
        loading={isLoading}
        style={{ width: '100%' }}
        rowSelection={{
          type: 'checkbox',
          onChange: handleUserCheck,
          selectedRowKeys: value
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: ['userInfo', 'displayName'],
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            className: 'bold'
          },
          {
            title: 'Email',
            dataIndex: ['userInfo', 'email'],
            key: 'email',
            sorter: true
          },
          {
            title: 'Group',
            dataIndex: 'groups',
            key: 'groups',
            render: value => (
              <Box gap={1}>
                {value.map((group: Group) => (
                  <Tag key={group.id} text={group.name} />
                ))}
              </Box>
            )
          },
          {
            title: 'Role',
            dataIndex: ['role', 'displayName'],
            key: 'role'
          },
          {
            title: 'Last login',
            dataIndex: 'lastLoginTime',
            key: 'lastLogin',
            sorter: (a, b) =>
              dayjs(a.lastLoginTime).isBefore(dayjs(b.lastLoginTime)) ? -1 : 1,
            render: value => formatDateTime(value)
          },
          {
            title: null,
            dataIndex: 'id',
            key: 'actions',
            width: 32,
            render: id => (
              <Link to={`/management/users/${id}`}>
                <Button
                  variant="tertiaryDark"
                  isRound
                  icon="falGlassesAlt"
                  className="show-on-row-hover"
                />
              </Link>
            )
          }
        ]}
        dataSource={dataSource}
      />
    </Box>
  )
}

export default CustomerList

