import { Input, InputProps } from '@veracity/vui'
import { Control, useController } from 'react-hook-form'
import FormLabel from './FormLabel'

interface FormInputProps extends InputProps {
  name: string
  label: string
  control: Control<any>
}
const FormInput = ({ name, label, control, ...props }: FormInputProps) => {
  const {
    field: { value, onChange }
  } = useController({ name, control })

  return (
    <FormLabel text={label} w={props.w}>
      <Input {...props} value={value} onChange={onChange} />
    </FormLabel>
  )
}

export default FormInput

