import ContentHeader from '../../../components/layouts/Content/ContentHeader'
import useContentStructure from '../../../hooks/useContentStructure'

const UpdatesHeader = () => {
  const { chapter: chapterData } = useContentStructure()

  const displayPath: string[] = [chapterData?.title].filter(Boolean) as string[]

  return <ContentHeader displayPath={displayPath} actions={null} />
}

export default UpdatesHeader
