import { useDispatch } from 'react-redux'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import useContentStructure from '../../../hooks/useContentStructure'
import { invalidateContentTags } from '../../../store/services/ContentService'
import { useDeleteFileMutation } from '../../../store/services/FilesService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useFileDelete = () => {
  const { tab: activeTab } = useContentStructure()
  const dispatch = useDispatch()
  const [deleteFile] = useDeleteFileMutation()
  const fileDeleteOperation = useAsyncOperationToast({
    id: 'delete-file',
    loadingMessage: 'Deleting file',
    successMessage: 'File deleted successfully',
    errorMessage: 'Deleting file failed'
  })

  return (id: number) => {
    fileDeleteOperation.setLoading(true)
    deleteFile(id)
      .then(
        handlePromiseError(
          () => {
            fileDeleteOperation.operationSuccess()
            dispatch(
              invalidateContentTags([{ type: 'TabContent', id: activeTab?.id }])
            )
          },
          () => fileDeleteOperation.operationFail()
        )
      )
      .finally(() => fileDeleteOperation.setLoading(false))
  }
}

export default useFileDelete
