import { Box } from '@veracity/vui'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FormInput from '../../../components/form/FormInput'

interface FormValues {
  id?: number
  name: string
}
interface GroupFormProps {
  initValues: FormValues
  onChange: (values: FormValues) => void
}
const GroupForm = ({ initValues, onChange }: GroupFormProps) => {
  const form = useForm({
    defaultValues: initValues ?? {
      name: ''
    }
  })
  const values = form.watch()

  useEffect(() => {
    onChange(values)
  }, [values, onChange])

  return (
    <Box px={3} py={2}>
      <FormInput
        control={form.control}
        name="name"
        label="Group name"
        size="md"
        w="100%"
      />
    </Box>
  )
}

export default GroupForm
