import { Grid, Heading } from '@veracity/vui'
import { ReactNode } from 'react'

interface TilesSectionProps {
  title: string
  children?: ReactNode
}

const TilesSection = ({ children, title }: TilesSectionProps) => (
  <>
    {title && <Heading mb={2} size="h3" text={title} weight={600} />}
    <Grid gap={3} gridTemplateColumns={3}>
      {children}
    </Grid>
  </>
)

export default TilesSection

