import { Box, Button, Divider } from '@veracity/vui'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import FormInput from '../../../../components/form/FormInput'
import ContentHeader from '../../../../components/layouts/Content/ContentHeader'
import { DataSourceType } from '../../../../store/services/DataSourceService'
import useCreateDataSource from './useCreateDataSource'

const NewDataSourceHeader = () => {
  const navigate = useNavigate()
  const form = useFormContext()

  const handleCreateDataSource = useCreateDataSource()

  const handleSave = () => {
    form.handleSubmit(({ type, name, data, file, parameters }) => {
      handleCreateDataSource({ type, name, data, file, parameters })
    })()
  }

  const type = form.watch('type')
  const file = form.watch('file')
  const fileDataMissing = type === DataSourceType.CSV && file === null

  const isSaveDisabled = !form.formState.isValid || fileDataMissing

  return (
    <ContentHeader
      displayPath={['New Data Source']}
      variant="dark"
      onBack={() => navigate('/management/data-sources')}
      actions={
        <Box alignItems="center" gap={2}>
          <FormInput
            size="md"
            label=""
            placeholder="Data source name"
            name="name"
            control={form.control}
            w={300}
          />
          <Divider isVertical width={2} h={24} />
          <Button
            size="lg"
            icon="falSave"
            variant="tertiaryDark"
            borderRadius={6}
            onClick={handleSave}
            disabled={isSaveDisabled}
          />
        </Box>
      }
    />
  )
}

export default NewDataSourceHeader
