import ErrorCard from '../../components/ErrorCard'

const UnauthorizedPage = () => (
  <ErrorCard
    title="Sorry, you don't have access to this content"
    description="The content you're trying to access is restricted for you due to lack of permissions."
  />
)

export default UnauthorizedPage
