const emptyChapters = [
  {
    id: 1,
    slugId: 'overview',
    iconName: 'falTachometer',
    title: 'Overview',
    type: 'Regular',
    sections: []
  },
  {
    id: 2,
    slugId: 'updates',
    iconName: 'falBell',
    title: 'Updates',
    type: 'Updates',
    sections: []
  },
  {
    id: 3,
    slugId: 'scenarios',
    iconName: 'falCodeMerge',
    title: 'Scenarios',
    type: 'Regular',
    sections: []
  },
  {
    id: 4,
    slugId: 'forecast',
    iconName: 'falAnalytics',
    title: 'Forecast',
    type: 'Regular',
    sections: []
  },
  {
    id: 5,
    slugId: 'downloads',
    iconName: 'culFileDownloadAlt',
    title: 'downloads',
    type: 'Files',
    sections: []
  }
]

export default emptyChapters

