import { Box, Button, Card, Icon, Link, T } from '@veracity/vui'
import { ContentFile } from '../../store/services/ContentService'

const formatBytesSize = (bytes: number) => {
  if (!bytes) return undefined

  const kb = bytes / 1024
  const mb = bytes / 1048576

  if (mb >= 1) return `${Math.round(mb * 100) / 100} MB`

  if (kb >= 1) return `${Math.round(kb * 100) / 100} KB`

  return `${bytes} B`
}

interface FileListItemProps {
  file: ContentFile
  movable?: boolean
  onDelete?: () => void
  onRename?: () => void
}
const FileListItem = ({
  file,
  movable,
  onDelete,
  onRename
}: FileListItemProps) => {
  const typeIcon =
    {
      'text/csv': 'falFileCsv',
      'application/pdf': 'falFilePdf',
      'application/vnd.ms-excel': 'falFileExcel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        'falFileExcel'
    }[file.type as string] ?? 'falFileAlt'

  return (
    <Card justifyContent="space-between" p={1} w={500}>
      <Box alignItems="center" gap={1}>
        {movable && <Icon name="falBars" size="xs" />}
        <Icon name={typeIcon} color="prussian.80" size="xl" />
        <Box column>
          <Box alignItems="center" gap={1}>
            <T text={file.displayName} weight={600} color="prussian.80" />
            {onRename && (
              <Button
                onClick={onRename}
                icon="falPen"
                variant="tertiaryDark"
                isRound
                size="sm"
              />
            )}
          </Box>
          <T
            text={formatBytesSize(file.size)}
            color="#698091"
            size="sm"
            ml={1}
          />
        </Box>
      </Box>
      <Box alignItems="center" gap={1}>
        {file.url && (
          <Link href={file.url} target="_blank">
            <Button icon="culFileDownloadAlt" variant="tertiaryDark" isRound />
          </Link>
        )}
        {onDelete && (
          <Button
            onClick={onDelete}
            icon={<Icon size="sm" name="falTrashAlt" color="red.90" />}
            variant="tertiaryDark"
            isRound
            hoverBg="red.20"
            activeBg="red.20"
          />
        )}
      </Box>
    </Card>
  )
}

export default FileListItem
