import { useForm } from 'react-hook-form'
import FormSelect from '../../../components/form/FormSelect'
import styled from 'styled-components'
import { UserRole } from '../../../store/services/UsersService'
import { useEffect } from 'react'
import useChangeUserRole from './useChangeUserRole'

const Wrapper = styled.div`
  .vui-selectButton {
    border: none;
    background: transparent;
  }
`

interface AlterRoleProps {
  role: UserRole
  userId: number
}
const AlterRole = ({ role, userId }: AlterRoleProps) => {
  const form = useForm({
    defaultValues: { role, userId }
  })
  const roleId = form.watch('role')
  const handleChangeUserRole = useChangeUserRole(userId)

  useEffect(() => {
    if (role !== roleId) {
      handleChangeUserRole(roleId, () => form.setValue('role', role))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, roleId])

  return (
    <Wrapper>
      <FormSelect
        name="role"
        label=""
        control={form.control}
        options={[
          { value: UserRole.Customer, text: 'Customer' },
          { value: UserRole.ContentManager, text: 'Content Manager' },
          { value: UserRole.Keymaster, text: 'Keymaster' }
        ]}
      />
    </Wrapper>
  )
}

export default AlterRole
