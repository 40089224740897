import { createSlice } from '@reduxjs/toolkit'
import { UserInfo, UserRole, UserRoleInfo } from '../services/UsersService'

interface UserState {
  isLoading: boolean
  isLoggedIn: boolean
  isDemoMode: boolean
  userInfo?: UserInfo
  role?: UserRoleInfo
}

const initialState: UserState = {
  isLoading: true,
  isLoggedIn: false,
  isDemoMode: false,
  userInfo: undefined,
  role: undefined
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      if (payload) {
        state.isLoggedIn = true
        state.userInfo = payload.userInfo
        state.role = payload.role
        const isCustomer = payload.role.roleId === UserRole.Customer
        state.isDemoMode = getDemoMode(isCustomer)
      } else {
        state.isLoggedIn = false
        state.userInfo = undefined
        state.role = undefined
        state.isDemoMode = false
      }
    },
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setDemoMode: (state, { payload }) => {
      state.isDemoMode = payload
      persistDemoMode(payload)
    }
  }
})

function getDemoMode(isCustomer: boolean) {
  if (isCustomer) {
    persistDemoMode(false)
    return false
  }

  const demoMode = localStorage.getItem('demoMode')

  if (demoMode) {
    return Boolean(Number(demoMode))
  }

  return false
}

function persistDemoMode(value: boolean) {
  localStorage.setItem('demoMode', value ? '1' : '0')
}

export const { reducer: userReducer, actions: userActions } = userSlice

