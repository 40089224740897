import { Box, Menu } from '@veracity/vui'
import { stopPropagation } from '../../../utils/event'
import useNavMode, { Mode } from './useNavMode'

interface SectionControlProps {
  onAddSubsection: () => void
  onEditSection: () => void
  onDeleteSection: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
  onPublish?: () => void
}
const SectionControl = ({
  onAddSubsection,
  onEditSection,
  onDeleteSection,
  onMoveUp,
  onMoveDown,
  onPublish
}: SectionControlProps) => {
  const mode = useNavMode()

  if (mode === Mode.Read) return null

  return (
    <Box>
      <Menu>
        <Menu.Button icon="falEllipsisV" isRound />
        <Menu.List w={240}>
          {onPublish && (
            <Menu.Item
              iconLeft="culGlobePlus"
              onClick={stopPropagation(onPublish)}>
              Publish section
            </Menu.Item>
          )}
          <Menu.Item
            iconLeft="falPlus"
            onClick={stopPropagation(onAddSubsection)}>
            Add subsection
          </Menu.Item>
          <Menu.Item
            iconLeft="falPencil"
            onClick={stopPropagation(onEditSection)}>
            Edit section
          </Menu.Item>
          <Menu.Item
            iconLeft="falTrashAlt"
            onClick={stopPropagation(onDeleteSection)}>
            Delete section
          </Menu.Item>
          {onMoveUp && (
            <Menu.Item
              iconLeft="falArrowAltUp"
              onClick={stopPropagation(onMoveUp)}>
              Move up
            </Menu.Item>
          )}
          {onMoveDown && (
            <Menu.Item
              iconLeft="falArrowAltDown"
              onClick={stopPropagation(onMoveDown)}>
              Move down
            </Menu.Item>
          )}
        </Menu.List>
      </Menu>
    </Box>
  )
}

export default SectionControl

