import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { parseQuerystring } from '../../utils/url'
import { axiosBaseQuery } from './utils/axiosBaseQuery'

export enum DataSourceType {
  CSV,
  DB,
  Manual
}

interface GetDataSourceListRequest {
  searchName?: string
  pageSize?: number
  pageNumber?: number
}

export interface DataSource {
  id: number
  extId: string
  name: string
  parameters: Record<string, unknown>
  type: number
  columns: string[]
  rows: number
  lastUpdateDateTime: string
  data: string[][]
}

export type DataSourceListItem = Omit<DataSource, 'parameters' | 'data'>

interface GetDataSourceListResponse {
  dataSourceList: DataSourceListItem[]
  total: number
}

type GetDataSourceResponse = DataSource

type UpsertDataSourceResponse = DataSource

interface UpsertDataSourceWithStringRequest {
  id?: number
  name: string
  data: string[][]
}

interface UpsertDataSourceWithDBRequest {
  id?: number
  name: string
  parameters: {
    dbName: string
    entityName: string
  }
}

interface TestExternalDBRequest {
  dbName: string
  entityName: string
}

interface TestExternalDBResponse {
  isSuccess: boolean
  error: string | null
  data: string[][] | null
}

const dataSourceApi = createApi({
  reducerPath: 'dataSourceApi',
  tagTypes: ['AllDataSources', 'DataSource', 'DataSourceData'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/DataSource/'
  }),
  endpoints: builder => ({
    getDataSourceList: builder.query<
      GetDataSourceListResponse,
      GetDataSourceListRequest
    >({
      query: filters => ({
        url: `GetAll?${parseQuerystring(filters)}`
      }),
      providesTags: (_, __, args) => [
        {
          type: 'AllDataSources',
          id: parseQuerystring(args)
        }
      ]
    }),
    getDataSource: builder.query<GetDataSourceResponse, number>({
      query: dataSourceId => ({ url: `Get/${dataSourceId}` }),
      providesTags: (_, __, id) => [{ type: 'DataSource', id }]
    }),
    deleteDataSource: builder.mutation<void, number>({
      query: dataSourceId => ({
        url: `Delete/${dataSourceId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_, __, id) => [
        'AllDataSources',
        { type: 'DataSource', id },
        { type: 'DataSourceData', id }
      ]
    }),
    upsertDataSourceWithFile: builder.mutation<
      UpsertDataSourceResponse,
      FormData
    >({
      query: data => ({
        url: 'UpsertDataSourceWithFile',
        method: 'POST',
        data
      }),
      invalidatesTags: response =>
        response
          ? [
              'AllDataSources',
              { type: 'DataSource', id: response.id },
              { type: 'DataSourceData', id: response.id }
            ]
          : ['AllDataSources']
    }),
    upsertDataSourceWithString: builder.mutation<
      UpsertDataSourceResponse,
      UpsertDataSourceWithStringRequest
    >({
      query: data => ({
        url: 'UpsertDataSourceWithString',
        method: 'POST',
        data
      }),
      invalidatesTags: response =>
        response
          ? [
              'AllDataSources',
              { type: 'DataSource', id: response.id },
              { type: 'DataSourceData', id: response.id }
            ]
          : ['AllDataSources']
    }),
    upsertDataSourceWithDB: builder.mutation<
      UpsertDataSourceResponse,
      UpsertDataSourceWithDBRequest
    >({
      query: data => ({
        url: 'UpsertDataSourceFromExternalDb',
        method: 'POST',
        data
      }),
      invalidatesTags: response =>
        response
          ? [
              'AllDataSources',
              { type: 'DataSource', id: response.id },
              { type: 'DataSourceData', id: response.id }
            ]
          : ['AllDataSources']
    }),
    testExternalDB: builder.mutation<
      TestExternalDBResponse,
      TestExternalDBRequest
    >({
      query: data => ({ url: 'TestExternalDbDataSource', method: 'POST', data })
    }),
    getDataSourceData: builder.query<string[][], number>({
      query: dataSourceId => ({ url: `${dataSourceId}/Data` }),
      providesTags: (_, __, id) => [{ type: 'DataSourceData', id }]
    })
  })
})

export const {
  reducerPath: dataSourceApiReducerPath,
  reducer: dataSourceApiReducer,
  middleware: dataSourceApiMiddleware,
  useGetDataSourceListQuery,
  useGetDataSourceQuery,
  useDeleteDataSourceMutation,
  useUpsertDataSourceWithFileMutation,
  useUpsertDataSourceWithStringMutation,
  useUpsertDataSourceWithDBMutation,
  useTestExternalDBMutation,
  useLazyGetDataSourceDataQuery
} = dataSourceApi
