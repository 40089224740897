import { useDispatch } from 'react-redux'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { invalidateContentAccessTags } from '../../../store/services/ContentAccessService'
import {
  ContentTab,
  useUpsertTabMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

interface EditContentTab extends ContentTab {
  groupsId: number[]
}

const useTabEdit = () => {
  const dispatch = useDispatch()
  const [upsertTab] = useUpsertTabMutation()
  const editOperation = useAsyncOperationToast({
    id: 'tab-edit',
    loadingMessage: 'Saving changes',
    successMessage: 'Changes saved successfully',
    errorMessage: 'Saving changes failed'
  })

  return (tab: EditContentTab) => {
    if (editOperation.isLoading) return
    editOperation.setLoading(true)
    upsertTab({ tabId: tab.id, title: tab.title, groupsId: tab.groupsId })
      .then(
        handlePromiseError(
          () => {
            editOperation.operationSuccess()
            dispatch(invalidateContentAccessTags([{ type: 'ContentTree' }]))
          },
          () => editOperation.operationFail()
        )
      )
      .finally(() => editOperation.setLoading(false))
  }
}

export default useTabEdit

