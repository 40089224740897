import { Box, Button, Icon } from '@veracity/vui'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../../store'
import { ContentSection } from '../../../store/services/ContentService'
import { contentActions } from '../../../store/slices/content'
import CollapseWrapper from '../../CollapseWrapper'
import EllipsisText from '../../EllipsisText'
import UpdateIndicator from '../../UpdateIndicator'
import NavSubsections from './NavSubsections'
import SectionControl from './SectionControl'
import useDeleteSection from './useDeleteSection'
import { Mode } from './useNavMode'
import usePublishSection from './usePublishSection'

interface NavSectionProps {
  section: ContentSection
  mode: Mode
  country: string
  chapter: string
  chapterId: number
  onNavigate: (path: string[]) => void
  onMoveUp?: () => void
  onMoveDown?: () => void
}

const NavSection = ({
  section,
  mode,
  country,
  chapter,
  chapterId,
  onNavigate,
  onMoveUp,
  onMoveDown
}: NavSectionProps) => {
  const {
    id,
    title,
    slugId,
    subSections,
    isAccessible,
    isPublished,
    modificationStatus
  } = section
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const childIsActive =
    params.chapter === chapter &&
    params.section === slugId &&
    params.subsection !== undefined &&
    subSections.map(({ slugId }) => slugId).includes(params.subsection)
  const active =
    params.chapter === chapter &&
    params.section === slugId &&
    params.subsection === undefined
  const readMode = mode === Mode.Read
  const editMode = mode === Mode.Edit
  const [collapsed, setCollapsed] = useState(!(editMode || childIsActive))
  const haveChildNodes = subSections.length > 0
  const isLocked = !isAccessible && !haveChildNodes
  const isViewed = useSelector<RootState, boolean>(state =>
    state.content.viewedSections.includes(id)
  )

  useEffect(() => {
    if (editMode) {
      setCollapsed(false)
    }
  }, [editMode])

  useEffect(() => {
    if (!collapsed) {
      dispatch(contentActions.setViewedSection(id))
    }
  }, [collapsed, dispatch, id])

  const handleNavigate = () => {
    if (!haveChildNodes) {
      dispatch(contentActions.setViewedSection(id))
      onNavigate([slugId])
    } else {
      setCollapsed(state => !state)
    }
  }

  const handleAddSubsection = () => {
    if (!country || !chapter || !slugId) return

    navigate(
      `/power-analytics/edit/${country}/${chapter}/${slugId}/add-subsection`
    )
  }

  const handleEditSection = () => {
    if (!country || !chapter || !slugId) return

    navigate(
      `/power-analytics/edit/${country}/${chapter}/${slugId}/edit-section`
    )
  }

  const handleDeleteSection = useDeleteSection(id)

  const handlePublishSection = usePublishSection(chapterId, id)

  return (
    <Box column>
      <Box
        alignItems="center"
        justifyContent="space-between"
        py={0.5}
        pl={2.5}
        pr={1}
        ml={-2.5}
        mr={-1}
        bg={active ? 'blue.10' : undefined}
        hoverBg="blue.10"
        cursor={haveChildNodes ? 'default' : 'pointer'}
        onClick={handleNavigate}>
        <Box alignItems="center">
          {editMode && haveChildNodes && (
            <Icon name="falChevronDown" mr={2.5} />
          )}
          {editMode && subSections.length === 0 && <Box w={24} mr={2.5} />}
          <UpdateIndicator
            isViewed={isViewed || editMode}
            status={modificationStatus}>
            <EllipsisText
              size="sm"
              text={title}
              weight={500}
              maxW={editMode ? 120 : undefined}
            />
          </UpdateIndicator>
        </Box>
        {readMode && haveChildNodes && (
          <Button
            icon={
              <Icon
                name="falChevronDown"
                h={10}
                w={15}
                transform={collapsed ? 'rotate(0)' : 'rotate(180deg)'}
                transition="transform 0.3s"
                cursor="pointer"
              />
            }
            size="sm"
            isRound
            variant="tertiaryDark"
          />
        )}
        <SectionControl
          onAddSubsection={handleAddSubsection}
          onEditSection={handleEditSection}
          onDeleteSection={handleDeleteSection}
          onMoveUp={onMoveUp}
          onMoveDown={onMoveDown}
          onPublish={!isPublished ? handlePublishSection : undefined}
        />
        {isLocked && <Icon name="falLockAlt" h={20} w={20} />}
      </Box>
      <CollapseWrapper ml={-2.5} mr={-1} column collapsed={collapsed}>
        <NavSubsections
          subSections={subSections}
          country={country}
          chapter={chapter}
          section={slugId}
          sectionId={id}
          onNavigate={(path: string[]) => onNavigate([slugId, ...path])}
        />
      </CollapseWrapper>
    </Box>
  )
}

export default NavSection

