import { Box, Heading } from '@veracity/vui'

const PRIMARY = '#0f214d'
const WHITE = '#ffffff'

interface BasicTabProps {
  text: string
  isActive?: boolean
  onClick: () => void
}
const BasicTab = ({ isActive, onClick, text }: BasicTabProps) => {
  return (
    <Box
      bg={isActive ? PRIMARY : WHITE}
      px={1}
      h={32}
      alignItems="center"
      radius={15}
      onClick={() => onClick()}
      cursor="pointer">
      <Heading text={text} size="h6" color={isActive ? WHITE : PRIMARY} />
    </Box>
  )
}

export default BasicTab
