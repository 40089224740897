import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useUpsertChapterMutation } from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const usePublishChapter = (countryId?: number, chapterId?: number) => {
  const [publishChapter] = useUpsertChapterMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'publish-chapter',
      loadingMessage: 'Publishing chapter',
      successMessage: 'Chapter published successfully',
      errorMessage: 'Publishing chapter failed'
    })

  return () => {
    if (isLoading || !countryId || !chapterId) return

    setLoading(true)
    publishChapter({ countryId, chapterId, isPublished: true })
      .then(
        handlePromiseError(
          () => operationSuccess(),
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default usePublishChapter
