import { Box, BoxProps, Tag, Tooltip, TooltipProps } from '@veracity/vui'

interface Props extends BoxProps {
  tags: string[]
  tooltipProps?: TooltipProps
}
const CompactTagList = ({ tags, tooltipProps = {}, ...props }: Props) => {
  if (!tags?.length) return null

  const [first, ...rest] = tags

  return (
    <Box alignItems="center" gap={1} {...props}>
      <Tag text={first} />
      {rest.length > 0 && (
        <Tooltip text={rest.join(', ')} {...tooltipProps}>
          <Tag text={`+${rest.length}`} />
        </Tooltip>
      )}
    </Box>
  )
}

export default CompactTagList
