import { Box, Spinner } from '@veracity/vui'
import CSVDataSource from '../CSVDataSource'
import DBDataSource from '../DBDataSource'
import EditDataSourceHeader from './Header'
import ManualDataSource from '../ManualDataSource'
import DataSourceTypeSwitcher from '../TypeSwitcher'
import { useForm, FormProvider } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useEffect } from 'react'
import {
  DataSource,
  DataSourceType,
  useGetDataSourceQuery
} from '../../../../store/services/DataSourceService'

const schema = yup
  .object({
    name: yup.string().min(1).required(),
    type: yup
      .number()
      .oneOf([DataSourceType.CSV, DataSourceType.Manual, DataSourceType.DB])
      .required(),
    data: yup.array(yup.array(yup.string())),
    preview: yup.array(yup.array(yup.string())),
    parameters: yup.object()
  })
  .required()

interface FormInputs {
  name: string
  extId: string
  type: DataSourceType
  data: string[][]
  preview: string[][]
  file: File | null
  initial: DataSource | null
  parameters: any
}

const EditDataSourcePage = () => {
  const { id } = useParams()
  const { data, isFetching } = useGetDataSourceQuery(Number(id), {
    skip: isNaN(Number(id))
  })

  const form = useForm<FormInputs>({
    defaultValues: {
      name: ``,
      extId: ``,
      type: DataSourceType.Manual,
      data: [],
      preview: [],
      file: null,
      initial: null,
      parameters: {}
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const type = form.watch('type')

  useEffect(() => {
    form.setValue('preview', [])
  }, [type, form])

  useEffect(() => {
    if (data) {
      form.setValue('name', data.name)
      form.setValue('extId', data.extId)
      form.setValue('type', data.type)
      form.setValue('data', data.data ?? [])
      form.setValue('parameters', data.parameters ?? {})
      form.setValue('initial', data)
    }
  }, [data, form])

  const emptyData = form.watch('data').length === 0

  return (
    <Box column w="100%">
      <FormProvider {...form}>
        {isFetching && <Spinner size="md" />}
        {!isFetching && (
          <>
            <EditDataSourceHeader />
            <DataSourceTypeSwitcher />
            {type === DataSourceType.CSV && <CSVDataSource />}
            {type === DataSourceType.Manual && (
              <ManualDataSource key={emptyData ? 'EMPTY' : 'LOADED'} />
            )}
            {type === DataSourceType.DB && <DBDataSource />}
          </>
        )}
      </FormProvider>
    </Box>
  )
}

export default EditDataSourcePage
