import { Box } from '@veracity/vui'
import { TableColumnsType } from 'antd'
import { useState } from 'react'
import Table from '../../../components/Table'
import useContentStructure from '../../../hooks/useContentStructure'
import { useGetContentUpdatesQuery } from '../../../store/services/ContentService'
import { formatDate } from '../../../utils/format'
import UpdatesHeader from './Header'

const PowerAnalyticsUpdatesPage = () => {
  const { country } = useContentStructure()
  const countryId = country?.id
  const pageSize = 20
  const [pageNumber, setPageNumber] = useState(1)

  const { data: updates, isFetching } = useGetContentUpdatesQuery(
    { countryId: countryId!, pageSize, pageNumber },
    {
      skip: !countryId
    }
  )

  const columns: TableColumnsType = [
    { title: 'Chapter', dataIndex: 'chapter' },
    { title: 'Last Update', dataIndex: 'updatedAt' },
    { title: 'Description', dataIndex: 'description' }
  ]

  const dataSource = updates?.items?.map(updateLog => ({
    key: updateLog.id,
    chapter: updateLog.chapter,
    updatedAt: formatDate(updateLog.updateTime),
    description: updateLog.description
  }))

  return (
    <Box column w="100%">
      <UpdatesHeader />
      <Box column overflow="auto">
        <Box column w="100%" py={2} px={4}>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
              pageSize,
              hideOnSinglePage: true,
              showSizeChanger: false,
              current: pageNumber,
              total: updates?.total,
              onChange: page => setPageNumber(page)
            }}
            loading={isFetching}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PowerAnalyticsUpdatesPage

