import { Box, Spinner } from '@veracity/vui'
import { Navigate } from 'react-router-dom'
import useContentStructure from '../hooks/useContentStructure'

const StructureRedirect = () => {
  const { pathToFirst } = useContentStructure()

  return pathToFirst ? (
    <Navigate to={pathToFirst} />
  ) : (
    <Box w="100%" justifyContent="center">
      <Spinner />
    </Box>
  )
}

export default StructureRedirect
