import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { parseQuerystring } from '../../utils/url'
import { axiosBaseQuery } from './utils/axiosBaseQuery'

export interface Group {
  id: number
  name: string
}

interface GetGroupListRequest {
  searchName?: string
  pageSize?: number
  pageNumber?: number
}

interface GetGroupListResponse {
  groups: Group[]
  total: number
}

interface UpsertGroupRequest {
  groupId?: number
  groupName?: string
}

const groupsApi = createApi({
  reducerPath: 'groupsApi',
  tagTypes: ['Groups'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/Groups/'
  }),
  endpoints: builder => ({
    getGroupList: builder.query<GetGroupListResponse, GetGroupListRequest>({
      query: filters => ({ url: `GetAll?${parseQuerystring(filters)}` }),
      providesTags: (_, __, filters) => [
        { type: 'Groups', id: parseQuerystring(filters) }
      ]
    }),
    upsertGroup: builder.mutation<void, UpsertGroupRequest>({
      query: data => ({ url: 'Group', method: 'POST', data }),
      invalidatesTags: ['Groups']
    }),
    deleteGroup: builder.mutation<void, number>({
      query: id => ({ url: `Group/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Groups']
    })
  })
})

export const {
  reducerPath: groupsApiReducerPath,
  reducer: groupsApiReducer,
  middleware: groupsApiMiddleware,
  useGetGroupListQuery,
  useUpsertGroupMutation,
  useDeleteGroupMutation
} = groupsApi
