import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { RootState } from '../store'
import {
  ChapterType,
  ContentChapter,
  ContentSection,
  ContentSubSection,
  ContentTab
} from '../store/services/ContentService'
import { Country } from '../store/services/CountryService'
import { applyDemoMode } from '../utils/content'

export enum StructureLevel {
  Chapter = 'chapter',
  Section = 'section',
  Subsection = 'sub section'
}

interface ContentStructure {
  pathToParent: string
  pathToFirst?: string
  country?: Country
  chapter?: ContentChapter
  section?: ContentSection
  subsection?: ContentSubSection
  tab?: ContentTab
  tabs?: ContentTab[]
  level?: StructureLevel
  exist: boolean
  contentPath: string
  isAccessible: boolean
}
const useContentStructure = (): ContentStructure => {
  const [URLSearchParams] = useSearchParams()
  const tabSlugId = URLSearchParams.get('tab') ?? undefined
  const { chapter, section, subsection } = useParams()
  const country = useSelector<RootState, Country | undefined>(
    state => state.content.countryInfo
  )
  const chapters = useSelector<RootState, ContentChapter[]>(
    state => state.content.chapters
  )
  const isDemoMode = useSelector<RootState, boolean>(
    state => state.user.isDemoMode
  )
  const filteredChapters = applyDemoMode(isDemoMode, chapters)

  const chapterData = filteredChapters?.find(c => c.slugId === chapter)

  const sectionData = chapterData?.sections?.find(s => s.slugId === section)

  const subsectionData = sectionData?.subSections?.find(
    s => s.slugId === subsection
  )

  const tabData = getActiveTab(
    chapterData,
    sectionData,
    subsectionData,
    tabSlugId
  )

  const contentPath = [
    chapterData?.title,
    sectionData?.title,
    subsectionData?.title,
    tabData?.title
  ]
    .filter(Boolean)
    .join(' | ')

  const isAccessible = [
    tabData?.isAccessible,
    subsectionData?.isAccessible,
    sectionData?.isAccessible,
    chapterData?.isAccessible
  ].every((i) => i == null || i)

  if (chapter && section && subsection) {
    return {
      pathToParent: `${chapter}/${section}`,
      pathToFirst:
        subsectionData?.tabs.length && !tabData
          ? getPathToFirstTab(subsectionData.tabs)
          : undefined,
      country,
      chapter: chapterData,
      section: sectionData,
      subsection: subsectionData,
      tab: tabData,
      tabs: subsectionData?.tabs,
      level: StructureLevel.Subsection,
      exist: !!subsectionData,
      contentPath,
      isAccessible
    }
  }

  if (chapter && section) {
    return {
      pathToParent: `${chapter}`,
      pathToFirst: sectionData?.subSections?.length
        ? getPathToFirstFromSubSections(sectionData.subSections)
        : tryGetPathToFirstTab(tabData, sectionData?.tabs),
      country,
      chapter: chapterData,
      section: sectionData,
      tab: tabData,
      tabs: sectionData?.tabs,
      level: StructureLevel.Section,
      exist: !!sectionData,
      contentPath,
      isAccessible
    }
  }

  if (chapter) {
    return {
      pathToParent: '',
      pathToFirst: chapterData?.sections?.length
        ? getPathToFirstFromSections(chapterData.sections)
        : tryGetPathToFirstTab(tabData, chapterData?.tabs),
      country,
      chapter: chapterData,
      tab: tabData,
      tabs: chapterData?.tabs,
      level: StructureLevel.Chapter,
      exist: !!chapterData,
      contentPath,
      isAccessible
    }
  }

  return {
    pathToParent: '',
    pathToFirst: filteredChapters
      ? getPathToFirstFromChapters(filteredChapters)
      : undefined,
    country,
    exist: false,
    contentPath,
    isAccessible
  }
}

function getActiveTab(
  chapterData?: ContentChapter,
  sectionData?: ContentSection,
  subSectionData?: ContentSubSection,
  tabSlugId?: string
) {
  if (!tabSlugId) return undefined

  if (subSectionData && subSectionData.tabs?.length)
    return subSectionData.tabs.find(tab => tab.slugId === tabSlugId)

  if (sectionData && sectionData.tabs?.length)
    return sectionData.tabs.find(tab => tab.slugId === tabSlugId)

  if (chapterData && chapterData.tabs?.length)
    return chapterData.tabs.find(tab => tab.slugId === tabSlugId)

  return undefined
}

function applyTabToPath(path: string | undefined, tab: ContentTab | undefined) {
  if (path && tab) return `${path}?tab=${tab?.slugId}`

  if (tab) return `?tab=${tab.slugId}`

  return path
}

function getPathToFirstFromSubSections(subSections: ContentSubSection[]) {
  const first = subSections.find(x => x.isAccessible)
  const firstTab = first?.tabs.find(x => x.isAccessible)

  if (!first) return undefined

  return applyTabToPath(first.slugId, firstTab)
}

function getPathToFirstFromSections(sections: ContentSection[]) {
  const first = sections.find(x => x.isAccessible)
  const firstTab = first?.tabs.find(x => x.isAccessible)

  if (!first) return undefined

  if (
    !first.subSections?.length &&
    !getPathToFirstFromSubSections(first.subSections)
  )
    return applyTabToPath(first.slugId, firstTab)

  return applyTabToPath(
    `${first.slugId}/${getPathToFirstFromSubSections(first.subSections)}`,
    firstTab
  )
}

function getPathToFirstFromChapters(chapters: ContentChapter[]) {
  const first = chapters.find(
    x => x.isAccessible && x.type !== ChapterType.Updates
  )
  const firstTab = first?.tabs.find(x => x.isAccessible)

  if (!first) return undefined

  if (!first.sections.length && !getPathToFirstFromSections(first.sections))
    return applyTabToPath(first.slugId, firstTab)

  return applyTabToPath(
    `${first.slugId}/${getPathToFirstFromSections(first.sections)}`,
    firstTab
  )
}

function getPathToFirstTab(tabs: ContentTab[]) {
  const firstTab = tabs.find(x => x.isAccessible)

  if (!firstTab) return undefined

  return applyTabToPath(undefined, firstTab)
}

function tryGetPathToFirstTab(activeTab?: ContentTab, tabs?: ContentTab[]) {
  if (activeTab) return undefined

  if (!tabs?.length) return undefined

  return getPathToFirstTab(tabs)
}

export default useContentStructure

