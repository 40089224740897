import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useFormModal } from '../../../hooks/useFormModal'
import {
  Region,
  useLazyGetRegionQuery,
  useUpsertRegionMutation
} from '../../../store/services/RegionsService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'
import RegionForm from './Form'

export default function useEditRegion(): (id: number) => void {
  const openForm = useFormModal<Partial<Region>>(
    (initValue, onChange, onValidChange) => (
      <RegionForm
        initValue={initValue}
        onChange={onChange}
        onValidChange={onValidChange}
      />
    )
  )
  const [editRegion] = useUpsertRegionMutation()
  const [getRegion] = useLazyGetRegionQuery()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'edit-region',
      loadingMessage: 'Saving region',
      successMessage: 'Region saved successfully',
      errorMessage: 'Saving region failed'
    })

  return (id: number) => {
    getRegion(id)
      .then(values => openForm(values.data ?? {}, 'Edit Region'))
      .then(result => {
        if (!result || isLoading) return

        setLoading(true)

        editRegion(result)
          .then(
            handlePromiseError(
              () => operationSuccess(),
              () => operationFail()
            )
          )
          .finally(() => setLoading(false))
      })
  }
}
