import { useDispatch } from 'react-redux'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import useContentStructure from '../../../hooks/useContentStructure'
import { invalidateContentTags } from '../../../store/services/ContentService'
import { useRenameFileMutation } from '../../../store/services/FilesService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useFileRename = () => {
  const { tab: activeTab } = useContentStructure()
  const dispatch = useDispatch()
  const [renameFile] = useRenameFileMutation()
  const fileRenameOperation = useAsyncOperationToast({
    id: 'rename-file',
    loadingMessage: 'Renaming file',
    successMessage: 'File renamed successfully',
    errorMessage: 'Renaming file failed'
  })

  return (id: number, displayName: string) => {
    fileRenameOperation.setLoading(true)
    renameFile({ id, displayName })
      .then(
        handlePromiseError(
          () => {
            fileRenameOperation.operationSuccess()
            dispatch(
              invalidateContentTags([{ type: 'TabContent', id: activeTab?.id }])
            )
          },
          () => fileRenameOperation.operationFail()
        )
      )
      .finally(() => fileRenameOperation.setLoading(false))
  }
}

export default useFileRename
