import { Box } from '@veracity/vui'
import UploadingFile, { UploadingFileType } from './UploadingFile'

interface UploadingFileListProps {
  files: UploadingFileType[]
}
const UploadingFileList = ({ files }: UploadingFileListProps) => {
  return (
    <Box alignItems="center" column gap={1} mb={2}>
      {files.map(file => (
        <UploadingFile key={file.id} file={file} />
      ))}
    </Box>
  )
}

export default UploadingFileList
