export enum ChartType {
  Area = 'area',
  Bar = 'bar',
  Line = 'line',
  Composed = 'composed'
}

export type ChartTypeString = 'area' | 'bar' | 'line' | 'composed'

export enum Layout {
  Vertical = 'vertical',
  Horizontal = 'horizontal'
}

export type LayoutString = 'vertical' | 'horizontal'

export enum AxisType {
  Category = 'category',
  Number = 'number'
}

export type AxisTypeString = 'category' | 'number'

export enum DataSetType {
  Area = 'area',
  Bar = 'bar',
  Line = 'line'
}

export type DataSetTypeString = 'area' | 'bar' | 'line'

export type InterpolationTypeString =
  | 'basis'
  | 'basisClosed'
  | 'basisOpen'
  | 'linear'
  | 'linearClosed'
  | 'natural'
  | 'monotoneX'
  | 'monotoneY'
  | 'monotone'
  | 'step'
  | 'stepBefore'
  | 'stepAfter'

export interface SizeConfig {
  height?: number
  width?: number
  minHeight?: number
  minWidth?: number
}

export interface ChartOptions {
  layout?: LayoutString
  margin?: {
    top: number
    right: number
    bottom: number
    left: number
  }
  stackOffset?: 'sign' | 'expand' | 'none'
}

export interface AxisConfig {
  dataKey?: string
  type?: AxisTypeString
  domain?: [number | 'auto', number | 'auto']
  label?: any
}

export type YAxisOrientationString = 'left' | 'right'

export interface YAxisConfig extends AxisConfig {
  yAxisId: string | number
  orientation?: YAxisOrientationString
  ticks?: number[]
}

export type XAxisOrientationString = 'top' | 'bottom'

export interface XAxisConfig extends AxisConfig {
  xAxisId: string | number
  orientation?: XAxisOrientationString
}

export interface TooltipConfig {
  offset?: number
}

export interface LegendConfig {
  layout?: LayoutString
  verticalAlign?: 'middle' | 'bottom' | 'top'
  align?: 'center' | 'left' | 'right'
  height?: number
  wrapperStyle?: any
}

export interface GridConfig {
  stroke?: string
}

export interface DataSetConfig {
  type: DataSetTypeString
  name?: string
  dataKey: string
  props: {
    fill?: string
    stroke?: string
    strokeDasharray?: string
    dot?: boolean
    strokeWidth?: number
    yAxisId?: string | number
    xAxisId?: string | number
    stackId?: string | number
    type?: InterpolationTypeString
    connectNulls?: boolean
  }
}

export interface ChartConfig {
  type: ChartTypeString
  size?: SizeConfig
  options: ChartOptions
  xAxis?: XAxisConfig[]
  yAxis?: YAxisConfig[]
  tooltip?: TooltipConfig
  legend?: LegendConfig
  grid?: GridConfig
  dataSets?: DataSetConfig[]
  combineRanges?: Record<string, [string, string]>
}
