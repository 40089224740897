import { RouteObject } from 'react-router-dom'
import HomePage from './pages/Home'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import HomeLayout from './components/layouts/Home'
import ContentLayout from './components/layouts/Content'
import PowerAnalyticsDetailsPage from './pages/PowerAnalytics/Details'
import PowerAnalyticsEditPage from './pages/PowerAnalytics/Edit'
import { Mode } from './components/layouts/Content/useNavMode'
import ManagementLayout from './components/layouts/Management'
import AddChapterPage from './pages/PowerAnalytics/Edit/AddChapter'
import StructureRedirect from './components/StructureRedirect'
import EditChapterPage from './pages/PowerAnalytics/Edit/EditChapter'
import AddSectionPage from './pages/PowerAnalytics/Edit/AddSection'
import EditSectionPage from './pages/PowerAnalytics/Edit/EditSection'
import AddSubsectionPage from './pages/PowerAnalytics/Edit/AddSubsection'
import EditSubsectionPage from './pages/PowerAnalytics/Edit/EditSubsection'
import UsersManagementPage from './pages/Management/Users'
import ContentAccessManagementPage from './pages/Management/ContentAccess'
import DataSourcesManagementPage from './pages/Management/DataSources'
import NewDataSourcePage from './pages/Management/DataSources/New'
import EditDataSourcePage from './pages/Management/DataSources/Edit'
import UserDetailsPage from './pages/Management/Users/Details'
import EditUserPage from './pages/Management/Users/Details/EditUser'
import EditAccessPage from './pages/Management/Users/Details/EditAccess'
import UnauthenticatedPage from './pages/Error/Unauthenticated'
import UnauthorizedPage from './pages/Error/Unauthorized'
import NotFoundPage from './pages/Error/NotFound'
import ErrorPage from './pages/Error'
import PowerAnalyticsUpdatesPage from './pages/PowerAnalytics/Updates'
import GroupsManagementPage from './pages/Management/Groups'
import AboutPage from './pages/About'
import TabNavigation from './components/TabNavigation'
import RegionsManagementPage from './pages/Management/Regions'
import CountriesManagementPage from './pages/Management/Countries'
import CustomerKeysPage from './pages/Management/APIKey/CustomerKeys'
import IntegrationKeysPage from './pages/Management/APIKey/IntegrationKeys'
import DataGroupsManagementPage from './pages/Management/DataGroups'
import DataGroupEditPage from './pages/Management/DataGroups/Edit'

const routes: RouteObject[] = [
  {
    path: '/power-analytics/edit',
    element: <ContentLayout mode={Mode.Edit} />,
    children: [
      {
        path: ':country',
        element: <StructureRedirect />
      },
      {
        path: ':country/add-chapter',
        element: <AddChapterPage />
      },
      {
        path: ':country/:chapter',
        element: <PowerAnalyticsEditPage />
      },
      {
        path: ':country/:chapter/edit-chapter',
        element: <EditChapterPage />
      },
      {
        path: ':country/:chapter/add-section',
        element: <AddSectionPage />
      },
      {
        path: ':country/:chapter/:section',
        element: <PowerAnalyticsEditPage />
      },
      {
        path: ':country/:chapter/:section/edit-section',
        element: <EditSectionPage />
      },
      {
        path: ':country/:chapter/:section/add-subsection',
        element: <AddSubsectionPage />
      },
      {
        path: ':country/:chapter/:section/:subsection',
        element: <PowerAnalyticsEditPage />
      },
      {
        path: ':country/:chapter/:section/:subsection/edit-subsection',
        element: <EditSubsectionPage />
      }
    ]
  },
  {
    path: '/power-analytics',
    element: <ContentLayout />,
    children: [
      {
        path: 'updates/:country/:chapter',
        element: <PowerAnalyticsUpdatesPage />
      },
      {
        path: ':country',
        element: <StructureRedirect />
      },
      {
        path: ':country/:chapter',
        element: <PowerAnalyticsDetailsPage />
      },
      {
        path: ':country/:chapter/:section',
        element: <PowerAnalyticsDetailsPage />
      },
      {
        path: ':country/:chapter/:section/:subsection',
        element: <PowerAnalyticsDetailsPage />
      }
    ]
  },
  {
    path: '/management',
    element: (
      <ManagementLayout
        navigation={
          <TabNavigation
            tabs={[
              { label: 'Users', href: '/management/users' },
              { label: 'Groups', href: '/management/groups' }
            ]}
          />
        }
      />
    ),
    children: [
      {
        path: 'users',
        element: <UsersManagementPage />
      },
      {
        path: 'groups',
        element: <GroupsManagementPage />
      }
    ]
  },
  {
    path: '/management',
    element: (
      <ManagementLayout
        navigation={
          <TabNavigation
            tabs={[
              { label: 'Customer keys', href: '/management/customer-keys' },
              {
                label: 'Integration keys',
                href: '/management/integration-keys'
              }
            ]}
          />
        }
      />
    ),
    children: [
      {
        path: 'customer-keys',
        element: <CustomerKeysPage />
      },
      {
        path: 'integration-keys',
        element: <IntegrationKeysPage />
      }
    ]
  },
  {
    path: '/management',
    element: (
      <ManagementLayout
        navigation={
          <TabNavigation
            tabs={[
              { label: 'Regions', href: '/management/regions' },
              { label: 'Countries', href: '/management/countries' }
            ]}
          />
        }
      />
    ),
    children: [
      {
        path: 'regions',
        element: <RegionsManagementPage />
      },
      {
        path: 'countries',
        element: <CountriesManagementPage />
      }
    ]
  },
  {
    path: '/management',
    element: (
      <ManagementLayout
        navigation={
          <TabNavigation
            tabs={[
              { label: 'Data sources', href: '/management/data-sources' },
              { label: 'Data groups', href: '/management/data-groups' }
            ]}
          />
        }
      />
    ),
    children: [
      {
        path: 'data-sources',
        element: <DataSourcesManagementPage />
      },
      {
        path: 'data-groups',
        element: <DataGroupsManagementPage />
      }
    ]
  },
  {
    path: '/management',
    element: <ManagementLayout />,
    children: [
      {
        path: 'content-access',
        element: <ContentAccessManagementPage />
      }
    ]
  },
  {
    path: '/management',
    element: <ManagementLayout navBarHidden />,
    children: [
      {
        path: 'data-sources/new',
        element: <NewDataSourcePage />
      },
      {
        path: 'data-sources/:id',
        element: <EditDataSourcePage />
      },
      {
        path: 'data-groups/create',
        element: <DataGroupEditPage />
      },
      {
        path: 'data-groups/:id',
        element: <DataGroupEditPage />
      },
      {
        path: 'users/:id',
        element: <UserDetailsPage />
      },
      {
        path: 'users/:id/edit-user',
        element: <EditUserPage />
      },
      {
        path: 'users/:id/edit-access',
        element: <EditAccessPage />
      }
    ]
  },
  {
    path: '/unauthenticated',
    element: <UnauthenticatedPage />
  },
  {
    path: '/',
    element: <HomeLayout />,
    children: [
      {
        path: '/error',
        element: <ErrorPage />
      },
      {
        path: '/unauthorized',
        element: <UnauthorizedPage />
      },
      {
        path: '/about',
        element: <AboutPage />
      },
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
]

const router = createBrowserRouter(routes)

const AppRouter = () => <RouterProvider router={router} />

export default AppRouter
