import { Card, CardProps, Heading } from '@veracity/vui'

const ActivityLogCard = (props: CardProps) => {
  return (
    <Card column p={2} {...props}>
      <Heading size="h5" text="Activity Log" />
    </Card>
  )
}

export default ActivityLogCard
