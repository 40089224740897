import { Box, Button, Divider, T } from '@veracity/vui'
import { TablePaginationConfig } from 'antd'
import { useState } from 'react'
import { Control, useController } from 'react-hook-form'
import Table from '../../../../components/Table'
import { DataSourceListItem } from '../../../../store/services/DataSourceService'

interface Props {
  initialDataSourcesRegistry?: DataSourceListItem[]
  dataSourcesPaginationConfig?: TablePaginationConfig
  dataSources: DataSourceListItem[]
  control: Control<any>
  name: string
  isLoading?: boolean
}
const DataSourcePicker = ({
  initialDataSourcesRegistry = [],
  dataSourcesPaginationConfig,
  dataSources,
  control,
  name,
  isLoading
}: Props) => {
  const [dataSourcesRegistry, setDataSourcesRegistry] = useState([
    ...initialDataSourcesRegistry
  ])
  const {
    field: { value, onChange }
  } = useController({ name, control, defaultValue: [] })

  const handleAdd = (dataSource: DataSourceListItem) => {
    if (value.includes(dataSource.id)) return

    dataSourcesRegistry.push(dataSource)
    value.push(dataSource.id)
    setDataSourcesRegistry(dataSourcesRegistry)
    onChange(value)
  }

  const handleRemove = (dataSource: DataSourceListItem) => {
    if (!value.includes(dataSource.id)) return

    setDataSourcesRegistry(
      dataSourcesRegistry.filter(({ id }) => dataSource.id !== id)
    )
    onChange(value.filter((id: number) => dataSource.id !== id))
  }

  return (
    <Box gap={1}>
      <Box flex={1} column>
        <T text="Included data sources" weight={600} color="#0f214d" py={1} />
        <Table
          style={{ flex: 1 }}
          columns={[
            { key: 'id', dataIndex: 'id', title: 'Id' },
            { key: 'name', dataIndex: 'name', title: 'Name' },
            {
              key: 'actions',
              dataIndex: 'id',
              width: 24,
              render(id, record) {
                return (
                  <Button
                    icon="falTimes"
                    variant="subtleRed"
                    size="sm"
                    className="show-on-row-hover"
                    onClick={() => handleRemove(record)}
                    isRound
                  />
                )
              }
            }
          ]}
          dataSource={value
            .map((dataSourceId: number) =>
              dataSourcesRegistry.find(({ id }) => dataSourceId === id)
            )
            .filter(Boolean)
            .map((dataSource: DataSourceListItem) => ({
              key: dataSource.id,
              ...dataSource
            }))}
          pagination={{
            pageSize: dataSourcesPaginationConfig?.pageSize,
            hideOnSinglePage: true,
            showSizeChanger: false
          }}
        />
      </Box>
      <Divider isVertical />
      <Box flex={1} column>
        <T text="Available data sources" weight={600} color="#0f214d" py={1} />
        <Table
          loading={isLoading}
          style={{ flex: 1 }}
          columns={[
            { key: 'id', dataIndex: 'id', title: 'Id' },
            { key: 'name', dataIndex: 'name', title: 'Name' },
            {
              key: 'actions',
              dataIndex: 'id',
              width: 24,
              render(id, record) {
                const isAdded = value.includes(id)

                return (
                  <Button
                    icon={isAdded ? 'falTimes' : 'falPlus'}
                    variant={isAdded ? 'subtleRed' : 'tertiaryDark'}
                    size="sm"
                    className="show-on-row-hover"
                    onClick={
                      isAdded
                        ? () => handleRemove(record)
                        : () => handleAdd(record)
                    }
                    isRound
                  />
                )
              }
            }
          ]}
          dataSource={dataSources.map(dataSource => ({
            key: dataSource.id,
            ...dataSource
          }))}
          pagination={dataSourcesPaginationConfig ?? false}
        />
      </Box>
    </Box>
  )
}

export default DataSourcePicker
