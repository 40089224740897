import { Grid } from '@veracity/vui'
import { DataSourceListItem } from '../../../store/services/DataSourceService'
import DataSourceCard from './DataSourceCard'

interface DataSourcesGridProps {
  dataSources: DataSourceListItem[]
}
const DataSourcesGrid = ({ dataSources }: DataSourcesGridProps) => {
  return (
    <Grid px={8} py={2} gridTemplateColumns={4} gap={1}>
      {dataSources.map(dataSource => (
        <DataSourceCard key={dataSource.id} dataSource={dataSource} />
      ))}
    </Grid>
  )
}

export default DataSourcesGrid
