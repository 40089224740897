import { Box, Button } from '@veracity/vui'
import { useController, useFormContext } from 'react-hook-form'
import { DataSourceType } from '../../../store/services/DataSourceService'

const DataSourceTypeSwitcher = () => {
  const form = useFormContext()
  const {
    field: { value, onChange }
  } = useController({ control: form.control, name: 'type' })

  return (
    <Box justifyContent="center" gap={2} bg="white" py={1}>
      <Button
        icon="falTh"
        variant="tertiaryDark"
        bg={value === DataSourceType.Manual ? 'skyBlue.85' : 'transparent'}
        borderRadius={6}
        onClick={() => onChange(DataSourceType.Manual)}
      />
      <Button
        icon="falFileSpreadsheet"
        variant="tertiaryDark"
        bg={value === DataSourceType.CSV ? 'skyBlue.85' : 'transparent'}
        borderRadius={6}
        onClick={() => onChange(DataSourceType.CSV)}
      />
    </Box>
  )
}

export default DataSourceTypeSwitcher
