import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, Card, Icon, T } from '@veracity/vui'
import Spreadsheet from 'react-spreadsheet'

const DataSourcePreview = () => {
  const form = useFormContext<{ preview: string[][] }>()
  const data = form.watch('preview')
  const previewData = useMemo(
    () => data.map(row => row.map(col => ({ value: col ?? '' }))),
    [data]
  )

  return (
    <Box column p={2} w="calc(100% - 364px)" overflow="auto">
      {!previewData.length && (
        <Card column alignItems="center" py={4}>
          <Icon name="falEyeSlash" color="grey.60" />
          <T text="No preview" color="grey.60" />
        </Card>
      )}
      {!!previewData.length && (
        <Box>
          <Spreadsheet data={previewData} />
        </Box>
      )}
    </Box>
  )
}

export default DataSourcePreview
