import { useDispatch } from 'react-redux'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { invalidateContentAccessTags } from '../../../store/services/ContentAccessService'
import {
  ContentTab,
  useDeleteTabMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useTabDelete = () => {
  const dispatch = useDispatch()
  const [deleteTab] = useDeleteTabMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'tab-delete',
      loadingMessage: 'Deleting tab',
      successMessage: 'Tab deleted successfully',
      errorMessage: 'Deleting tab failed'
    })

  return ({ id }: ContentTab) => {
    if (isLoading) return
    setLoading(true)
    deleteTab(id)
      .then(
        handlePromiseError(
          () => {
            operationSuccess()
            dispatch(invalidateContentAccessTags([{ type: 'ContentTree' }]))
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default useTabDelete
