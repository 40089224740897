import { useState } from 'react'
import { useGetDataGroupsQuery } from '../store/services/DataGroupsService'
import { useDebounce } from './useDebounce'

const useDataGroupOptions = () => {
  const [rawSearch, setSearch] = useState('')
  const search = useDebounce(rawSearch, 300)
  const { data, isFetching: loading } = useGetDataGroupsQuery({
    search,
    pageSize: 10,
    pageNumber: 1
  })
  const groups = data?.items ?? []
  const options = groups.map(group => ({ value: group.id, text: group.name }))

  return {
    options,
    onSearch: setSearch,
    loading
  }
}

export default useDataGroupOptions
