import {
  Box,
  Button,
  Card,
  CardProps,
  Heading,
  Spinner,
  T,
  Tag
} from '@veracity/vui'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetUserDetailsQuery } from '../../../../store/services/UsersService'

const PersonalInformationCard = (props: CardProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data: user, isFetching } = useGetUserDetailsQuery(Number(id), {
    skip: isNaN(Number(id))
  })

  return (
    <Card column p={2} gap={1} {...props}>
      <Box justifyContent="space-between" alignItems="center">
        <Heading size="h5" text="Personal Information" />
        <Button
          icon="falPen"
          variant="tertiaryDark"
          isRound
          onClick={() => navigate('edit-user')}
        />
      </Box>
      {isFetching && <Spinner size="md" />}
      {user && !isFetching && (
        <>
          <Box justifyContent="space-between">
            <T text="Name" />
            <T text={user.userInfo.displayName} weight={600} />
          </Box>
          <Box justifyContent="space-between">
            <T text="Email" />
            <T text={user.userInfo.email} weight={600} />
          </Box>
          <Box justifyContent="space-between">
            <T text="Role" />
            <T text={user.role.displayName} weight={600} />
          </Box>
          <Box justifyContent="space-between">
            <T text="Groups" />
            <Box gap={1}>
              {user.groups.map(({ id, name }) => (
                <Tag key={id} text={name} />
              ))}
            </Box>
          </Box>
          <Box justifyContent="space-between">
            <T text="Last login" />
            <T
              text={dayjs(user.lastLoginTime).format('MMM DD, YYYY')}
              weight={600}
            />
          </Box>
        </>
      )}
    </Card>
  )
}

export default PersonalInformationCard

