import useAsyncOperationToast from '../../../../../hooks/useAsyncOperationToast'
import { useFormModal } from '../../../../../hooks/useFormModal'
import {
  IntegrationKey,
  useUpsertIntegrationKeyMutation
} from '../../../../../store/services/ApiKeysService'
import { handlePromiseError } from '../../../../../store/services/utils/errorHandling'
import IntegrationKeyEditForm from './Form'

interface FormResponse {
  id?: number
  name: string
  dataGroupIds: number[]
  validTill: string
}

interface UpsertRequest {
  apiKeyId?: number
  dataGroupIds: number[]
  validTill: string
  name: string
}

const useIntegrationKeyEdit = () => {
  const [upsertKey] = useUpsertIntegrationKeyMutation()
  const { isLoading, setLoading, operationFail, operationSuccess } =
    useAsyncOperationToast({
      id: 'upsert-integration-key',
      loadingMessage: 'Submitting form',
      successMessage: 'Form submitted successfully',
      errorMessage: 'Form submitting failed'
    })
  const openModal = useFormModal<IntegrationKey | undefined, FormResponse>(
    (initValues, onChange, onValid) => (
      <IntegrationKeyEditForm
        initValues={initValues}
        onChange={onChange}
        onValid={onValid}
      />
    )
  )

  return (initValues?: IntegrationKey) =>
    openModal(
      initValues,
      !initValues ? 'Create Integration Key' : 'Edit Integration Key'
    ).then(values => {
      if (!values || isLoading) return

      const data: UpsertRequest = {
        dataGroupIds: values.dataGroupIds,
        validTill: values.validTill,
        name: values.name
      }

      if (initValues?.id !== undefined) {
        data.apiKeyId = initValues.id
      }

      setLoading(true)
      upsertKey(data)
        .then(
          handlePromiseError(
            () => operationSuccess(),
            () => operationFail()
          )
        )
        .finally(() => setLoading(false))
    })
}

export default useIntegrationKeyEdit
