import { Box, Button, Divider } from '@veracity/vui'
import { useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ContentHeader from '../../../components/layouts/Content/ContentHeader'
import useContentStructure from '../../../hooks/useContentStructure'
import usePermissions from '../../../hooks/usePermissions'
import { RootState } from '../../../store'
import { UserRole } from '../../../store/services/UsersService'

const DetailsHeader = () => {
  const { country, chapter, section, subsection } = useParams()
  const [searchParams] = useSearchParams()
  const {
    chapter: chapterData,
    section: sectionData,
    subsection: subSectionData
  } = useContentStructure()
  const navigate = useNavigate()
  const { isOneOf } = usePermissions()
  const isDemoMode = useSelector<RootState, boolean>(
    state => state.user.isDemoMode
  )

  const rawPath = [country, chapter, section, subsection].filter(Boolean)
  const displayPath: string[] = [
    chapterData?.title,
    sectionData?.title,
    subSectionData?.title
  ].filter(Boolean) as string[]

  const handleEdit = () => {
    const path = rawPath.join('/')
    navigate('/power-analytics/edit/' + path + `?${searchParams}`)
  }

  return (
    <ContentHeader
      displayPath={displayPath}
      actions={
        isOneOf([UserRole.ContentManager, UserRole.Keymaster]) &&
        !isDemoMode ? (
          <Box alignItems="center" gap={2}>
            <Divider isVertical width={2} h={24} />
            <Button
              size="lg"
              icon="falPen"
              variant="tertiaryDark"
              borderRadius={6}
              onClick={handleEdit}
            />
          </Box>
        ) : null
      }
    />
  )
}

export default DetailsHeader

