import { Box, Spinner } from '@veracity/vui'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useParams } from 'react-router-dom'
import { RootState } from '../../../store'
import { useGetContentStructureQuery } from '../../../store/services/ContentService'
import { contentActions } from '../../../store/slices/content'
import Header from '../../Header'
import ResizeWatcher, { OnResizeCallback } from '../../ResizeWatcher'
import emptyChapters from './emptyChapters'
import SideNav from './SideNav'
import { Mode } from './useNavMode'

interface SideNavLayoutProps {
  mode?: Mode
  isFullWidth?: boolean
}

const SideNavLayout = ({
  mode = Mode.Read,
  isFullWidth
}: SideNavLayoutProps) => {
  const { country } = useParams()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector<RootState, boolean>(
    state => state.user.isLoggedIn
  )
  const [headerHeight, setHeaderHeight] = useState(0)
  const { data, isLoading } = useGetContentStructureQuery(country as string)

  useEffect(() => {
    if (data) {
      dispatch(contentActions.init(data))
    } else {
      dispatch(
        contentActions.init({
          chapters: emptyChapters,
          countryInfo: { name: 'Country' }
        })
      )
    }
  }, [data, dispatch])

  const handleHeaderResize = useCallback<OnResizeCallback>(({ height }) => {
    if (height) {
      setHeaderHeight(height)
    }
  }, [])

  if (!isLoggedIn) {
    return <Navigate to="/" />
  }

  return (
    <main>
      <ResizeWatcher onResize={handleHeaderResize}>
        <Header />
      </ResizeWatcher>
      <Box position="relative" h={`calc(100vh - ${headerHeight}px)`}>
        {!isFullWidth && <SideNav mode={mode} />}
        <Box
          w="100%"
          position="relative"
          h={`calc(100vh - ${headerHeight}px)`}
          bg="#F2F7FA"
          overflow="auto">
          {isLoading ? (
            <Box column alignItems="center" justifyContent="center" w="100%">
              <Spinner />
            </Box>
          ) : (
            <Outlet />
          )}
        </Box>
      </Box>
    </main>
  )
}

export default SideNavLayout
