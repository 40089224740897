import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useFormModal } from '../../../hooks/useFormModal'
import { useUpsertGroupMutation } from '../../../store/services/GroupsService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'
import GroupForm from './GroupForm'

const useCreateGroup = () => {
  const openModal = useFormModal<{ name: string }>((initValues, onChange) => (
    <GroupForm initValues={initValues} onChange={onChange} />
  ))
  const [createGroup] = useUpsertGroupMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'create-group',
      loadingMessage: 'Creating group',
      successMessage: 'Group created successfully',
      errorMessage: 'Creating group failed'
    })

  return async () => {
    const response = await openModal({ name: '' }, 'Create Group')
    if (!response || isLoading) return

    setLoading(true)
    // Weird async/then mix due to typing issue
    // createGroup({ groupName: response.name })
    //   .then(handlePromiseError(() => operationSuccess(), () => operationFail()))
    //   .finally(() => setLoading(false))
    try {
      handlePromiseError(
        () => operationSuccess(),
        () => operationFail()
      )(await createGroup({ groupName: response.name }))
    } catch {
      operationFail()
    } finally {
      setLoading(false)
    }
  }
}

export default useCreateGroup
