import { Card, Icon, Heading, P, Link } from '@veracity/vui'
import CenterAbsolute from '../../components/Styled/CenterAbsolute'
import config from '../../config'
import Divider from './Divider'
import Links from './Links'
import Message from './Message'

const EMAIL = config.TechnicalSupportEmail

interface ErrorCardProps {
  title: string
  description: string
}
const ErrorCard = ({ title, description }: ErrorCardProps) => (
  <CenterAbsolute>
    <Card column={false} align="center" py={5} px={8} position="relative">
      <Icon name="falExclamationCircle" scale={6} />
      <Divider />
      <Message>
        <Heading text={title} mb={2} />
        <P text={description} />
      </Message>
      <Links>
        <Link text="Contact support" href={`mailto:${EMAIL}`} />
        <Link text="Go to homepage" href="/" />
      </Links>
    </Card>
  </CenterAbsolute>
)

export default ErrorCard
