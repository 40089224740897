import { Box } from '@veracity/vui'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../Styled/BackButton'

interface NavBarProps {
  navigation?: ReactNode
}
const NavBar = ({ navigation }: NavBarProps) => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate('/')
  }

  return (
    <Box
      bg="white"
      alignItems="center"
      minH={64}
      px={8}
      justifyContent="space-between">
      <BackButton
        variant="secondaryDark"
        iconLeft="falSignIn"
        onClick={handleNavigate}>
        Exit
      </BackButton>
      <Box gap={2}>{navigation}</Box>
      <Box w={68} h={32} />
    </Box>
  )
}

export default NavBar
