import { createSlice } from '@reduxjs/toolkit'
import { ContentChapter } from '../services/ContentService'
import { Country } from '../services/CountryService'
interface ContentSlice {
  isLoading: boolean
  countryInfo?: Country
  chapters: ContentChapter[]
  isAccessible: boolean
  viewedChapters: number[]
  viewedSections: number[]
  viewedSubSections: number[]
  viewedTabs: number[]
}

const initialState: ContentSlice = {
  isLoading: false,
  countryInfo: undefined,
  chapters: [],
  isAccessible: false,
  viewedChapters: [],
  viewedSections: [],
  viewedSubSections: [],
  viewedTabs: []
}

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    init(state, { payload }) {
      state.countryInfo = payload.countryInfo
      state.chapters = payload.chapters
    },
    setViewedChapter(state, { payload }) {
      state.viewedChapters = state.viewedChapters.filter(c => c !== payload)
      state.viewedChapters.push(payload)
    },
    setViewedSection(state, { payload }) {
      state.viewedSections = state.viewedSections.filter(c => c !== payload)
      state.viewedSections.push(payload)
    },
    setViewedSubSection(state, { payload }) {
      state.viewedSubSections = state.viewedSubSections.filter(
        c => c !== payload
      )
      state.viewedSubSections.push(payload)
    },
    setViewedTab(state, { payload }) {
      state.viewedTabs = state.viewedTabs.filter(c => c !== payload)
      state.viewedTabs.push(payload)
    },
    setLoading(state, { payload }) {
      state.isLoading = payload
    }
  }
})

export const { reducer: contentReducer, actions: contentActions } = contentSlice

