import { useDispatch } from 'react-redux'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { invalidateContentAccessTags } from '../../../store/services/ContentAccessService'
import {
  ContentTab,
  useUpsertTabMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

interface CreateContentTab extends ContentTab {
  groupsId: number[]
}

const useTabCreate = (
  chapterId?: number,
  sectionId?: number,
  subSectionId?: number
) => {
  const dispatch = useDispatch()
  const [upsertTab] = useUpsertTabMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'tab-creation',
      loadingMessage: 'Creating new tab',
      successMessage: 'Tab created successfully',
      errorMessage: 'Creating new tab failed'
    })

  return (tab: Partial<CreateContentTab>) => {
    if (isLoading) return
    setLoading(true)
    upsertTab({
      ...tab,
      chapterId,
      sectionId,
      subSectionId
    })
      .then(
        handlePromiseError(
          () => {
            operationSuccess()
            dispatch(invalidateContentAccessTags([{ type: 'ContentTree' }]))
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default useTabCreate

