import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import useContentStructure from '../../../hooks/useContentStructure'
import {
  ContentFile,
  useUpsertTabContentMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useSaveContent = () => {
  const { tab: activeTab } = useContentStructure()
  const [upsertTabContent] = useUpsertTabContentMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'save-content',
      loadingMessage: 'Saving changes',
      errorMessage: 'Saving changes failed',
      successMessage: 'Changes saved successfully'
    })

  return {
    handler: (data: {
      markdown: string | null
      files: ContentFile[] | null
    }) => {
      if (isLoading || !activeTab) return
      setLoading(true)
      upsertTabContent([activeTab.id, data])
        .then(
          handlePromiseError(
            () => operationSuccess(),
            () => operationFail()
          )
        )
        .finally(() => setLoading(false))
    },
    isLoading
  }
}

export default useSaveContent
