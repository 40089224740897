import { Box, DragAndDrop, Heading, Icon, T, useDialog } from '@veracity/vui'
import { TabContent } from '../../store/services/ContentService'
import EditorFileList from './EditorFileList'
import UploadingFileList from './UploadingFileList'

interface FileListEditorProps {
  content: TabContent
  uploadingFiles: any[]
  onUpload: (files: File[]) => void
  onDelete: (id: number) => void
  onRename: (id: number, displayName: string) => void
}
const FileListEditor = ({
  content,
  uploadingFiles,
  onUpload,
  onDelete,
  onRename
}: FileListEditorProps) => {
  const { showConfirm, showPrompt } = useDialog()

  const handleDelete = (id: number) => {
    showConfirm(
      'Delete file',
      'Are you sure you want to delete this file?'
    ).then(confirm => {
      if (confirm) {
        onDelete(id)
      }
    })
  }

  const handleRename = (id: number, currentName: string) => {
    showPrompt('Rename file').then(value => {
      if (value) {
        onRename(id, value)
      }
    })
  }

  return (
    <>
      {!content.files?.length && (
        <Box column alignItems="center" mt={12}>
          <Heading size="h5" text="There are no documents on this tab" />
          <T text="Drag or upload files" />
        </Box>
      )}
      <DragAndDrop
        accept=".docx,.xls,.xlsx,.csv,.pdf"
        alignItems="center"
        borderRadius="50%"
        h={250}
        mx="auto"
        w={250}
        mt={8}
        mb={4}
        onFilesAdded={onUpload}>
        <Box column alignItems="center">
          <Icon mb={3} name="falFileAlt" size="xl" />
          <T fontWeight={600}>Drop file here</T>
          <T>or click to upload</T>
        </Box>
      </DragAndDrop>
      <UploadingFileList files={uploadingFiles} />
      {content.files && (
        <EditorFileList
          files={content.files}
          onDelete={handleDelete}
          onRename={handleRename}
        />
      )}
    </>
  )
}

export default FileListEditor
