import { Box } from '@veracity/vui'
import { useFormContext } from 'react-hook-form'
import FormSpreadsheet from '../../../components/form/FormSpreadsheet'

const ManualDataSourceForm = () => {
  const form = useFormContext()

  return (
    <Box column overflow="auto" p={2}>
      <FormSpreadsheet name="data" control={form.control} />
    </Box>
  )
}

export default ManualDataSourceForm
