import { useToast } from '@veracity/vui'
import {
  IntegrationKey,
  useLazyGetIntegrationKeySecretQuery
} from '../../../../store/services/ApiKeysService'

const useIntegrationKeyCopySecret = () => {
  const [getKeySecret] = useLazyGetIntegrationKeySecretQuery()
  const { showInfo } = useToast()

  return ({ id }: IntegrationKey) => {
    getKeySecret(id)
      .unwrap()
      .then(value => {
        navigator.clipboard.writeText(value)
        showInfo('Key copied to clipboard')
      })
  }
}

export default useIntegrationKeyCopySecret
