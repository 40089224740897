import { Box } from '@veracity/vui'
import { useParams } from 'react-router-dom'
import useContentStructure from '../../../hooks/useContentStructure'
import EditHeader from './Header'
import SectionForm from './SectionForm'
import useEditSection from './useEditSection'

const EditSectionPage = () => {
  const { country } = useParams()
  const { section, chapter } = useContentStructure()

  const { handler: handleSubmit, isLoading } = useEditSection(
    section?.id,
    chapter?.id,
    chapter?.slugId,
    country
  )

  const initialValues = section
    ? { title: section.title, groupsId: section.groups?.map(group => group.id) }
    : undefined

  return (
    <Box column w="100%">
      <EditHeader title="Edit section" actions={<div />} />
      <Box column w="100%" flex={1} p={8}>
        <SectionForm
          key={section?.id}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          initOptions={section?.groups?.map(group => ({
            value: group.id,
            text: group.name
          }))}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}

export default EditSectionPage

