import { Country, CountryState } from '../../store/services/CountryService'
import { Region } from '../../store/services/RegionsService'
import { RegionCountries } from './types'

export function groupCountries(
  countries?: Country[],
  regions?: Region[]
): RegionCountries[] {
  if (!countries || !regions) return []

  return regions.map(({ id, name }) => {
    const regionCountries = countries.filter(
      country => country.region?.id === id
    )
    const accessibleCountries = regionCountries
      .filter(
        country =>
          country.isAccessible && country.state === CountryState.Complete
      )
      .sort(countryNameSorter)
    const restrictedCountries = regionCountries
      .filter(country => country.state === CountryState.RestrictedAccess)
      .sort(countryNameSorter)
    const pendingCountries = regionCountries
      .filter(country => country.state === CountryState.ComingSoon)
      .sort(countryNameSorter)

    return {
      regionId: id,
      regionName: name,
      accessibleCountries,
      restrictedCountries,
      pendingCountries
    }
  })
}

export function countryNameSorter(a: Country, b: Country) {
  return a.name.localeCompare(b.name)
}

export function emptyRegionFilter(isCustomer: boolean) {
  return ({
    accessibleCountries,
    restrictedCountries,
    pendingCountries
  }: RegionCountries) => {
    const isAccessibleCountriesNotEmpty = isCustomer
      ? false
      : accessibleCountries.length > 0
    const isRestrictedCountriesNotEmpty = restrictedCountries.length > 0
    const isPendingCountriesNotEmpty = pendingCountries.length > 0

    return (
      isAccessibleCountriesNotEmpty ||
      isRestrictedCountriesNotEmpty ||
      isPendingCountriesNotEmpty
    )
  }
}
