import {
  Box,
  Input,
  InputProps,
  SelectOptionData,
  SelectValue,
  Spinner
} from '@veracity/vui'
import { AutoComplete } from 'antd'
import { useMemo, useState } from 'react'
import { Control, useController } from 'react-hook-form'
import FormLabel from '../FormLabel'
import './style.css'

interface Props extends InputProps {
  name: string
  control: Control<any>
  options: SelectOptionData[]
  initOptions?: SelectOptionData[]
  loading?: boolean
  label?: string
  onSearch?: (search: string) => void
}
const FormSearchSelect = ({
  name,
  control,
  initOptions,
  options,
  loading,
  label = '',
  onSearch,
  ...props
}: Props) => {
  const {
    field: { value, onChange }
  } = useController({ name, control })

  const [selectedValue, setSelectedValue] = useState<SelectValue | undefined>(
    value
  )
  const [searchValue, setSearchValue] = useState(
    initSearchValue(value, initOptions)
  )

  const filteredOptions = useMemo(() => {
    if (onSearch)
      return options.map(({ text, value }) => ({
        label: text,
        value: value.toString()
      }))

    return options
      .filter(
        ({ text, value }) =>
          text.toLowerCase().includes(searchValue.toLowerCase()) &&
          selectedValue !== value
      )
      .map(({ text, value }) => ({ label: text, value: value.toString() }))
  }, [options, searchValue, selectedValue, onSearch])

  const handleSearch = (val: string) => {
    onSearch?.(val)
    setSearchValue(val)
    setSelectedValue(undefined)
    onChange(undefined)
  }

  const handleSelect = (val: string) => {
    const option = options.find(o => o.value.toString() === val)
    if (!option) return

    setSearchValue(option.text)
    setSelectedValue(option.value)
    onChange(option.value)
  }

  return (
    <FormLabel text={label}>
      <Box column className="form-search-select">
        <AutoComplete
          defaultActiveFirstOption
          value={searchValue}
          onSearch={handleSearch}
          onSelect={handleSelect}
          options={filteredOptions}
          disabled={props.disabled}>
          <Input
            itemRight={loading && <Spinner size="sm" mr={1} />}
            {...props}
            className={
              value === undefined
                ? 'form-search-select__no-value'
                : 'form-search-select__selected-value'
            }
          />
        </AutoComplete>
      </Box>
    </FormLabel>
  )
}

function initSearchValue(
  value?: SelectValue,
  initOptions?: SelectOptionData[]
) {
  if (value === undefined || !initOptions?.length) return ''

  const match = initOptions.find(option => option.value === value)

  return match ? match.text : ''
}

export default FormSearchSelect
