import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import useContentStructure from '../../../hooks/useContentStructure'
import {
  ContentTab,
  useCopyContentMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useTabClone = () => {
  const { chapter, section, subsection } = useContentStructure()
  const [cloneTab] = useCopyContentMutation()
  const chapterId = chapter?.id
  const sectionId = section?.id
  const subSectionId = subsection?.id

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'tab-clone',
      loadingMessage: 'Cloning tab',
      successMessage: 'Tab cloned successfully',
      errorMessage: 'Cloning tab failed'
    })

  return (tab: ContentTab) => {
    if (isLoading) return

    setLoading(true)
    cloneTab({
      item: { tabId: tab.id },
      newPlace: { chapterId, sectionId, subSectionId }
    })
      .then(
        handlePromiseError(
          () => operationSuccess(),
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default useTabClone
