import { useDialog } from '@veracity/vui'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useDeleteCountryMutation } from '../../../store/services/CountryService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

export default function useDeleteCountry(): (id: number) => void {
  const { showConfirm } = useDialog()
  const [deleteCountry] = useDeleteCountryMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'delete-country',
      loadingMessage: 'Deleting country',
      successMessage: 'Country deleted successfully',
      errorMessage: 'Deleting country failed'
    })

  return (id: number) => {
    showConfirm(
      'Delete country',
      'Are you sure you want to delete this country?'
    ).then(confirm => {
      if (!confirm || isLoading) return

      setLoading(true)

      deleteCountry(id)
        .then(
          handlePromiseError(
            () => operationSuccess(),
            () => operationFail()
          )
        )
        .finally(() => setLoading(false))
    })
  }
}
