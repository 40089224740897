import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@veracity/vui'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FormSearchSelect from '../../../../../components/form/FormSearchSelect'
import FormTagMultiselect from '../../../../../components/form/FormTagMultiselect'
import useDataGroupOptions from '../../../../../hooks/useDataGroupOptions'
import useGroupedUserOptions from '../../../../../hooks/useGroupedUserOptions'
import useGroupOptions from '../../../../../hooks/useGroupOptions'
import * as yup from 'yup'
import FormInput from '../../../../../components/form/FormInput'
import { CustomerKey } from '../../../../../store/services/ApiKeysService'

const schema = yup
  .object({
    userGroupId: yup.number().required(),
    userId: yup.number().required(),
    dataGroupIds: yup.array(yup.number()).min(1).required(),
    validTill: yup.date().min(new Date()).required()
  })
  .required()

interface Props {
  initValues?: CustomerKey
  onChange: (values: any) => void
  onValid: (value: boolean) => void
}
const CustomerKeyEditForm = ({ initValues, onChange, onValid }: Props) => {
  const form = useForm({
    defaultValues: {
      userGroupId: initValues?.group.id,
      userId: initValues?.user.id,
      dataGroupIds: initValues?.dataGroups.map(({ id }) => id),
      validTill: initValues?.validTill
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const userGroupInitOptions = initValues && [
    {
      text: initValues.group.name,
      value: initValues.group.id
    }
  ]
  const userInitOptions = initValues && [
    { text: initValues.user.userInfo.displayName, value: initValues.user.id }
  ]
  const dataGroupInitOptions = initValues?.dataGroups.map(group => ({
    text: group.name,
    value: group.id
  }))

  const values = form.watch()

  useEffect(() => {
    onValid(form.formState.isValid)
  }, [onValid, form.formState.isValid])

  useEffect(() => {
    onChange(values)
  }, [onChange, values])

  const {
    onSearch: onUserGroupSearch,
    options: userGroupOptions,
    loading: userGroupOptionsLoading
  } = useGroupOptions()
  const {
    onSearch: onDataGroupSearch,
    options: dataGroupOptions,
    loading: dataGroupOptionsLoading
  } = useDataGroupOptions()
  const {
    onSearch: onUserSearch,
    options: userOptions,
    loading: userOptionsLoading
  } = useGroupedUserOptions(values.userGroupId)

  return (
    <Box px={3} column gap={1}>
      <FormSearchSelect
        name="userGroupId"
        label="User group"
        control={form.control}
        options={userGroupOptions}
        onSearch={onUserGroupSearch}
        loading={userGroupOptionsLoading}
        disabled={values.userId !== undefined || initValues?.id !== undefined}
        size="md"
        iconLeft="falSearch"
        initOptions={userGroupInitOptions}
      />
      <FormSearchSelect
        name="userId"
        label="User"
        control={form.control}
        options={userOptions}
        onSearch={onUserSearch}
        loading={userOptionsLoading}
        size="md"
        disabled={
          values.userGroupId === undefined || initValues?.id !== undefined
        }
        iconLeft="falSearch"
        initOptions={userInitOptions}
      />
      <FormTagMultiselect
        name="dataGroupIds"
        label="Data group"
        control={form.control}
        options={dataGroupOptions}
        onSearch={onDataGroupSearch}
        loading={dataGroupOptionsLoading}
        size="md"
        iconLeft="falSearch"
        initOptions={dataGroupInitOptions}
      />
      <FormInput
        name="validTill"
        label="Valid till"
        control={form.control}
        type="datetime-local"
        size="md"
      />
    </Box>
  )
}

export default CustomerKeyEditForm
