import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useUpsertSectionMutation } from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const usePublishSection = (chapterId?: number, sectionId?: number) => {
  const [publishSection] = useUpsertSectionMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'publish-section',
      loadingMessage: 'Publishing section',
      successMessage: 'Section published successfully',
      errorMessage: 'Publishing section failed'
    })

  return () => {
    if (isLoading || !chapterId || !sectionId) return

    setLoading(true)
    publishSection({ chapterId, sectionId, isPublished: true })
      .then(
        handlePromiseError(
          () => operationSuccess(),
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default usePublishSection
