import {
  createContext,
  createRef,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useRef
} from 'react'

const EditorControlsContext = createContext<MutableRefObject<any>>(createRef())

export const EditorControlsProvider = ({
  children
}: {
  children: ReactNode
}) => {
  const ref = useRef<any>()

  return (
    <EditorControlsContext.Provider value={ref}>
      {children}
    </EditorControlsContext.Provider>
  )
}

const useEditorControlsContext = () => useContext(EditorControlsContext)

const useEditorControls = () => {
  const ref = useEditorControlsContext()

  const getContent = useCallback(
    () => ref.current?.getInstance?.()?.getMarkdown() ?? null,
    [ref]
  )

  return { ref, getContent }
}

export default useEditorControls
