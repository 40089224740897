import styled from 'styled-components'

interface Props {
  ratio: number
  url: string
}

const BackgroundBox = styled.div<Props>`
  position: relative;
  background-image: url(${props => props.url});
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  padding-top: ${props => props.ratio * 100}%;
  background-position: bottom;
`

export default BackgroundBox
