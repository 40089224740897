import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

const api = axios.create()

api.interceptors.request.use((req: AxiosRequestConfig<any>) => {
  const antiforgeryCookieName = 'RequestVerificationToken'
  const antiforgeryMethods = ['POST', 'PUT', 'PATCH', 'DELETE']

  const [antiforgeryToken] = document.cookie
    .split('; ')
    .filter(x => x.startsWith(`${antiforgeryCookieName}=`))
    .map(token => token.replace(`${antiforgeryCookieName}=`, ''))

  if (
    antiforgeryToken &&
    antiforgeryMethods.includes(req.method?.toUpperCase() || '')
  ) {
    req.headers = {
      RequestVerificationToken: antiforgeryToken
    }
  }

  return req
})

function redirect(url: string) {
  window.location.href = url
}

api.interceptors.response.use(
  (res: AxiosResponse<any, any>) => res,
  (error: AxiosError) => {
    if (!error.response) return

    if (error.response.status === 401) {
      return redirect('/unauthenticated')
    }

    if (error.response.status === 403) {
      return redirect('/unauthorized')
    }

    if (error.response.status >= 500) {
      return redirect('/error')
    }
  }
)

export default api
