import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../../../components/layouts/Content/ContentHeader'

const EditAccessHeader = () => {
  const navigate = useNavigate()

  return (
    <ContentHeader
      displayPath={['Edit Access']}
      variant="dark"
      onBack={() => navigate(-1)}
    />
  )
}

export default EditAccessHeader
