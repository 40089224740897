import { Box, Heading, Image } from '@veracity/vui'

interface CountryFlagProps {
  name: string
  code: string
}

const CountryFlag = ({ name, code }: CountryFlagProps) => {
  return (
    <Box column center gap={1.5}>
      <Image
        alignSelf="start"
        src={`assets/flags/${code}.png`}
        h={66}
        w={104}
        borderRadius={6}
        boxShadow="0px 0px 6px 0px rgba(105, 128, 145, 0.2)"
      />
      <Heading
        size="h4"
        text={name}
        textTransform="uppercase"
        maxW={160}
        whiteSpace="nowrap"
      />
    </Box>
  )
}

export default CountryFlag
