import { ContentChapter } from '../store/services/ContentService'

export function applyDemoMode(isDemoMode: boolean, chapters: ContentChapter[]) {
  if (!isDemoMode) return chapters

  return chapters
    .filter(chapter => !chapter.groups?.length && chapter.isPublished)
    .map(chapter => ({
      ...chapter,
      tabs: chapter.tabs.filter(tab => !tab.groups?.length && tab.isPublished),
      sections: chapter.sections
        .filter(section => !section.groups?.length && section.isPublished)
        .map(section => ({
          ...section,
          tabs: section.tabs.filter(
            tab => !tab.groups?.length && tab.isPublished
          ),
          subSections: section.subSections
            .filter(
              subSection => !subSection.groups?.length && subSection.isPublished
            )
            .map(subSection => ({
              ...subSection,
              tabs: subSection.tabs.filter(
                tab => !tab.groups?.length && tab.isPublished
              )
            }))
        }))
    }))
}
