import { Box, Button } from '@veracity/vui'
import { useEffect, useReducer } from 'react'
import { Control, useController } from 'react-hook-form'
import { Spreadsheet } from 'react-spreadsheet'

type Column = { value: string } | undefined

type Row = Column[]

type Matrix = Row[]

const createEmptyRow = (columns: number) => {
  const row = []
  for (let col = 0; col < columns; col++) {
    row.push({ value: '' })
  }

  return row
}

const createEmptyMatrix = (rows: number, columns: number): Matrix => {
  const matrix = []

  for (let row = 0; row < rows; row++) {
    matrix.push(createEmptyRow(columns))
  }

  return matrix
}

const mapDataToMatrix = (data: string[][]): Matrix => {
  const firstRow = data?.[0]
  const rows = data?.length ?? 0
  const columns = firstRow?.length ?? 0
  const matrix = createEmptyMatrix(rows, columns)

  for (let i = 0; i < data.length; i++) {
    const dataRow = data[i]
    const row = matrix[i]
    for (let j = 0; j < dataRow.length; j++) {
      const dataColumn = dataRow[j]
      const column = row[j]
      if (column && dataColumn) {
        column.value = dataColumn
      }
    }
  }

  return matrix
}

const mapMatrixToData = (matrix: Matrix): string[][] =>
  matrix.map(row => row.map(column => column?.value ?? ''))

interface DataAction {
  type: 'SetMatrix' | 'AddRow' | 'RemoveRow' | 'AddColumn' | 'RemoveColumn'
  payload?: Matrix
}

const reducer = (state: Matrix, action: DataAction) => {
  const { type, payload } = action

  switch (type) {
    case 'SetMatrix':
      return payload as Matrix
    case 'AddRow':
      const firstRow = state[0]
      const columns = firstRow?.length ?? 0
      const newRow = createEmptyRow(columns)

      return [...state, newRow]
    case 'RemoveRow':
      return state.slice(0, -1)
    case 'AddColumn':
      return state.map(row => [...row, { value: '' }])
    case 'RemoveColumn':
      return state.map(row => row.slice(0, -1))
    default:
      return state
  }
}

interface FormSpreadsheetProps {
  name: string
  control: Control
}
const FormSpreadsheet = ({ name, control }: FormSpreadsheetProps) => {
  const {
    field: { value, onChange }
  } = useController({ name, control })

  const [data, dataDispatch] = useReducer(reducer, mapDataToMatrix(value))

  const columns = data?.[0]?.length ?? 0
  const rows = data?.length ?? 0

  const removeColumn = () => {
    dataDispatch({ type: 'RemoveColumn' })
  }

  const addColumn = () => {
    dataDispatch({ type: 'AddColumn' })
  }

  const addRow = () => {
    dataDispatch({ type: 'AddRow' })
  }

  const removeRow = () => {
    dataDispatch({ type: 'RemoveRow' })
  }

  const handleChange = (matrix: Matrix) => {
    dataDispatch({ type: 'SetMatrix', payload: matrix })
  }

  const handleBlur = () => {
    onChange(mapMatrixToData(data))
  }

  useEffect(() => {
    onChange(mapMatrixToData(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, rows])

  return (
    <Box column>
      <Box>
        <Spreadsheet data={data} onChange={handleChange} onBlur={handleBlur} />
        <Box px={1}>
          <Button
            icon="falMinus"
            variant="subtleRed"
            isRound
            onClick={removeColumn}
            disabled={columns <= 1}
          />
          <Button
            icon="falPlus"
            variant="subtleGreen"
            isRound
            onClick={addColumn}
          />
        </Box>
      </Box>
      <Box column py={1}>
        <Button
          icon="falMinus"
          variant="subtleRed"
          isRound
          onClick={removeRow}
          disabled={rows <= 1}
        />
        <Button icon="falPlus" variant="subtleGreen" isRound onClick={addRow} />
      </Box>
    </Box>
  )
}

export default FormSpreadsheet
