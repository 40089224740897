import { Box, useToast } from '@veracity/vui'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDebounce } from '../../../../hooks/useDebounce'
import { useGetIntegrationKeyListQuery } from '../../../../store/services/ApiKeysService'
import useIntegrationKeyEdit from './Edit/useIntegrationKeyEdit'
import IntegrationKeysManagementHeader from './Header'
import IntegrationKeysList from './List'
import useIntegrationKeyCopySecret from './useIntegrationKeyCopySecret'
import useIntegrationKeyDelete from './useIntegrationKeyDelete'

const IntegrationKeysPage = () => {
  const form = useForm()
  const { showWarning } = useToast()
  const search = useDebounce(form.watch('search'), 300)
  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 20
  const { data, isFetching } = useGetIntegrationKeyListQuery({
    search,
    pageSize,
    pageNumber
  })
  const integrationKeys = data?.items ?? []
  const total = data?.total ?? 0

  const handleEdit = useIntegrationKeyEdit()
  const handleCreate = () => handleEdit()
  const handleDelete = useIntegrationKeyDelete()

  const handleCopy = useIntegrationKeyCopySecret()

  const handleShare = () => {
    showWarning('Not implemented yet')
  }

  return (
    <Box column w="100%">
      <FormProvider {...form}>
        <IntegrationKeysManagementHeader onCreate={handleCreate} />
      </FormProvider>
      <IntegrationKeysList
        integrationKeys={integrationKeys}
        isLoading={isFetching}
        page={pageNumber}
        pageSize={pageSize}
        total={total}
        onPageChange={setPageNumber}
        onCopy={handleCopy}
        onEdit={handleEdit}
        onShare={handleShare}
        onDelete={handleDelete}
      />
    </Box>
  )
}

export default IntegrationKeysPage
