import { Box, Card, Heading, Spinner } from '@veracity/vui'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import FormStrictTree from '../../../components/form/FormStrictTree'
import { useGetContentTreeQuery } from '../../../store/services/ContentAccessService'
import { mapToAccessTree } from './utils'

const AccessTree = () => {
  const form = useFormContext()
  const { data, isFetching } = useGetContentTreeQuery()

  const accessTree = useMemo(() => mapToAccessTree(data ?? []), [data])

  return (
    <Box column>
      <Heading text="Content access" weight="600" size="h4" pb={1} />
      <Card column p={2}>
        {isFetching && <Spinner size="md" />}
        <FormStrictTree
          name="access"
          control={form.control}
          treeData={accessTree}
        />
      </Card>
    </Box>
  )
}

export default AccessTree
