import { Box, Heading } from '@veracity/vui'
import {
  ContentTreeTab,
  UserAccess
} from '../../../../../store/services/ContentAccessService'

interface IdRefs {
  countryId: number
  chapterId: number
  sectionId?: number
  subSectionId?: number
  tabId: number
}
const checkUserAccessToTab = (
  { countryId, chapterId, sectionId, subSectionId, tabId }: IdRefs,
  userAccess: UserAccess[]
) => {
  if (userAccess.some(ua => ua.tabId === tabId)) return true

  if (
    userAccess.some(ua => ua.subSectionId === subSectionId && ua.tabId === null)
  )
    return true

  if (
    userAccess.some(
      ua =>
        ua.sectionId === sectionId &&
        ua.subSectionId === null &&
        ua.tabId === null
    )
  )
    return true

  if (
    userAccess.some(
      ua =>
        ua.chapterId === chapterId &&
        ua.sectionId === null &&
        ua.subSectionId === null &&
        ua.tabId === null
    )
  )
    return true

  if (
    userAccess.some(
      ua =>
        ua.countryId === countryId &&
        ua.chapterId === null &&
        ua.sectionId === null &&
        ua.subSectionId === null &&
        ua.tabId === null
    )
  )
    return true

  return false
}

interface TabNodeProps {
  countryId: number
  chapterId: number
  sectionId?: number
  subSectionId?: number
  userAccess: UserAccess[]
  tab: ContentTreeTab
}
const TabNode = ({
  countryId,
  chapterId,
  sectionId,
  subSectionId,
  tab,
  userAccess
}: TabNodeProps) => {
  const disabled = !checkUserAccessToTab(
    { tabId: tab.tabId, countryId, chapterId, sectionId, subSectionId },
    userAccess
  )

  return (
    <Box alignItems="center" gap={1}>
      <Box
        h={12}
        w={12}
        bg={disabled ? 'grey.20' : 'green.40'}
        border={disabled ? '1px solid grey.60' : '1px solid green.50'}
        radius={6}
      />
      <Heading
        size="h5"
        text={tab.title}
        color={disabled ? 'grey.60' : undefined}
      />
    </Box>
  )
}

export default TabNode
