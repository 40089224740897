import { Box } from '@veracity/vui'
import { ContentFile } from '../../store/services/ContentService'
import FileListItem from './FileListItem'

interface FilesListProps {
  files: ContentFile[]
  onDelete: (id: number) => void
  onRename: (id: number, currentName: string) => void
}
const EditorFileList = ({ files, onDelete, onRename }: FilesListProps) => {
  return (
    <Box column alignItems="center" gap={1}>
      {files.map(file => (
        <FileListItem
          key={file.id}
          file={file}
          onDelete={() => onDelete(file.id)}
          onRename={() => onRename(file.id, file.displayName)}
        />
      ))}
    </Box>
  )
}

export default EditorFileList
