import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import {
  ContentTab,
  useUpsertTabMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const usePublishTab = () => {
  const [publishTab] = useUpsertTabMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'publish-tab',
      loadingMessage: 'Publishing tab',
      successMessage: 'Tab published successfully',
      errorMessage: 'Publishing tab failed'
    })

  return (tab?: ContentTab) => {
    if (isLoading || !tab?.id) return

    setLoading(true)
    publishTab({ tabId: tab.id, isPublished: true })
      .then(
        handlePromiseError(
          () => operationSuccess(),
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default usePublishTab
