import { Box, Heading, Image } from '@veracity/vui'
import {
  ContentTreeCountry,
  UserAccess
} from '../../../../../store/services/ContentAccessService'
import ChapterAccessTree from './ChapterAccessTree'

interface CountryAccessTreeProps {
  accessibleCountries: ContentTreeCountry[]
  userAccess: UserAccess[]
}
const CountryAccessTree = ({
  accessibleCountries,
  userAccess
}: CountryAccessTreeProps) => (
  <Box column gap={2}>
    {accessibleCountries.map(country => (
      <Box column key={country.countryId}>
        <Box alignItems="center" gap={2}>
          <Image src={`/assets/flags/${country.code}.png`} h={32} w={50} />
          <Heading size="h5" text={country.title} textTransform="uppercase" />
        </Box>
        <Box column gap={1} mt={1}>
          {country.chapters.map(chapter => (
            <ChapterAccessTree
              key={chapter.chapterId}
              countryId={country.countryId}
              chapter={chapter}
              userAccess={userAccess}
            />
          ))}
        </Box>
      </Box>
    ))}
  </Box>
)

export default CountryAccessTree
