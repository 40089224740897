import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@veracity/vui'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import FormInput from '../../../../components/form/FormInput'
import { useDebounce } from '../../../../hooks/useDebounce'
import { DataGroup } from '../../../../store/services/DataGroupsService'
import { useGetDataSourceListQuery } from '../../../../store/services/DataSourceService'
import DataSourcePicker from './DataSourcePicker'
import DataGroupEditHeader from './Header'
import * as yup from 'yup'

const schema = yup
  .object({
    name: yup.string().min(1).required(),
    dataSourceIds: yup.array(yup.number()).required()
  })
  .required()

interface DataGroupEditFormProps {
  initialData?: DataGroup
}
const DataGroupEditForm = ({ initialData }: DataGroupEditFormProps) => {
  const [pageNumber, setPageNumber] = useState(1)
  const defaultValues = initialData
    ? {
        id: initialData.id,
        name: initialData.name,
        dataSourceIds: initialData.dataSources.map(({ id }) => id),
        search: ''
      }
    : { name: '', dataSourceIds: [], search: '' }

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const searchName = useDebounce(form.watch('search'), 300)

  const { data, isFetching } = useGetDataSourceListQuery({
    searchName,
    pageNumber,
    pageSize: 10
  })

  return (
    <FormProvider {...form}>
      <DataGroupEditHeader />
      <Box column px={8} py={4} gap={4}>
        <FormInput
          control={form.control}
          name="name"
          label="Group name"
          size="md"
          w={480}
        />
        <DataSourcePicker
          name="dataSourceIds"
          control={form.control}
          dataSources={data?.dataSourceList ?? []}
          initialDataSourcesRegistry={initialData?.dataSources}
          dataSourcesPaginationConfig={{
            current: pageNumber,
            total: data?.total ?? 0,
            onChange(page) {
              setPageNumber(page)
            }
          }}
          isLoading={isFetching}
        />
      </Box>
    </FormProvider>
  )
}

export default DataGroupEditForm
