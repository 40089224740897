import { Box, Icon, T } from '@veracity/vui'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../../store'
import { ContentSubSection } from '../../../store/services/ContentService'
import { contentActions } from '../../../store/slices/content'
import UpdateIndicator from '../../UpdateIndicator'
import SubsectionControl from './SubsectionControl'
import useDeleteSubSection from './useDeleteSubSection'
import { Mode } from './useNavMode'
import usePublishSubSection from './usePublishSubSection'

interface NavSubsectionProps {
  subSection: ContentSubSection
  mode: Mode
  country: string
  chapter: string
  section: string
  sectionId: number
  onNavigate: (path: string[]) => void
  onMoveUp?: () => void
  onMoveDown?: () => void
}

const NavSubsection = ({
  subSection,
  mode,
  country,
  chapter,
  section,
  sectionId,
  onNavigate,
  onMoveUp,
  onMoveDown
}: NavSubsectionProps) => {
  const { id, slugId, title, isAccessible, isPublished, modificationStatus } =
    subSection
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const active =
    params.chapter === chapter &&
    params.section === section &&
    params.subsection === slugId
  const editMode = mode === Mode.Edit
  const isViewed = useSelector<RootState, boolean>(state =>
    state.content.viewedSubSections.includes(id)
  )

  const handleNavigate = () => {
    dispatch(contentActions.setViewedSubSection(id))

    onNavigate([slugId])
  }

  const handleEditSubsection = () => {
    if (!country || !chapter || !section) return

    navigate(
      `/power-analytics/edit/${country}/${chapter}/${section}/${slugId}/edit-subsection`
    )
  }

  const handleDeleteSubSection = useDeleteSubSection(id)

  const handlePublishSubSection = usePublishSubSection(sectionId, id)

  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      pl={editMode ? 8 : 3.5}
      pr={1}
      py={0.5}
      bg={active ? 'blue.10' : undefined}
      hoverBg="blue.10"
      cursor="pointer"
      onClick={handleNavigate}>
      <UpdateIndicator
        isViewed={isViewed || editMode}
        status={modificationStatus}>
        <T
          size="xs"
          weight={500}
          color="prussian.50"
          maxW={editMode ? 120 : undefined}>
          {title}
        </T>
      </UpdateIndicator>
      {!isAccessible && <Icon name="falLockAlt" h={20} w={20} />}
      <SubsectionControl
        onEditSubsection={handleEditSubsection}
        onDeleteSubsection={handleDeleteSubSection}
        onMoveUp={onMoveUp}
        onMoveDown={onMoveDown}
        onPublish={!isPublished ? handlePublishSubSection : undefined}
      />
    </Box>
  )
}

export default NavSubsection

