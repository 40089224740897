import { useDialog } from '@veracity/vui'
import useAsyncOperationToast from '../../../../hooks/useAsyncOperationToast'
import {
  IntegrationKey,
  useDeleteIntegrationKeyMutation
} from '../../../../store/services/ApiKeysService'
import { handlePromiseError } from '../../../../store/services/utils/errorHandling'

const useIntegrationKeyDelete = () => {
  const { showConfirm } = useDialog()
  const [deleteKey] = useDeleteIntegrationKeyMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'delete-integration-key',
      loadingMessage: 'Deleting integration key',
      successMessage: 'Integration key deleted successfully',
      errorMessage: 'Deleting integration key failed'
    })

  return ({ id }: IntegrationKey) => {
    showConfirm(
      'Delete integration key',
      'Are you sure you want to delete this key?'
    ).then(confirm => {
      if (!confirm || isLoading) return

      setLoading(true)
      deleteKey(id)
        .then(
          handlePromiseError(
            () => operationSuccess(),
            () => operationFail()
          )
        )
        .finally(() => setLoading(false))
    })
  }
}

export default useIntegrationKeyDelete
