import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Group } from './GroupsService'
import { axiosBaseQuery } from './utils/axiosBaseQuery'

export enum UserRole {
  Customer = 1,
  ContentManager,
  Keymaster
}

export interface UserInfo {
  veracityUserId: string
  displayName: string
  firstName: string
  lastName: string
  email: string
}

export interface UserRoleInfo {
  roleId: UserRole
  name: string
  displayName: string
}

export interface UserData {
  id: number
  userInfo: UserInfo
  role: UserRoleInfo
  lastLoginTime: string
  groups: Group[]
}

interface ChangeUserRoleRequest {
  userId: number
  roleId: number
}

interface UpdateUserDetailsRequest {
  userId: number
  roleId: number
  groupsId: number[]
}

const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['AllUsers', 'User'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/Users/'
  }),
  endpoints: builder => ({
    getAllUsers: builder.query<UserData[], void>({
      query: () => ({ url: 'AllUsers' }),
      providesTags: ['AllUsers']
    }),
    changeUserRole: builder.mutation<void, ChangeUserRoleRequest>({
      query: ({ userId, roleId }) => ({
        url: `${userId}/Role/${roleId}`,
        method: 'PUT'
      }),
      invalidatesTags: (_, __, { userId }) => [
        'AllUsers',
        { type: 'User', id: userId }
      ]
    }),
    getUserDetails: builder.query<UserData, number>({
      query: userId => ({ url: `${userId}/Details` }),
      providesTags: (_, __, id) => [{ type: 'User', id }]
    }),
    updateUserDetails: builder.mutation<void, UpdateUserDetailsRequest>({
      query: ({ userId, ...data }) => ({
        url: `${userId}/Details`,
        method: 'PUT',
        data
      }),
      invalidatesTags: (_, __, { userId }) => [
        { type: 'User', id: userId },
        'AllUsers'
      ]
    })
  })
})

export const {
  reducerPath: usersApiReducerPath,
  reducer: usersApiReducer,
  middleware: usersApiMiddleware,
  useGetAllUsersQuery,
  useGetUserDetailsQuery,
  useChangeUserRoleMutation,
  useUpdateUserDetailsMutation
} = usersApi

