import { Box, Divider, Heading, T } from '@veracity/vui'
import { useForm } from 'react-hook-form'
import CountryCard from '../../components/CountryCard'
import CountryStatus from '../../components/CountryCard/CountryStatus'
import FormSelect from '../../components/form/FormSelect'
import TilesSection from '../../components/TilesSection'
import usePermissions from '../../hooks/usePermissions'
import { CountryState } from '../../store/services/CountryService'
import { RegionCountries } from './types'
import { emptyRegionFilter } from './utils'

interface CroupedCountriesProps {
  groupedCountries: RegionCountries[]
}
const GroupedCountries = ({ groupedCountries }: CroupedCountriesProps) => {
  const { isCustomer } = usePermissions()
  const form = useForm({
    defaultValues: {
      region: -1
    }
  })

  const filteredRegions = groupedCountries.filter(emptyRegionFilter(isCustomer))
  const regionOptions = [
    { text: 'All regions', value: -1 },
    ...filteredRegions.map(({ regionName, regionId }) => ({
      text: regionName,
      value: regionId
    }))
  ]
  const selectedRegion = form.watch('region')

  return (
    <>
      {regionOptions.length > 1 && (
        <Box alignItems="center" gap={1} my={2}>
          <T text="Region" size="lg" weight={600} />
          <FormSelect
            name="region"
            control={form.control}
            label=""
            options={regionOptions}
            w={150}
          />
        </Box>
      )}
      {filteredRegions
        .filter(
          ({ regionId }) => selectedRegion === -1 || regionId === selectedRegion
        )
        .map(regionCountries => (
          <Box mb={2} column key={regionCountries.regionId}>
            <Heading
              size="h5"
              text={regionCountries.regionName}
              color="#465663"
              mb={2}
            />
            {!isCustomer && regionCountries.accessibleCountries.length > 0 && (
              <Box mb={4} column>
                <TilesSection title="">
                  {regionCountries.accessibleCountries.map(country => (
                    <CountryCard key={country.id} country={country} />
                  ))}
                </TilesSection>
              </Box>
            )}
            {regionCountries.restrictedCountries.length > 0 && (
              <Box mb={4} column>
                <CountryStatus mb={2} status={CountryState.RestrictedAccess} />
                <TilesSection title="">
                  {regionCountries.restrictedCountries.map(country => (
                    <CountryCard key={country.id} country={country} />
                  ))}
                </TilesSection>
              </Box>
            )}
            {regionCountries.pendingCountries.length > 0 && (
              <Box mb={4} column>
                <CountryStatus mb={2} status={CountryState.ComingSoon} />
                <TilesSection title="">
                  {regionCountries.pendingCountries.map(country => (
                    <CountryCard key={country.id} country={country} />
                  ))}
                </TilesSection>
              </Box>
            )}
            <Divider />
          </Box>
        ))}
    </>
  )
}

export default GroupedCountries
