import { Box, Button, Divider } from '@veracity/vui'
import { useFormContext } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import FormInput from '../../../../components/form/FormInput'
import ContentHeader from '../../../../components/layouts/Content/ContentHeader'
import { DataSourceType } from '../../../../store/services/DataSourceService'
import useUpdateDataSource from './useUpdateDataSource'

const NewDataSourceHeader = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const form = useFormContext()

  const handleUpdate = useUpdateDataSource()

  const handleSave = () => {
    form.handleSubmit(({ type, name, data, file, parameters }) =>
      handleUpdate({ id: Number(id), type, name, data, file, parameters })
    )()
  }

  const initial = form.watch('initial')
  const type = form.watch('type')
  const file = form.watch('file')
  const extId = form.watch('extId')

  const createdWithoutFile = initial?.type !== DataSourceType.CSV
  const fileIsRequired = type === DataSourceType.CSV
  const fileDataMissing = createdWithoutFile && fileIsRequired && file === null
  const isSaveDisabled = !form.formState.isValid || fileDataMissing

  return (
    <ContentHeader
      displayPath={[`Data Source #${id} (${extId})`]}
      variant="dark"
      onBack={() => navigate('/management/data-sources')}
      actions={
        <Box alignItems="center" gap={2}>
          <FormInput
            size="md"
            label=""
            placeholder="Data source name"
            name="name"
            control={form.control}
            w={300}
          />
          <Divider isVertical width={2} h={24} />
          <Button
            size="lg"
            icon="falSave"
            variant="tertiaryDark"
            borderRadius={6}
            onClick={handleSave}
            disabled={isSaveDisabled}
          />
        </Box>
      }
    />
  )
}

export default NewDataSourceHeader
