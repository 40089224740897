import { Box, Button } from '@veracity/vui'
import { useFormContext } from 'react-hook-form'
import FormInput from '../../../components/form/FormInput'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useTestExternalDBMutation } from '../../../store/services/DataSourceService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const DBDataSourceForm = () => {
  const form = useFormContext()
  const dbName = form.watch('parameters.dbName')
  const entityName = form.watch('parameters.entityName')

  const [testConnection] = useTestExternalDBMutation()

  const testConnectionDisabled = !dbName || !entityName

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'test-connection',
      loadingMessage: 'Connecting',
      successMessage: 'Successfully connected',
      errorMessage: 'Connection failed'
    })

  const handleTestConnection = () => {
    if (isLoading) return

    setLoading(true)
    testConnection({ dbName, entityName })
      .then(
        handlePromiseError(
          ({ data: response }) => {
            if (response.error) {
              operationFail(response.error)
            } else {
              operationSuccess()
              form.setValue('preview', response.data)
            }
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }

  return (
    <Box bg="white" column p={2} overflow="auto" gap={2} w={364}>
      <FormInput
        size="md"
        name="parameters.dbName"
        label="Database Name"
        control={form.control}
      />
      <FormInput
        size="md"
        name="parameters.entityName"
        label="Table/View Name"
        control={form.control}
      />
      <Button
        text="Test connection"
        onClick={handleTestConnection}
        disabled={testConnectionDisabled}
      />
    </Box>
  )
}

export default DBDataSourceForm
