import { configureStore } from '@reduxjs/toolkit'
import {
  accountApiMiddleware,
  accountApiReducer,
  accountApiReducerPath
} from './services/AccountService'
import {
  apiKeysApiMiddleware,
  apiKeysApiReducer,
  apiKeysApiReducerPath
} from './services/ApiKeysService'
import {
  contentAccessApiMiddleware,
  contentAccessApiReducer,
  contentAccessApiReducerPath
} from './services/ContentAccessService'
import {
  contentApiMiddleware,
  contentApiReducer,
  contentApiReducerPath
} from './services/ContentService'
import {
  countryApiMiddleware,
  countryApiReducer,
  countryApiReducerPath
} from './services/CountryService'
import {
  dataGroupsApiReducerPath,
  dataGroupsApiReducer,
  dataGroupsApiMiddleware
} from './services/DataGroupsService'
import {
  dataSourceApiMiddleware,
  dataSourceApiReducer,
  dataSourceApiReducerPath
} from './services/DataSourceService'
import {
  filesApiMiddleware,
  filesApiReducer,
  filesApiReducerPath
} from './services/FilesService'
import {
  groupsApiMiddleware,
  groupsApiReducer,
  groupsApiReducerPath
} from './services/GroupsService'
import {
  regionsApiReducerPath,
  regionsApiReducer,
  regionsApiMiddleware
} from './services/RegionsService'
import {
  usersApiMiddleware,
  usersApiReducer,
  usersApiReducerPath
} from './services/UsersService'
import { contentReducer } from './slices/content'
import { userReducer } from './slices/user'

const store = configureStore({
  reducer: {
    user: userReducer,
    content: contentReducer,
    [accountApiReducerPath]: accountApiReducer,
    [countryApiReducerPath]: countryApiReducer,
    [contentApiReducerPath]: contentApiReducer,
    [filesApiReducerPath]: filesApiReducer,
    [usersApiReducerPath]: usersApiReducer,
    [dataSourceApiReducerPath]: dataSourceApiReducer,
    [contentAccessApiReducerPath]: contentAccessApiReducer,
    [groupsApiReducerPath]: groupsApiReducer,
    [regionsApiReducerPath]: regionsApiReducer,
    [dataGroupsApiReducerPath]: dataGroupsApiReducer,
    [apiKeysApiReducerPath]: apiKeysApiReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      accountApiMiddleware,
      countryApiMiddleware,
      contentApiMiddleware,
      filesApiMiddleware,
      usersApiMiddleware,
      dataSourceApiMiddleware,
      contentAccessApiMiddleware,
      groupsApiMiddleware,
      regionsApiMiddleware,
      dataGroupsApiMiddleware,
      apiKeysApiMiddleware
    ])
})

export type RootState = ReturnType<typeof store.getState>

export default store

