import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { ContentFile } from './ContentService'
import { axiosBaseQuery } from './utils/axiosBaseQuery'

const filesApi = createApi({
  reducerPath: 'filesApi',
  tagTypes: ['ContentFile'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/Files/'
  }),
  endpoints: builder => ({
    getFile: builder.query<ContentFile, number>({
      query: fileId => ({ url: `${fileId}` }),
      providesTags: result =>
        result ? [{ type: 'ContentFile', id: result.id }] : ['ContentFile']
    }),
    renameFile: builder.mutation<
      ContentFile,
      Pick<ContentFile, 'id' | 'displayName'>
    >({
      query: file => ({
        url: `${file.id}`,
        method: 'PUT',
        data: { displayName: file.displayName }
      }),
      invalidatesTags: result =>
        result ? [{ type: 'ContentFile', id: result.id }] : ['ContentFile']
    }),
    deleteFile: builder.mutation<void, number>({
      query: fileId => ({ url: `${fileId}`, method: 'DELETE' }),
      invalidatesTags: (_, __, id) => [{ type: 'ContentFile', id }]
    })
  })
})

export const {
  reducerPath: filesApiReducerPath,
  reducer: filesApiReducer,
  middleware: filesApiMiddleware,
  useGetFileQuery,
  useDeleteFileMutation,
  useRenameFileMutation
} = filesApi
