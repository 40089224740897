interface Data {
  key: string | number
  children?: Data[]
}

enum TreeNodeState {
  Unchecked,
  Checked
}

export class TreeDataHelper {
  private children: TreeNode[] = []
  private allNodes: TreeNode[] = []

  constructor(data: Data[]) {
    this.children = this.parseData(data)
  }

  private parseData(data: Data[], parent: TreeNode | null = null) {
    return data.map(({ key, children = [] }) => {
      const node = new TreeNode(`${key}`, parent)
      node.setChildren(this.parseData(children, node))
      this.allNodes.push(node)

      return node
    })
  }

  private changeState(key: string, state: TreeNodeState) {
    const found = this.allNodes.find(node => node.getKey() === key)

    if (!found) return

    found.setState(state)
  }

  getChecked() {
    const totalChecked = this.allNodes
      .filter(
        node =>
          node.getState() === TreeNodeState.Checked &&
          node.isComplete() &&
          !node.isParentComplete()
      )
      .map(node => node.getKey())

    return totalChecked
  }

  getHalfChecked() {
    const halfChecked: string[] = []
    this.getChecked().forEach(key => {
      const found = this.allNodes.find(node => node.getKey() === key)

      if (found) {
        halfChecked.push(...found.getParentKeys())
        halfChecked.push(...found.getChildKeys())
      }
    })

    return Array.from(new Set(halfChecked))
  }

  getLowerHalfChecked() {
    const halfChecked: string[] = []
    this.getChecked().forEach(key => {
      const found = this.allNodes.find(node => node.getKey() === key)

      if (found) {
        halfChecked.push(...found.getChildKeys())
      }
    })

    return Array.from(new Set(halfChecked))
  }

  check(key: string) {
    this.changeState(key, TreeNodeState.Checked)
  }

  uncheck(key: string) {
    this.changeState(key, TreeNodeState.Unchecked)
  }

  clear() {
    this.children.forEach(child => child.setState(TreeNodeState.Unchecked))
  }
}

class TreeNode {
  private children: TreeNode[] = []
  private state: TreeNodeState = TreeNodeState.Unchecked

  constructor(private key: string, private parent: TreeNode | null) {}

  isComplete(): boolean {
    const uncheckedChildren = this.children.length
      ? this.children.some(
          child =>
            child.getState() === TreeNodeState.Unchecked || !child.isComplete()
        )
      : false
    return !uncheckedChildren
  }

  isParentComplete(): boolean {
    const completeParent = this.parent
      ? this.parent.isComplete() &&
        this.parent.getState() === TreeNodeState.Checked
      : false
    return completeParent
  }

  getKey(): string {
    return this.key
  }

  getChildKeys(): string[] {
    return this.children.flatMap(child => [
      child.getKey(),
      ...child.getChildKeys()
    ])
  }

  getParentKeys(): string[] {
    if (!this.parent) return []

    return [this.parent.getKey(), ...this.parent.getParentKeys()]
  }

  getChildren(): TreeNode[] {
    return this.children
  }

  setChildren(children: TreeNode[]) {
    this.children = children
  }

  getState(): TreeNodeState {
    return this.state
  }

  setState(state: TreeNodeState) {
    if (state === TreeNodeState.Checked) {
      this.state = state
      this.children.forEach(child => child.setState(TreeNodeState.Checked))

      return
    }

    if (state === TreeNodeState.Unchecked) {
      this.state = state
      this.children.forEach(child => child.setState(state))

      return
    }
  }
}
