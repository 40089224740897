import { Box, Divider, Heading } from '@veracity/vui'
import CountryCard from '../../components/CountryCard'
import TilesSection from '../../components/TilesSection'
import usePermissions from '../../hooks/usePermissions'
import { RegionCountries } from './types'

interface GroupedCountriesProps {
  groupedCountries: RegionCountries[]
}
const CustomerAccessibleCountries = ({
  groupedCountries
}: GroupedCountriesProps) => {
  const { isCustomer } = usePermissions()

  if (!isCustomer) return null

  const haveAnyAccess =
    !!groupedCountries.length &&
    groupedCountries.some(
      ({ accessibleCountries }) => accessibleCountries.length > 0
    )

  if (!haveAnyAccess) return null

  return (
    <Box column>
      <Heading my={2} size="h4" text="Your current access" />
      {groupedCountries
        .filter(({ accessibleCountries }) => accessibleCountries.length > 0)
        .map(regionCountries => (
          <Box mb={2} column>
            <Heading
              mb={2}
              size="h5"
              text={regionCountries.regionName}
              color="#465663"
            />
            <Box mb={4} column>
              <TilesSection key={regionCountries.regionId} title="">
                {regionCountries.accessibleCountries.map(country => (
                  <CountryCard key={country.id} country={country} />
                ))}
              </TilesSection>
            </Box>
            <Divider />
          </Box>
        ))}
    </Box>
  )
}

export default CustomerAccessibleCountries
