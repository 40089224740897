import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useFormModal } from '../../../hooks/useFormModal'
import {
  Country,
  useLazyGetCountryQuery,
  useUpsertCountryMutation
} from '../../../store/services/CountryService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'
import CountryForm from './Form'

interface FormData extends Partial<Country> {
  regionId?: number
  countryId?: number
  stateId?: number
}

export default function useEditCountry(): (id: number) => void {
  const openForm = useFormModal<FormData>(
    (initValue, onChange, onValidChange) => (
      <CountryForm
        initValue={initValue}
        onChange={onChange}
        onValidChange={onValidChange}
      />
    )
  )
  const [editCountry] = useUpsertCountryMutation()
  const [getCountry] = useLazyGetCountryQuery()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'edit-country',
      loadingMessage: 'Saving country',
      successMessage: 'Country saved successfully',
      errorMessage: 'Saving country failed'
    })

  return (id: number) => {
    getCountry(id)
      .then(values => openForm(values.data ?? {}, 'Edit Country'))
      .then(result => {
        if (!result || isLoading) return

        setLoading(true)

        editCountry(result)
          .then(
            handlePromiseError(
              () => operationSuccess(),
              () => operationFail()
            )
          )
          .finally(() => setLoading(false))
      })
  }
}
