import { ReactNode, useEffect, useMemo, useRef } from 'react'

export type OnResizeCallback = (size: {
  width?: number
  height?: number
}) => void

interface ResizeWatcherProps {
  children: ReactNode
  onResize: OnResizeCallback
}

const ResizeWatcher = (props: ResizeWatcherProps) => {
  const { children, onResize } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const observer = useMemo(
    () =>
      new ResizeObserver(() => {
        onResize({
          width: containerRef.current?.clientWidth,
          height: containerRef.current?.clientHeight
        })
      }),
    [onResize]
  )

  useEffect(() => {
    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [observer])

  return <div ref={containerRef}>{children}</div>
}

export default ResizeWatcher

