import { Box, Button, Card, CardProps, Heading, Spinner } from '@veracity/vui'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetContentTreeQuery,
  useGetUserAccessQuery
} from '../../../../store/services/ContentAccessService'
import CountryAccessTree from './CountryAccessTree'

const PagesCard = (props: CardProps) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data: userAccess, isFetching: isAccessLoading } =
    useGetUserAccessQuery(Number(id), {
      skip: !id
    })
  const { data: contentTree, isFetching: isTreeLoading } =
    useGetContentTreeQuery()
  const accessibleCountries = useMemo(
    () =>
      contentTree?.filter(country =>
        userAccess?.some(({ countryId }) => countryId === country.countryId)
      ) ?? [],
    [contentTree, userAccess]
  )

  return (
    <Card column p={2} gap={1} {...props}>
      <Box justifyContent="space-between" alignItems="center">
        <Heading size="h5" text="Pages" />
        <Button
          icon="falPen"
          variant="tertiaryDark"
          isRound
          onClick={() => navigate('edit-access')}
        />
      </Box>
      <Box column overflow="auto" ml={2}>
        {(isAccessLoading || isTreeLoading) && <Spinner size="md" />}
        {userAccess && (
          <CountryAccessTree
            accessibleCountries={accessibleCountries}
            userAccess={userAccess}
          />
        )}
      </Box>
    </Card>
  )
}

export default PagesCard
