import { Box } from '@veracity/vui'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../store'
import ChapterForm from './ChapterForm'
import EditHeader from './Header'
import useCreateChapter from './useCreateChapter'

const AddChapterPage = () => {
  const { country } = useParams()
  const countryId = useSelector(
    (state: RootState) => state.content.countryInfo?.id
  )
  const { handler: handleSubmit, isLoading } = useCreateChapter(
    countryId,
    country
  )

  return (
    <Box column w="100%">
      <EditHeader title="Add chapter" actions={<div />} />
      <Box column w="100%" flex={1} p={8}>
        <ChapterForm onSubmit={handleSubmit} isLoading={isLoading} />
      </Box>
    </Box>
  )
}

export default AddChapterPage

