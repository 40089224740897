import { Box, Button, Heading } from '@veracity/vui'
import { useState } from 'react'
import CollapseWrapper from '../../../../../components/CollapseWrapper'
import {
  ContentTreeChapter,
  UserAccess
} from '../../../../../store/services/ContentAccessService'
import SectionAccessTree from './SectionAccessTree'
import TabNode from './TabNode'

interface ChapterAccessTreeProps {
  countryId: number
  chapter: ContentTreeChapter
  userAccess: UserAccess[]
}
const ChapterAccessTree = ({
  chapter,
  countryId,
  userAccess
}: ChapterAccessTreeProps) => {
  const [collapsed, setCollapsed] = useState(true)

  const parentAccess = userAccess.some(
    ua =>
      ua.countryId === countryId &&
      !ua.chapterId &&
      !ua.sectionId &&
      !ua.subSectionId &&
      !ua.tabId
  )

  const disabled = !(
    parentAccess || userAccess.some(ua => ua.chapterId === chapter.chapterId)
  )

  return (
    <Box column>
      <Box alignItems="center" gap={0.5}>
        <Button
          size="sm"
          icon="falChevronDown"
          variant="tertiaryDark"
          transform={`rotate(${collapsed ? '0deg' : '180deg'})`}
          isRound
          onClick={() => setCollapsed(state => !state)}
          disabled={disabled}
        />
        <Heading
          size="h5"
          text={chapter.title}
          color={disabled ? 'grey.40' : undefined}
        />
      </Box>
      <CollapseWrapper column collapsed={collapsed}>
        <Box column ml={4}>
          {chapter.tabs.map(tab => (
            <TabNode
              key={tab.tabId}
              countryId={countryId}
              chapterId={chapter.chapterId}
              tab={tab}
              userAccess={userAccess}
            />
          ))}
          {chapter.sections.map(section => (
            <SectionAccessTree
              key={section.sectionId}
              countryId={countryId}
              chapterId={chapter.chapterId}
              section={section}
              userAccess={userAccess}
              globalAccess={parentAccess}
            />
          ))}
        </Box>
      </CollapseWrapper>
    </Box>
  )
}

export default ChapterAccessTree
