import { Box, SelectOptionData } from '@veracity/vui'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FormInput from '../../../components/form/FormInput'
import FormTagMultiselect from '../../../components/form/FormTagMultiselect'
import useGroupOptions from '../../../hooks/useGroupOptions'

interface FormValues {
  title: string
  groupsId: number[]
}
interface TabFormProps {
  initValues?: FormValues
  initOptions?: SelectOptionData[]
  onChange: (values: FormValues) => void
}
const TabForm = ({ initValues, onChange, initOptions = [] }: TabFormProps) => {
  const form = useForm({
    defaultValues: initValues ?? {
      title: '',
      groupsId: []
    }
  })
  const { options: groupOptions, onSearch, loading } = useGroupOptions()

  const values = form.watch()

  useEffect(() => {
    onChange(values)
  }, [values, onChange])

  return (
    <Box px={3} py={2} column gap={1}>
      <FormInput
        control={form.control}
        name="title"
        label="Tab name"
        size="md"
        w="100%"
      />
      <FormTagMultiselect
        name="groupsId"
        label="Group"
        control={form.control}
        options={groupOptions}
        initOptions={initOptions}
        size="md"
        iconLeft="falSearch"
        onSearch={onSearch}
        loading={loading}
      />
    </Box>
  )
}

export default TabForm
