import { useNavigate } from 'react-router-dom'
import useAsyncOperationToast from '../../../../hooks/useAsyncOperationToast'
import {
  DataSourceType,
  useUpsertDataSourceWithDBMutation,
  useUpsertDataSourceWithFileMutation,
  useUpsertDataSourceWithStringMutation
} from '../../../../store/services/DataSourceService'
import { handlePromiseError } from '../../../../store/services/utils/errorHandling'

interface CallbackParams {
  type: DataSourceType
  name: string
  data: string[][]
  file: File
  parameters: {
    dbName?: string
    entityName?: string
  }
}

const useCreateDataSource = () => {
  const navigate = useNavigate()

  const [createWithFile] = useUpsertDataSourceWithFileMutation()
  const [createWithData] = useUpsertDataSourceWithStringMutation()
  const [createWithDB] = useUpsertDataSourceWithDBMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'create-data-source',
      loadingMessage: 'Creating data source',
      successMessage: 'Data source created successfully',
      errorMessage: 'Creating data source failed'
    })

  const createDataSourceFromCSVFile = (name: string, file: File) => {
    if (isLoading) return
    const data = new FormData()
    data.append('name', name)
    data.append('content', file)
    setLoading(true)
    createWithFile(data)
      .then(
        handlePromiseError(
          () => {
            operationSuccess()
            navigate(-1)
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }

  const createDataSourceFromManualData = (name: string, data: string[][]) => {
    if (isLoading) return
    setLoading(true)
    createWithData({ name, data })
      .then(
        handlePromiseError(
          () => {
            operationSuccess()
            navigate(-1)
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }

  const createDataSourceFromDBConfig = (
    name: string,
    dbName?: string,
    entityName?: string
  ) => {
    if (isLoading || !dbName || !entityName) return

    setLoading(true)
    createWithDB({ name, parameters: { dbName, entityName } })
      .then(
        handlePromiseError(
          () => {
            operationSuccess()
            navigate(-1)
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }

  return ({ type, name, data, file, parameters }: CallbackParams) => {
    if (type === DataSourceType.CSV)
      return createDataSourceFromCSVFile(name, file)
    if (type === DataSourceType.Manual)
      return createDataSourceFromManualData(name, data)
    if (type === DataSourceType.DB)
      return createDataSourceFromDBConfig(
        name,
        parameters.dbName,
        parameters.entityName
      )
  }
}

export default useCreateDataSource
