import { Box, Button } from '@veracity/vui'
import { useController, useFormContext } from 'react-hook-form'
import Table from '../../../components/Table'
import { Group } from '../../../store/services/GroupsService'

interface GroupListProps {
  groups: Group[]
  isLoading: boolean
  total: number
  onDelete: (id: number) => void
  onEdit: (id: number) => void
}
const GroupList = ({
  groups,
  isLoading,
  onDelete,
  onEdit,
  total
}: GroupListProps) => {
  const form = useFormContext()
  const {
    field: { value: pageSize, onChange: onPageSizeChange }
  } = useController({ name: 'pageSize', control: form.control })
  const {
    field: { value: pageNumber, onChange: onPageNumberChange }
  } = useController({ name: 'pageNumber', control: form.control })
  const dataSource = groups.map(group => ({
    ...group,
    key: group.id
  }))

  const handlePaginationChange = (number: number, size: number) => {
    onPageNumberChange(number)
    onPageSizeChange(size)
  }

  return (
    <Box column px={8} py={1.5}>
      <Table
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true,
          current: pageNumber,
          pageSize,
          total,
          onChange: handlePaginationChange
        }}
        columns={[
          {
            title: 'Group name',
            dataIndex: 'name',
            key: 'name',
            className: 'bold'
          },
          {
            title: 'User count',
            dataIndex: 'userCount',
            key: 'userCount'
          },
          {
            title: 'Content count',
            dataIndex: 'contentCount',
            key: 'contentCount'
          },
          {
            title: null,
            dataIndex: 'id',
            key: 'actions',
            width: 32,
            render: id => (
              <Box alignItems="center" gap={1}>
                <Button
                  variant="tertiaryDark"
                  isRound
                  icon="falPen"
                  className="show-on-row-hover"
                  onClick={() => onEdit(id)}
                />
                <Button
                  variant="subtleRed"
                  isRound
                  icon="falTrashAlt"
                  className="show-on-row-hover"
                  onClick={() => onDelete(id)}
                />
              </Box>
            )
          }
        ]}
        dataSource={dataSource}
      />
    </Box>
  )
}

export default GroupList
