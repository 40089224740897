import { useState, useCallback, ReactNode } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import ResizeWatcher, { OnResizeCallback } from '../../ResizeWatcher'
import Header from '../../Header'
import NavBar from './NavBar'
import { Box } from '@veracity/vui'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

interface ManagementLayoutProps {
  navBarHidden?: boolean
  navigation?: ReactNode
}
const ManagementLayout = ({
  navBarHidden,
  navigation
}: ManagementLayoutProps) => {
  const isLoggedIn = useSelector<RootState, boolean>(
    state => state.user.isLoggedIn
  )
  const [headerHeight, setHeaderHeight] = useState(0)

  const nonContentHeight = headerHeight

  const handleHeaderResize = useCallback<OnResizeCallback>(({ height }) => {
    if (height) {
      setHeaderHeight(height)
    }
  }, [])

  if (!isLoggedIn) {
    return <Navigate to="/" />
  }

  return (
    <main>
      <ResizeWatcher onResize={handleHeaderResize}>
        <Header />
      </ResizeWatcher>
      {!navBarHidden && <NavBar navigation={navigation} />}
      <Box
        h={`calc(100vh - ${nonContentHeight + (!navBarHidden ? 64 : 0)}px)`}
        position="relative"
        bg="#F2F7FA"
        overflow="auto">
        <Outlet />
      </Box>
    </main>
  )
}

export default ManagementLayout
