import styled from 'styled-components'

const CenterAbsolute = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default CenterAbsolute
