import { Viewer as TUIViewer } from '@toast-ui/react-editor'
import '@toast-ui/editor/dist/toastui-editor.css'
import './style.css'
import { useLayoutEffect, useRef } from 'react'
import chartPlugin from './plugins/chartPlugin'
import tablePlugin from './plugins/tablePlugin'
import { useLazyGetDataSourceDataQuery } from '../../store/services/DataSourceService'

interface ViewerProps {
  value: string
}

const Viewer = ({ value }: ViewerProps) => {
  const ref = useRef<any>()

  const [fetchDataSource] = useLazyGetDataSourceDataQuery()

  useLayoutEffect(() => {
    ref.current.getInstance().setMarkdown(value)
  }, [value])

  return (
    <TUIViewer
      ref={ref}
      plugins={[
        chartPlugin({ fetchDataSource }) as any,
        tablePlugin({ fetchDataSource }) as any
      ]}
    />
  )
}

export default Viewer
