import { T } from '@veracity/vui'
import styled from 'styled-components'

const Table = styled.table`
  tr > td:first-child {
    white-space: nowrap;
  }

  td {
    vertical-align: top;
  }
`

interface MetaTableProps {
  meta: {
    name: string
    value: string | number
  }[]
}
const MetaTable = ({ meta }: MetaTableProps) => {
  return (
    <Table>
      <tbody>
        {meta.map(({ name, value }) => (
          <tr key={name + value}>
            <td>
              <T size="xs" weight={600} mr={1}>
                {name}:
              </T>
            </td>
            <td>
              <T size="xs">{value}</T>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default MetaTable
