import { Box, Heading, P } from '@veracity/vui'
import useContentStructure from '../../../hooks/useContentStructure'

const EmptyStructure = () => {
  const { level } = useContentStructure()

  return (
    <Box column bg="white" w="calc(100% - 64px)" m={4}>
      <Box column alignItems="center" py={16}>
        <Heading size="h4" text={`This ${level} is empty`} />
        <P size="lg" text={`No content for this ${level} yet`} />
      </Box>
    </Box>
  )
}

export default EmptyStructure
