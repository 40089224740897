import { Box } from '@veracity/vui'
import { useParams } from 'react-router-dom'
import useContentStructure from '../../../hooks/useContentStructure'
import EditHeader from './Header'
import SectionForm from './SectionForm'
import useCreateSection from './useCreateSection'

const AddSectionPage = () => {
  const { country } = useParams()
  const { chapter } = useContentStructure()

  const parentWithTabs = (chapter?.tabs?.length ?? 0) > 0

  const { handler: handleSubmit, isLoading } = useCreateSection(
    chapter?.id,
    chapter?.slugId,
    country
  )

  return (
    <Box column w="100%">
      <EditHeader title="Add section" actions={<div />} />
      <Box column w="100%" flex={1} p={8}>
        <SectionForm
          onSubmit={handleSubmit}
          isLoading={isLoading}
          parentWithTabs={parentWithTabs}
        />
      </Box>
    </Box>
  )
}

export default AddSectionPage

