import { AxiosRequestConfig } from 'axios'
import api from '.'

export const uploadFile = (
  formData: FormData,
  onProgress: (percentage: number) => void
) => {
  const options: AxiosRequestConfig = {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: event => {
      const percentage = (event.loaded * 100) / (event.total ?? 1)

      onProgress(+percentage.toFixed(2))
    }
  }

  return api
    .post('/api/Files/Upsert', formData, options)
    .then(response => response.data)
}
