import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from './utils/axiosBaseQuery'
import { UserData } from './UsersService'

export interface ContentTreeTab {
  tabId: number
  title: string
}

export interface ContentTreeSubSection {
  subSectionId: number
  title: string
  tabs: ContentTreeTab[]
}

export interface ContentTreeSection {
  sectionId: number
  title: string
  tabs: ContentTreeTab[]
  subSections: ContentTreeSubSection[]
}

export interface ContentTreeChapter {
  chapterId: number
  title: string
  tabs: ContentTreeTab[]
  sections: ContentTreeSection[]
}

export interface ContentTreeCountry {
  countryId: number
  title: string
  code: string
  chapters: ContentTreeChapter[]
}

export interface UserAccess {
  countryId: number
  chapterId?: number | null
  sectionId?: number | null
  subSectionId?: number | null
  tabId?: number | null
  accessEndDate: string
}

type GetCustomersListResponse = UserData[]

type GetContentTreeResponse = ContentTreeCountry[]

type GetUserAccessResponse = UserAccess[]

type UpsertUserAccessResponse = UserAccess[]

interface UpsertUserAccessRequest {
  userIds: number[]
  items: UserAccess[]
}

const contentAccessApi = createApi({
  reducerPath: 'contentAccessApi',
  tagTypes: ['CustomersList', 'ContentTree', 'UserAccess'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/ContentAccess/'
  }),
  endpoints: builder => ({
    getCustomersList: builder.query<GetCustomersListResponse, void>({
      query: () => ({
        url: 'Customers'
      }),
      providesTags: ['CustomersList']
    }),
    getContentTree: builder.query<GetContentTreeResponse, void>({
      query: () => ({
        url: 'ContentTree'
      }),
      providesTags: ['ContentTree']
    }),
    getUserAccess: builder.query<GetUserAccessResponse, number>({
      query: userId => ({ url: `UserAccess/${userId}` }),
      providesTags: (_, __, id) => [{ type: 'UserAccess', id }]
    }),
    upsertUserAccess: builder.mutation<
      UpsertUserAccessResponse,
      UpsertUserAccessRequest
    >({
      query: data => ({ url: 'UserAccess', method: 'PUT', data }),
      invalidatesTags: (_, __, { userIds }) =>
        userIds.map(id => ({ type: 'UserAccess', id }))
    })
  })
})

export const {
  reducerPath: contentAccessApiReducerPath,
  reducer: contentAccessApiReducer,
  middleware: contentAccessApiMiddleware,
  useGetCustomersListQuery,
  useGetContentTreeQuery,
  useGetUserAccessQuery,
  useUpsertUserAccessMutation,
  util: { invalidateTags: invalidateContentAccessTags }
} = contentAccessApi
