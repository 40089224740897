import { Box, Button } from '@veracity/vui'
import Table from '../../../components/Table'
import { DataGroupListItem } from '../../../store/services/DataGroupsService'

interface Props {
  dataGroups: DataGroupListItem[]
  page: number
  pageSize: number
  total: number
  isLoading?: boolean
  onEdit: (dataGroup: DataGroupListItem) => void
  onDelete: (dataGroup: DataGroupListItem) => void
  onPageChange: (page: number) => void
}
const DataGroupsList = ({
  dataGroups,
  total,
  isLoading,
  page,
  pageSize,
  onPageChange,
  onEdit,
  onDelete
}: Props) => {
  return (
    <Box column px={8} py={4}>
      <Table
        loading={isLoading}
        columns={[
          { key: 'id', dataIndex: 'id' },
          { key: 'name', dataIndex: 'name', title: 'Data group name' },
          {
            key: 'count',
            dataIndex: 'count',
            title: 'Data sources'
          },
          {
            dataIndex: 'id',
            key: 'actions',
            width: 32,
            render: (id, record) => (
              <Box alignItems="center" gap={1}>
                <Button
                  variant="tertiaryDark"
                  isRound
                  icon="falPen"
                  className="show-on-row-hover"
                  onClick={() => onEdit(record)}
                />
                <Button
                  variant="subtleRed"
                  isRound
                  icon="falTrashAlt"
                  className="show-on-row-hover"
                  onClick={() => onDelete(record)}
                />
              </Box>
            )
          }
        ]}
        dataSource={dataGroups.map(dataGroup => ({
          key: dataGroup.id,
          count: dataGroup.dataSourceIds.length,
          ...dataGroup
        }))}
        pagination={{
          current: page,
          pageSize,
          total,
          onChange: onPageChange,
          hideOnSinglePage: true,
          showSizeChanger: false
        }}
      />
    </Box>
  )
}

export default DataGroupsList
