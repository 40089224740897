import { Box, Button, Divider } from '@veracity/vui'
import { ReactNode } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useEditorControls from '../../../components/editor/useEditorControls'
import ContentHeader from '../../../components/layouts/Content/ContentHeader'
import useContentStructure from '../../../hooks/useContentStructure'
import { ChapterType } from '../../../store/services/ContentService'
import useSaveContent from './useSaveContent'

interface EditHeaderProps {
  title?: string
  actions?: ReactNode
}
const EditHeader = ({ title, actions }: EditHeaderProps) => {
  const { country, chapter, section, subsection } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { getContent } = useEditorControls()
  const {
    chapter: chapterData,
    section: sectionData,
    subsection: subSectionData
  } = useContentStructure()

  const rawPath = [country, chapter, section, subsection].filter(Boolean)
  const displayPath: string[] = title
    ? [title]
    : ([chapterData?.title, sectionData?.title, subSectionData?.title].filter(
        Boolean
      ) as string[])

  const handleBack = () => {
    const path = rawPath.join('/')
    navigate('/power-analytics/' + path + `?${searchParams}`)
  }

  const { handler: handleSaveContent, isLoading } = useSaveContent()

  const handleSave = () => {
    if (chapterData) {
      const isMarkdown = chapterData.type === ChapterType.Regular
      const data = isMarkdown
        ? { markdown: getContent(), files: null }
        : { markdown: null, files: [] } // TODO: handle files
      handleSaveContent(data)
    }
  }

  return (
    <ContentHeader
      onBack={handleBack}
      displayPath={displayPath}
      actions={
        actions ? (
          actions
        ) : (
          <Box alignItems="center" gap={2}>
            <Divider isVertical width={2} h={24} />
            <Button
              size="lg"
              icon="falSave"
              variant="tertiaryDark"
              borderRadius={6}
              onClick={handleSave}
              disabled={isLoading}
            />
          </Box>
        )
      }
    />
  )
}

export default EditHeader

