import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Region } from './RegionsService'
import { axiosBaseQuery } from './utils/axiosBaseQuery'

export enum CountryState {
  Complete,
  ComingSoon,
  RestrictedAccess
}
export interface Country {
  id: number
  name: string
  code: string
  isAccessible: boolean
  state: CountryState
  region: Region
}

type GetCountriesListResponse = Country[]

type GetCountryResponse = Country

interface UpsertCountryRequest {
  countryId?: number
  name?: string
  code?: string
  state?: CountryState
  regionId?: number
}

interface UpsertCountryResponse {
  countryId: number
  code: string
  name: string
  stateId: number
}

const countryApi = createApi({
  reducerPath: 'countryApi',
  tagTypes: ['Countries', 'Country'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/Country/'
  }),
  endpoints: builder => ({
    getCountriesList: builder.query<GetCountriesListResponse, void>({
      query: () => ({ url: 'AllCountries' }),
      providesTags: ['Countries']
    }),
    getCountry: builder.query<GetCountryResponse, number>({
      query: countryId => ({ url: `${countryId}` }),
      providesTags: response =>
        response ? [{ type: 'Country', id: response.id }] : ['Country']
    }),
    upsertCountry: builder.mutation<
      UpsertCountryResponse,
      UpsertCountryRequest
    >({
      query: data => ({ url: 'Upsert', method: 'POST', data }),
      invalidatesTags: ['Countries']
    }),
    deleteCountry: builder.mutation<void, number>({
      query: countryId => ({ url: `${countryId}`, method: 'DELETE' }),
      invalidatesTags: ['Countries']
    })
  })
})

export const {
  middleware: countryApiMiddleware,
  reducerPath: countryApiReducerPath,
  reducer: countryApiReducer,
  useGetCountriesListQuery,
  useLazyGetCountryQuery,
  useUpsertCountryMutation,
  useDeleteCountryMutation
} = countryApi

