import { Box, Button } from '@veracity/vui'
import Table from '../../../components/Table'
import { Region } from '../../../store/services/RegionsService'

interface RegionsListProps {
  regions: Region[]
  isLoading: boolean
  onDelete: (id: number) => void
  onEdit: (id: number) => void
}
const RegionsList = ({
  regions,
  isLoading,
  onDelete,
  onEdit
}: RegionsListProps) => {
  const dataSource = regions.map(region => ({ key: region.id, ...region }))

  return (
    <Box column px={8} py={1.5}>
      <Table
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true
        }}
        columns={[
          {
            title: '',
            dataIndex: 'order',
            key: 'order',
            width: 30,
            render: value => value + 1
          },
          {
            title: 'Region name',
            dataIndex: 'name',
            key: 'name',
            className: 'bold'
          },
          {
            title: null,
            dataIndex: 'id',
            key: 'actions',
            width: 32,
            render: id => (
              <Box alignItems="center" gap={1}>
                <Button
                  variant="tertiaryDark"
                  isRound
                  icon="falPen"
                  className="show-on-row-hover"
                  onClick={() => onEdit(id)}
                />
                <Button
                  variant="subtleRed"
                  isRound
                  icon="falTrashAlt"
                  className="show-on-row-hover"
                  onClick={() => onDelete(id)}
                />
              </Box>
            )
          }
        ]}
        dataSource={dataSource}
      />
    </Box>
  )
}

export default RegionsList
