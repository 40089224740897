import { useMemo, useState } from 'react'
import { useGetAllUsersQuery } from '../store/services/UsersService'
import { useDebounce } from './useDebounce'

const useGroupedUserOptions = (userGroupId?: number) => {
  const [rawSearch, setSearch] = useState('')
  const search = useDebounce(rawSearch, 300)
  const { data, isFetching: loading } = useGetAllUsersQuery()
  const options = useMemo(
    () =>
      data
        ?.filter(
          user =>
            user.groups.some(group => group.id === userGroupId) &&
            user.userInfo.displayName
              .toLowerCase()
              .includes(search.toLowerCase())
        )
        .map(user => ({ value: user.id, text: user.userInfo.displayName })) ??
      [],
    [data, search, userGroupId]
  )

  return {
    options,
    onSearch: setSearch,
    loading
  }
}

export default useGroupedUserOptions
