import { Box } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import ManagementCard from '../../components/ManagementCard'
import TilesSection from '../../components/TilesSection'
import usePermissions from '../../hooks/usePermissions'
import { UserRole } from '../../store/services/UsersService'

const ManagementSection = () => {
  const navigate = useNavigate()
  const { isKeymaster, isOneOf } = usePermissions()

  if (!isOneOf([UserRole.Keymaster, UserRole.ContentManager])) {
    return null
  }

  return (
    <Box column mt={8}>
      <TilesSection title="Management">
        {isKeymaster && (
          <ManagementCard
            text="Users Management"
            onClick={() => navigate('/management/users')}
          />
        )}
        <ManagementCard
          text="Content Access Management"
          onClick={() => navigate('/management/content-access')}
        />
        <ManagementCard
          text="Data Sources Management"
          onClick={() => navigate('/management/data-sources')}
        />
        {isKeymaster && (
          <ManagementCard
            text="Regions and countries"
            onClick={() => navigate('/management/regions')}
          />
        )}
        {isKeymaster && (
          <ManagementCard
            text="API Key Management"
            onClick={() => navigate('/management/customer-keys')}
          />
        )}
      </TilesSection>
    </Box>
  )
}

export default ManagementSection

