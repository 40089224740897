import { Spinner } from '@veracity/vui'
import { Table as AntTable } from 'antd'
import type { TableProps } from 'antd/es/table'
import './style.css'

const Table = (props: TableProps<any>) => {
  return (
    <AntTable
      {...props}
      loading={props.loading ? { indicator: <Spinner size="md" /> } : false}
    />
  )
}

export default Table
