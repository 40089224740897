import { createContext, ReactNode, useContext } from 'react'

export enum Mode {
  Read,
  Edit,
  New
}

const modeContext = createContext(Mode.Read)

interface NavModeProviderProps {
  children: ReactNode
  value: Mode
}

export const NavModeProvider = ({ children, value }: NavModeProviderProps) => (
  <modeContext.Provider value={value}>{children}</modeContext.Provider>
)

const useNavMode = () => useContext(modeContext)

export default useNavMode
