import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useFormModal } from '../../../hooks/useFormModal'
import {
  Region,
  useUpsertRegionMutation
} from '../../../store/services/RegionsService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'
import RegionForm from './Form'

export default function useCreateRegion(): () => void {
  const openForm = useFormModal<Partial<Region>>(
    (initValue, onChange, onValidChange) => (
      <RegionForm
        initValue={initValue}
        onChange={onChange}
        onValidChange={onValidChange}
      />
    )
  )
  const [createRegion] = useUpsertRegionMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'create-region',
      loadingMessage: 'Creating region',
      successMessage: 'Region created successfully',
      errorMessage: 'Creating region failed'
    })

  return () => {
    openForm({}, 'Create Region').then(result => {
      if (!result || isLoading) return

      setLoading(true)

      createRegion(result)
        .then(
          handlePromiseError(
            () => operationSuccess(),
            () => operationFail()
          )
        )
        .finally(() => setLoading(false))
    })
  }
}
