import { useDialog } from '@veracity/vui'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useDeleteRegionMutation } from '../../../store/services/RegionsService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

export default function useDeleteRegion(): (id: number) => void {
  const { showConfirm } = useDialog()
  const [deleteRegion] = useDeleteRegionMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'delete-region',
      loadingMessage: 'Deleting region',
      successMessage: 'Region deleted successfully',
      errorMessage: 'Deleting region failed'
    })

  return (id: number) => {
    showConfirm(
      'Delete region',
      'Are you sure you want to delete this region?'
    ).then(confirm => {
      if (!confirm || isLoading) return

      setLoading(true)

      deleteRegion(id)
        .then(
          handlePromiseError(
            () => operationSuccess(),
            () => operationFail()
          )
        )
        .finally(() => setLoading(false))
    })
  }
}
