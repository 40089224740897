import { useDialog } from '@veracity/vui'
import { useSelector } from 'react-redux'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { RootState } from '../../../store'
import { useCopyContentMutation } from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useCloneChapter = (chapterId?: number) => {
  const countryId = useSelector<RootState, number | undefined>(
    state => state.content.countryInfo?.id
  )
  const [cloneChapter] = useCopyContentMutation()

  const { showConfirm } = useDialog()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'chapter-clone',
      loadingMessage: 'Cloning chapter',
      successMessage: 'Chapter cloned successfully',
      errorMessage: 'Cloning chapter failed'
    })

  return () => {
    if (!chapterId || !countryId || isLoading) return
    showConfirm(
      'Clone chapter',
      'Are you sure you want to clone this chapter?'
    ).then(agree => {
      if (agree) {
        setLoading(true)
        cloneChapter({ item: { chapterId }, newPlace: { countryId } })
          .then(
            handlePromiseError(
              () => operationSuccess(),
              () => operationFail()
            )
          )
          .finally(() => setLoading(false))
      }
    })
  }
}

export default useCloneChapter
