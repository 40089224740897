import * as R from 'ramda'

export const parseQuerystring = (queryObject: Record<string, any>) => {
  const keysToOmit = Object.entries(queryObject)
    .filter(([, value]) => R.isEmpty(value) || R.isNil(value))
    .map(([key]) => key)

  const clearedQueryObject = R.omit(keysToOmit, queryObject)

  const params = Object.entries(clearedQueryObject).reduce<string[][]>(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => acc.push([key, item]))

        return acc
      }

      acc.push([key, value as string])

      return acc
    },
    []
  )

  return new URLSearchParams(params).toString()
}

