import { Box, Card, Divider, Heading, Menu, Tooltip } from '@veracity/vui'
import { useNavigate } from 'react-router-dom'
import { DataSourceListItem } from '../../../store/services/DataSourceService'
import { formatArray, formatDateTime } from '../../../utils/format'
import MetaTable from './MetaTable'
import useDeleteDataSource from './useDeleteDataSource'

interface DataSourceProps {
  dataSource: DataSourceListItem
}
const DataSourceCard = ({ dataSource }: DataSourceProps) => {
  const navigate = useNavigate()
  const meta = [
    { name: 'Id', value: dataSource.id },
    { name: 'External Id', value: dataSource.extId },
    { name: 'Columns', value: formatArray(dataSource.columns) },
    { name: 'Rows', value: dataSource.rows },
    { name: 'Updated at', value: formatDateTime(dataSource.lastUpdateDateTime) }
  ]

  const handleDelete = useDeleteDataSource(dataSource.id)

  return (
    <Card column p={2}>
      <Box justifyContent="space-between" alignItems="center">
        <Tooltip text={dataSource.name}>
          <Heading
            size="h5"
            text={dataSource.name}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          />
        </Tooltip>
        <Box>
          <Menu>
            <Menu.Button icon="falEllipsisH" isRound />
            <Menu.List>
              <Menu.Item
                iconLeft="falPen"
                onClick={() => navigate(`${dataSource.id}`)}>
                Edit
              </Menu.Item>
              <Menu.Item iconLeft="falTrashAlt" onClick={handleDelete}>
                Delete
              </Menu.Item>
            </Menu.List>
          </Menu>
        </Box>
      </Box>
      <Divider my={1} />
      <MetaTable meta={meta} />
    </Card>
  )
}

export default DataSourceCard
