import { Box } from '@veracity/vui'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../Styled/BackButton'

const NavHeader = () => {
  const navigate = useNavigate()

  const handleNavigate = useCallback(() => {
    navigate('/')
  }, [navigate])

  return (
    <Box
      alignItems="center"
      px={2.5}
      h={64}
      borderBottom="solid 1px"
      borderBottomColor="#E1E6E9">
      <BackButton
        variant="secondaryDark"
        iconLeft="falSignIn"
        onClick={handleNavigate}>
        Exit
      </BackButton>
    </Box>
  )
}

export default NavHeader
