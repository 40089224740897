import { Box, HeaderSignIn, Heading, P } from '@veracity/vui'
import BackgroundBox from '../../components/Styled/BackgroundBox'

const AnonymousUserHome = () => {
  return (
    <>
      <BackgroundBox url="/assets/images/jumbotron-big.jpg" ratio={0.5}>
        <Box
          column
          p={4}
          position="absolute"
          top="50%"
          left="10vw"
          transform="translate(0, -50%)"
          bg="rgba(255, 255, 255, 0.56)">
          <Heading size="h1" text="Welcome To Power Analytics" />
          <HeaderSignIn mt={2} url="/api/Account/SignIn" />
        </Box>
      </BackgroundBox>
      <Box column my={15}>
        <Heading text="A Little About Power Analytics" align="center" />
        <P
          mt={3}
          textAlign="center"
          text="Power Analytics is a web-based platform that covers the need of having permanent access to updated forecasts required to take sound investment decisions. Through an annual subscription, we provide market-leading information on various energy markets, including Spain and Portugal."
          w="800px"
          mx="auto"
        />
        <P
          mt={3}
          textAlign="center"
          text="Power Analytics provides immediate and up-to-date access to updated Day-ahead Market Price forecast in the long-term run (20years). With a user-friendly design, both input and results are available for the Client in web format, as well as in PDF & Excel Files ready to download (same deliverables as in Stand-Alone study)."
          w="800px"
          mx="auto"
        />
      </Box>
    </>
  )
}

export default AnonymousUserHome
