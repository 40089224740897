import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { Country } from './CountryService'
import { Group } from './GroupsService'
import { axiosBaseQuery } from './utils/axiosBaseQuery'

export enum ChapterType {
  Regular = 'Regular',
  Files = 'Files',
  Updates = 'Updates'
}

export enum ModificationStatus {
  NoChanges,
  HasUpdates,
  IsNew
}

export enum TabsStrategy {
  Move,
  Remove
}

export interface ContentTab {
  id: number
  slugId: string
  title: string
  isAccessible: boolean
  isPublished: boolean
  modificationStatus: ModificationStatus
  groups: Group[]
}

export interface ContentSubSection {
  id: number
  slugId: string
  title: string
  tabs: ContentTab[]
  isAccessible: boolean
  isPublished: boolean
  modificationStatus: ModificationStatus
  groups: Group[]
}

export interface ContentSection {
  id: number
  slugId: string
  title: string
  subSections: ContentSubSection[]
  tabs: ContentTab[]
  isAccessible: boolean
  isPublished: boolean
  modificationStatus: ModificationStatus
  groups: Group[]
}

export interface ContentChapter {
  id: number
  slugId: string
  title: string
  iconName: string
  sections: ContentSection[]
  tabs: ContentTab[]
  type: ChapterType
  isAccessible: boolean
  isPublished: boolean
  modificationStatus: ModificationStatus
  groups: Group[]
}

export interface ContentFile {
  id: number
  type: string // MIME?
  displayName: string
  size: number // BYTES?
  modificationTime: string // Date string
  url: string
}

export interface TabContent {
  markdown: string | null
  files: ContentFile[] | null
}

export interface ContentLocation {
  countryId?: number
  chapterId?: number
  sectionId?: number
  subSectionId?: number
  tabId?: number
}

export interface ContentUpdateLog {
  id: number
  chapter: string
  description: string
  updateTime: string
}

interface ContentStructureResponse {
  chapters: ContentChapter[]
  countryInfo: Country
}

type UpsertChapterResponse = ContentChapter

interface UpsertChapterRequest {
  countryId: number
  chapterId?: number
  slugId?: string
  title?: string
  icon?: string
  type?: string
  order?: number
  isPublished?: boolean
  groupsId?: number[]
}

type UpsertSectionResponse = ContentSection

interface UpsertSectionRequest {
  chapterId: number
  sectionId?: number
  slugId?: string
  title?: string
  order?: number
  isPublished?: boolean
  groupsId?: number[]
}

type UpsertSubSectionResponse = ContentSubSection

interface UpsertSubSectionRequest {
  sectionId: number
  subSectionId?: number
  slugId?: string
  title?: string
  order?: number
  isPublished?: boolean
  groupsId?: number[]
}

type UpsertTabResponse = ContentTab

interface UpsertTabRequest {
  chapterId?: number
  sectionId?: number
  subSectionId?: number
  tabId?: number
  slugId?: string
  title?: string
  order?: number
  content?: TabContent
  isPublished?: boolean
  groupsId?: number[]
}

type TabContentResponse = TabContent

type UpsertTabContentResponse = TabContent

type UpsertTabContentRequest = [number, TabContent]

interface CopyContentRequest {
  item: ContentLocation
  newPlace: ContentLocation
}

interface MoveContentRequest {
  item: ContentLocation
  newPlace: ContentLocation
}

interface ContentUpdatesRequest {
  countryId: number
  pageNumber: number
  pageSize: number
}

interface ContentUpdatesResponse {
  items: ContentUpdateLog[]
  pageNumber: number
  pageSize: number
  total: number
}

const generateTags = (data: ContentStructureResponse) => {
  const tags = data.chapters.reduce<
    {
      type:
        | 'ContentChapter'
        | 'ContentTab'
        | 'ContentSection'
        | 'ContentSubSection'
      id: number
    }[]
  >((acc, chapter) => {
    acc.push({ type: 'ContentChapter', id: chapter.id })
    if (chapter.tabs?.length) {
      chapter.tabs.forEach(tab => acc.push({ type: 'ContentTab', id: tab.id }))
    }
    if (chapter.sections?.length) {
      chapter.sections.forEach(section => {
        acc.push({ type: 'ContentSection', id: section.id })
        if (section.tabs?.length) {
          section.tabs.forEach(tab =>
            acc.push({ type: 'ContentTab', id: tab.id })
          )
        }
        if (section.subSections?.length) {
          section.subSections.forEach(subSection => {
            acc.push({ type: 'ContentSubSection', id: subSection.id })
            if (subSection.tabs?.length) {
              subSection.tabs.forEach(tab =>
                acc.push({ type: 'ContentTab', id: tab.id })
              )
            }
          })
        }
      })
    }

    return acc
  }, [])

  return tags
}

const generateTabParentTag = (
  data: UpsertTabRequest
):
  | {
      type: 'ContentSubSection' | 'ContentSection' | 'ContentChapter'
      id: number
    }
  | 'ContentStructure' => {
  if (data.subSectionId) {
    return { type: 'ContentSubSection', id: data.subSectionId }
  }
  if (data.sectionId) {
    return { type: 'ContentSection', id: data.sectionId }
  }
  if (data.chapterId) {
    return { type: 'ContentChapter', id: data.chapterId }
  }

  return 'ContentStructure'
}

const contentApi = createApi({
  reducerPath: 'contentApi',
  tagTypes: [
    'ContentStructure',
    'ContentChapter',
    'ContentTab',
    'ContentSection',
    'ContentSubSection',
    'TabContent',
    'ContentUpdates'
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/Content/'
  }),
  endpoints: builder => ({
    getContentStructure: builder.query<ContentStructureResponse, string>({
      query: code => ({ url: `ContentStructure/${code}` }),
      providesTags: result =>
        result
          ? [
              { type: 'ContentStructure', id: result.countryInfo.id },
              ...generateTags(result)
            ]
          : ['ContentStructure']
    }),
    upsertChapter: builder.mutation<
      UpsertChapterResponse,
      UpsertChapterRequest
    >({
      query: data => ({ url: 'Chapter', method: 'POST', data }),
      invalidatesTags: (result, _, arg) =>
        result
          ? [
              { type: 'ContentChapter', id: result.id },
              { type: 'ContentStructure', id: arg.countryId }
            ]
          : ['ContentChapter']
    }),
    deleteChapter: builder.mutation<void, number>({
      query: chapterId => ({ url: `Chapter/${chapterId}`, method: 'DELETE' }),
      invalidatesTags: (_, __, id) => [{ type: 'ContentChapter', id }]
    }),
    upsertSection: builder.mutation<
      UpsertSectionResponse,
      UpsertSectionRequest
    >({
      query: data => ({ url: 'Section', method: 'POST', data }),
      invalidatesTags: (result, _, arg) =>
        result
          ? [
              { type: 'ContentSection', id: result.id },
              { type: 'ContentChapter', id: arg.chapterId }
            ]
          : ['ContentSection']
    }),
    deleteSection: builder.mutation<void, number>({
      query: sectionId => ({ url: `Section/${sectionId}`, method: 'DELETE' }),
      invalidatesTags: (_, __, id) => [{ type: 'ContentSection', id }]
    }),
    upsertSubsection: builder.mutation<
      UpsertSubSectionResponse,
      UpsertSubSectionRequest
    >({
      query: data => ({ url: 'SubSection', method: 'POST', data }),
      invalidatesTags: (result, _, args) =>
        result
          ? [
              { type: 'ContentSubSection', id: result.id },
              { type: 'ContentSection', id: args.sectionId }
            ]
          : ['ContentSubSection']
    }),
    deleteSubsection: builder.mutation<void, number>({
      query: subsectionId => ({
        url: `SubSection/${subsectionId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_, __, id) => [{ type: 'ContentSubSection', id }]
    }),
    upsertTab: builder.mutation<UpsertTabResponse, UpsertTabRequest>({
      query: data => ({ url: 'Tab', method: 'POST', data }),
      invalidatesTags: (result, _, args) =>
        result
          ? [{ type: 'ContentTab', id: result.id }, generateTabParentTag(args)]
          : ['ContentTab']
    }),
    deleteTab: builder.mutation<void, number>({
      query: tabId => ({ url: `Tab/${tabId}`, method: 'DELETE' }),
      invalidatesTags: (_, __, id) => [{ type: 'ContentTab', id }]
    }),
    getTabContent: builder.query<TabContentResponse, number>({
      query: tabId => ({ url: `TabContent/${tabId}` }),
      providesTags: (_, __, id) => [{ type: 'TabContent', id }]
    }),
    upsertTabContent: builder.mutation<
      UpsertTabContentResponse,
      UpsertTabContentRequest
    >({
      query: ([tabId, data]) => ({
        url: `TabContent/${tabId}`,
        method: 'PUT',
        data
      }),
      invalidatesTags: (_, __, [id]) => [{ type: 'TabContent', id }]
    }),
    copyContent: builder.mutation<void, CopyContentRequest>({
      query: data => ({ url: 'Copy', method: 'POST', data }),
      invalidatesTags: ['ContentStructure']
    }),
    moveContent: builder.mutation<void, MoveContentRequest>({
      query: data => ({ url: 'Move', method: 'POST', data }),
      invalidatesTags: ['ContentStructure']
    }),
    getContentUpdates: builder.query<
      ContentUpdatesResponse,
      ContentUpdatesRequest
    >({
      query: ({ countryId, pageNumber, pageSize }) => ({
        url: `Updates/${countryId}?pageNumber=${pageNumber}&pageSize=${pageSize}`
      }),
      providesTags: (_, __, { countryId, pageNumber, pageSize }) => [
        {
          type: 'ContentUpdates',
          id: [countryId, pageSize, pageNumber].join('-')
        }
      ]
    })
  })
})

export const {
  middleware: contentApiMiddleware,
  reducerPath: contentApiReducerPath,
  reducer: contentApiReducer,
  util: { invalidateTags: invalidateContentTags },
  useGetContentStructureQuery,
  useUpsertChapterMutation,
  useDeleteChapterMutation,
  useUpsertSectionMutation,
  useDeleteSectionMutation,
  useUpsertSubsectionMutation,
  useDeleteSubsectionMutation,
  useUpsertTabMutation,
  useDeleteTabMutation,
  useGetTabContentQuery,
  useUpsertTabContentMutation,
  useCopyContentMutation,
  useMoveContentMutation,
  useGetContentUpdatesQuery
} = contentApi

