import { Box, Button, Heading, Icon, P } from '@veracity/vui'
import { useSelector } from 'react-redux'
import config from '../../../config'
import { RootState } from '../../../store'

interface ContentRestrictedProps {
  contentPath: string | undefined
}

const ContentRestricted = ({ contentPath }: ContentRestrictedProps) => {
  const EMAIL = config.RequestAccessEmail

  const getSubject = (customerEmail: string) =>
    encodeURI(`User ${customerEmail} requesting access to a content`)
  const getBody = (
    customerName: string,
    customerEmail: string,
    contentPath: string | undefined
  ) =>
    encodeURI(`Dear DNV team,

  We would like to have access to '${contentPath}'. Please let us know the information needed to complete process.
  
  Best regards,
  
  ${customerName}
  ${customerEmail}`)

  const getMailto = (
    customerName: string,
    customerEmail: string,
    contentPath: string | undefined
  ) =>
    `mailto:${EMAIL}?subject=${getSubject(customerEmail)}&body=${getBody(
      customerName,
      customerEmail,
      contentPath
    )}`

  const handleRequestAccess = () => {
    window.location.href = getMailto(customerName, customerEmail, contentPath)
  }

  const customerEmail = useSelector<RootState, string>(
    state => state.user.userInfo?.email ?? 'EMAIL'
  )
  const customerName = useSelector<RootState, string>(
    state => state.user.userInfo?.displayName ?? 'NAME'
  )

  return (
    <Box column bg="white" w="calc(100% - 64px)" m={4}>
      <Box column alignItems="center" py={16}>
        <Box
          bg="#D3EEF8"
          alignItems={'center'}
          w={84}
          h={84}
          borderRadius="50%">
          <Icon ml={4} color={'#1152AD'} name="falLockAlt" h={20} w={20} />
        </Box>

        <Heading size="h4" text={`You don't have access to this content.`} />

        <Button variant="tertiaryDark" isRound onClick={handleRequestAccess}>
          <P size="lg" text={`Request access here.`} />
        </Button>
      </Box>
    </Box>
  )
}

export default ContentRestricted
