import { Box } from '@veracity/vui'
import DataSourcePreview from './DataSourcePreview'
import DBDataSourceForm from './DBDataSourceForm'

const DBDataSource = () => {
  return (
    <Box w="100%" h="100%" overflow="hidden">
      <DBDataSourceForm />
      <DataSourcePreview />
    </Box>
  )
}

export default DBDataSource
