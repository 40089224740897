import { useNavigate } from 'react-router-dom'
import useAsyncOperationToast from '../../../../../hooks/useAsyncOperationToast'
import { useUpdateUserDetailsMutation } from '../../../../../store/services/UsersService'
import { handlePromiseError } from '../../../../../store/services/utils/errorHandling'

const useEditUser = (userId?: number) => {
  const navigate = useNavigate()
  const [editUser] = useUpdateUserDetailsMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'edit-user-details',
      loadingMessage: 'Saving changes',
      successMessage: 'Changes saved successfully',
      errorMessage: 'Saving changes failed'
    })

  return (data: { roleId: number; groupsId: number[] }) => {
    if (isLoading || !userId) return

    setLoading(true)
    editUser({ userId, ...data })
      .then(
        handlePromiseError(
          () => {
            operationSuccess()
            navigate(-1)
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default useEditUser

