import { Box, Heading, Spinner } from '@veracity/vui'
import { useGetCountriesListQuery } from '../../store/services/CountryService'
import { useGetRegionsListQuery } from '../../store/services/RegionsService'
import CustomerAccessibleCountries from './CustomerAccessibleCountries'
import GroupedCountries from './GroupedCountries'
import { groupCountries } from './utils'

const PowerAnalyticsSection = () => {
  const { isFetching: isCountriesFetching, data: countries } =
    useGetCountriesListQuery()
  const { isFetching: isRegionsFetching, data: regions } =
    useGetRegionsListQuery({})

  const isLoading = isCountriesFetching || isRegionsFetching

  const groupedCountries = groupCountries(countries, regions)

  return (
    <Box column my={8}>
      <Heading size="h3" text="Power Analytics" weight={600} />
      {isLoading && <Spinner />}
      <CustomerAccessibleCountries groupedCountries={groupedCountries} />
      <GroupedCountries groupedCountries={groupedCountries} />
    </Box>
  )
}

export default PowerAnalyticsSection

