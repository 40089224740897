import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useFormModal } from '../../../hooks/useFormModal'
import {
  Country,
  useUpsertCountryMutation
} from '../../../store/services/CountryService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'
import CountryForm from './Form'

interface FormData extends Partial<Country> {
  regionId?: number
  stateId?: number
}

export default function useCreateCountry(): () => void {
  const openForm = useFormModal<FormData>(
    (initValue, onChange, onValidChange) => (
      <CountryForm
        initValue={initValue}
        onChange={onChange}
        onValidChange={onValidChange}
      />
    )
  )
  const [createCountry] = useUpsertCountryMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'create-country',
      loadingMessage: 'Creating country',
      successMessage: 'Country created successfully',
      errorMessage: 'Creating country failed'
    })

  return () => {
    openForm({}, 'Create Country').then(result => {
      if (!result || isLoading) return

      setLoading(true)

      createCountry(result)
        .then(
          handlePromiseError(
            () => operationSuccess(),
            () => operationFail()
          )
        )
        .finally(() => setLoading(false))
    })
  }
}
