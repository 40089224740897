import { Box } from '@veracity/vui'
import { FormProvider, useForm } from 'react-hook-form'
import { useDebounce } from '../../../hooks/useDebounce'
import { useGetGroupListQuery } from '../../../store/services/GroupsService'
import GroupsManagementHeader from './Header'
import GroupList from './List'
import useCreateGroup from './useCreateGroup'
import useEditGroup from './useEditGroup'
import useHandleDelete from './useHandleDelete'

const GroupsManagementPage = () => {
  const form = useForm({
    defaultValues: {
      searchName: '',
      pageSize: 10,
      pageNumber: 1
    }
  })

  const searchName = useDebounce(form.watch('searchName'), 300)
  const pageSize = form.watch('pageSize')
  const pageNumber = form.watch('pageNumber')

  const { data, isFetching } = useGetGroupListQuery({
    searchName,
    pageSize,
    pageNumber
  })
  const groups = data?.groups ?? []
  const total = data?.total ?? 0

  const handleCreate = useCreateGroup()
  const editGroup = useEditGroup()
  const handleDelete = useHandleDelete()

  const handleEdit = (id: number) => {
    const group = groups.find(group => group.id === id)
    if (group) editGroup(group)
  }

  return (
    <Box column w="100%">
      <FormProvider {...form}>
        <GroupsManagementHeader onCreate={() => handleCreate()} />
        <GroupList
          total={total}
          groups={groups}
          isLoading={isFetching}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      </FormProvider>
    </Box>
  )
}

export default GroupsManagementPage
