import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@veracity/vui'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FormInput from '../../../components/form/FormInput'
import * as yup from 'yup'
import CountryStatus from '../../../components/CountryCard/CountryStatus'
import { Country, CountryState } from '../../../store/services/CountryService'
import FormSelect from '../../../components/form/FormSelect'
import { useGetRegionsListQuery } from '../../../store/services/RegionsService'

const schema = yup
  .object({
    name: yup.string().min(3).required(),
    code: yup
      .string()
      .lowercase()
      .matches(/[a-z]{2}/i)
      .required(),
    stateId: yup
      .number()
      .equals([CountryState.ComingSoon, CountryState.Complete])
      .required(),
    regionId: yup.number().required()
  })
  .required()

interface CountryFormProps {
  initValue: Partial<Country>
  onChange: (value: Partial<Country>) => void
  onValidChange: (valid: boolean) => void
}
const CountryForm = ({
  initValue,
  onChange,
  onValidChange
}: CountryFormProps) => {
  const { data } = useGetRegionsListQuery({})
  const regionOptions =
    data?.map(region => ({ text: region.name, value: region.id })) ?? []
  const stateOptions = [
    { text: 'Coming soon', value: CountryState.ComingSoon },
    { text: 'Complete', value: CountryState.Complete }
  ]
  const form = useForm({
    defaultValues: parseCountry(initValue),
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const values = form.watch()
  const valid = form.formState.isValid
  const state = form.watch('stateId') as CountryState

  useEffect(() => {
    onChange(values)
  }, [values, onChange])

  useEffect(() => {
    onValidChange(valid)
  }, [valid, onValidChange])

  return (
    <Box column px={3}>
      <Box column w="100%" alignItems="flex-end">
        <CountryStatus status={state} />
      </Box>
      <Box column gap={3}>
        <FormInput
          size="md"
          label="Country name"
          name="name"
          control={form.control}
        />
        <FormInput
          size="md"
          label="Country code (ISO 3166-1 Alpha-2)"
          name="code"
          control={form.control}
        />
        <FormSelect
          label="Region"
          name="regionId"
          control={form.control}
          options={regionOptions}
        />
        <FormSelect
          label="Country status"
          name="stateId"
          control={form.control}
          options={stateOptions}
          disabled={!initValue.id}
        />
      </Box>
    </Box>
  )
}

function parseCountry(country: Partial<Country>) {
  const { region, id, state, ...copy } = country

  return {
    ...copy,
    stateId: state === undefined ? 1 : state,
    countryId: id,
    regionId: region?.id
  }
}

export default CountryForm
