import { Box, Button, Card, Spinner } from '@veracity/vui'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import FormInput from '../../../../../components/form/FormInput'
import FormStrictTree from '../../../../../components/form/FormStrictTree'
import { useGetContentTreeQuery } from '../../../../../store/services/ContentAccessService'
import { mapToAccessTree } from '../../../ContentAccess/utils'

interface EditAccessFormProps {
  onSubmit: () => void
}
const EditAccessForm = ({ onSubmit }: EditAccessFormProps) => {
  const navigate = useNavigate()
  const form = useFormContext()
  const { data, isFetching } = useGetContentTreeQuery()

  const accessTree = useMemo(() => mapToAccessTree(data ?? []), [data])

  return (
    <Card column p={2} w={528} gap={2}>
      {isFetching && <Spinner size="md" />}
      <FormStrictTree
        name="access"
        control={form.control}
        treeData={accessTree}
      />
      <FormInput
        size="md"
        name="endAccessDate"
        label="Access until"
        control={form.control}
        type="date"
      />
      <Box gap={1} justifyContent="flex-end" mt={4}>
        <Button
          text="Cancel"
          variant="secondaryDark"
          borderRadius={6}
          onClick={() => navigate(-1)}
        />
        <Button
          disabled={!form.formState.isDirty || !form.formState.isValid}
          text="Save"
          variant="primaryDark"
          borderRadius={6}
          onClick={onSubmit}
        />
      </Box>
    </Card>
  )
}

export default EditAccessForm
