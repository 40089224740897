import { Box, BoxProps, Heading } from '@veracity/vui'
import { ReactNode } from 'react'

interface FormLabelProps extends BoxProps {
  text: string
  children: ReactNode
  inputId?: string
}
const FormLabel = ({ text, children, inputId, ...props }: FormLabelProps) => (
  <Box column {...props}>
    <label htmlFor={inputId}>
      <Heading size="h6" mb={0.5} text={text} />
    </label>
    {children}
  </Box>
)

export default FormLabel
