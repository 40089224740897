import dayjs from 'dayjs'
import { UserRole } from '../store/services/UsersService'

export const formatDateTime = (date: string | Date) => {
  return dayjs(date).format('DD-MM-YYYY hh:mm A')
}

export const formatDate = (date: string | Date) => {
  return dayjs(date).format('DD/MM/YYYY')
}

export const formatRole = (userRole: UserRole) => {
  if (userRole === UserRole.Customer) return 'Customer'
  if (userRole === UserRole.ContentManager) return 'Content Manager'
  if (userRole === UserRole.Keymaster) return 'Keymaster'

  return 'Unknown'
}

export const formatArray = (array: string[], separator: string = ', ') => {
  if (!array?.length) return ''

  return array.join(separator)
}

