import { T, Tooltip, TProps } from '@veracity/vui'
import { useRef, useState } from 'react'

const EllipsisText = (
  props: TProps & {
    text: string
    placement?: 'top' | 'left' | 'bottom' | 'right'
  }
) => {
  const ref = useRef<HTMLSpanElement>(null)
  const [isEllipsis, setEllipsis] = useState(false)

  const content = (
    <T
      ref={ref}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      onMouseEnter={e =>
        setEllipsis(e.currentTarget.offsetWidth < e.currentTarget.scrollWidth)
      }
      {...props}
    />
  )

  if (isEllipsis)
    return (
      <Tooltip text={props.text} placement={props.placement}>
        {content}
      </Tooltip>
    )

  return content
}

export default EllipsisText
