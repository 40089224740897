import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, SelectOptionData } from '@veracity/vui'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import FormIconPicker from '../../../components/form/FormIconPicker'
import FormInput from '../../../components/form/FormInput'
import FormSelect from '../../../components/form/FormSelect'
import FormTagMultiselect from '../../../components/form/FormTagMultiselect'
import useGroupOptions from '../../../hooks/useGroupOptions'
import { ChapterType } from '../../../store/services/ContentService'

interface FormInputs {
  title: string
  icon: string
  type: ChapterType
  groupsId: number[]
}

const schema = yup
  .object({
    title: yup.string().min(3).required(),
    icon: yup.string().required(),
    type: yup
      .string()
      .equals([ChapterType.Regular, ChapterType.Files])
      .required(),
    groupsId: yup.array(yup.number())
  })
  .required()

interface ChapterFormProps {
  initialValues?: FormInputs
  initOptions?: SelectOptionData[]
  isLoading?: boolean
  onSubmit: (values: FormInputs) => void
}
const ChapterForm = ({
  onSubmit,
  initialValues,
  initOptions = [],
  isLoading
}: ChapterFormProps) => {
  const form = useForm<FormInputs>({
    defaultValues: initialValues ?? {
      title: '',
      icon: 'falChartBar',
      type: ChapterType.Regular,
      groupsId: []
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const handleSubmit = () => {
    form.handleSubmit(values => {
      onSubmit(values)
    })()
  }

  const { options: groupOptions, onSearch, loading } = useGroupOptions()

  useEffect(() => {
    form.reset(initialValues)
  }, [form, initialValues])

  return (
    <Card column maxW={528} flex={1} p={4}>
      <Box column gap={3}>
        <FormInput
          size="md"
          name="title"
          label="Chapter title"
          control={form.control}
        />
        <FormSelect
          name="type"
          label="Chapter type"
          control={form.control}
          options={[
            { text: 'Regular', value: ChapterType.Regular },
            { text: 'Files', value: ChapterType.Files }
          ]}
          disabled={Boolean(initialValues)}
        />
        <FormIconPicker name="icon" label="Icon" control={form.control} />
        <FormTagMultiselect
          name="groupsId"
          label="Group"
          control={form.control}
          options={groupOptions}
          initOptions={initOptions}
          size="md"
          iconLeft="falSearch"
          onSearch={onSearch}
          loading={loading}
        />
        <Button
          variant="secondaryDark"
          borderRadius={6}
          disabled={!form.formState.isValid || isLoading}
          onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Card>
  )
}

export default ChapterForm

