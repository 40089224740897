import { Box, Grid } from '@veracity/vui'
import ActivityLogCard from './ActivityLogCard'
import UserDetailsHeader from './Header'
import PagesCard from './PagesCard'
import PersonalInformationCard from './PersonalInformationCard'

const UserDetailsPage = () => {
  return (
    <Box w="100%" column>
      <UserDetailsHeader />
      <Grid gap={3} w="100%" px={8} py={4} gridTemplateColumns={2}>
        <PersonalInformationCard />
        <PagesCard gridColumn={2} gridRow="1 / 3" />
        <ActivityLogCard h={640} />
      </Grid>
    </Box>
  )
}

export default UserDetailsPage
