import { Box, Link, Spinner, Table, VuiProvider } from '@veracity/vui'
import { useMemo } from 'react'
import { TableConfig } from './types'

const processData = (data: string[][]) => {
  const columns = data[0]
  const rows = data.slice(1)
  return rows.map(row =>
    columns.reduce<Record<string, string | number>>((obj, column, index) => {
      const columnData = row[index]

      if (typeof columnData === 'string' && !columnData) return obj

      const numberValue = Number(columnData)
      const value = isNaN(numberValue) ? columnData : numberValue

      if (value !== null) {
        obj[column] = value
      }

      return obj
    }, {})
  )
}

interface TableRendererProps {
  config: TableConfig
  data: string[][]
  downloadLink?: string
  loading?: boolean
}
const TableRenderer = (props: TableRendererProps) => {
  const { config, downloadLink, loading } = props
  const data = useMemo(() => processData(props.data), [props.data])

  return (
    <>
      {(downloadLink || loading) && (
        <VuiProvider>
          {downloadLink && (
            <Box column alignItems="flex-end">
              <Link
                text="Download CSV"
                iconLeft="falFileCsv"
                href={downloadLink}
              />
            </Box>
          )}
          {loading && <Spinner size="sm" />}
        </VuiProvider>
      )}
      <Table {...config} rows={data} />
    </>
  )
}

export default TableRenderer
