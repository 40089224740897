import { Dialog, useVuiContext } from '@veracity/vui'
import { ReactElement, useCallback, useRef, useState } from 'react'

type OnValidChange = (valid: boolean) => void
type OnChange<T> = (value: T) => void
type Renderer<InitValue, Response> = (
  initValue: InitValue,
  onChange: OnChange<Response>,
  onValidChange: OnValidChange
) => ReactElement
export function useFormModal<InitValue, Response = InitValue>(
  renderer: Renderer<InitValue, Response>
) {
  const { setPortalSlot } = useVuiContext()

  return (
    initValue: InitValue,
    title: string
  ): Promise<Response | false | null> =>
    new Promise(resolve => {
      const handleCancel = () => {
        resolve(false)
        setPortalSlot(null)
      }

      const handleSubmit = (value: Response | null) => {
        resolve(value)
        setPortalSlot(null)
      }

      setPortalSlot(
        <FormModal
          title={title}
          initValue={initValue}
          renderer={renderer}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      )
    })
}

interface FormModalProps<InitValue, Response> {
  title: string
  initValue: InitValue
  renderer: Renderer<InitValue, Response>
  onCancel: () => void
  onSubmit: (value: Response | null) => void
}
function FormModal<InitValue, Response>({
  title,
  initValue,
  renderer,
  onCancel,
  onSubmit
}: FormModalProps<InitValue, Response>) {
  const formValue = useRef<Response | null>(null)
  const [isValid, setValid] = useState(true)
  const handleSubmit = useCallback(
    () => onSubmit(formValue.current),
    [onSubmit, formValue]
  )

  return (
    <Dialog
      body={renderer(
        initValue,
        value => (formValue.current = value),
        value => setValid(value)
      )}
      cancelButton={{
        onClick: onCancel,
        text: 'Cancel'
      }}
      closeButton={false}
      isOpen
      submitButton={{
        disabled: !isValid,
        onClick: handleSubmit,
        text: 'Submit'
      }}
      title={title}
    />
  )
}
