import { Box, Button, Divider, Heading, Icon } from '@veracity/vui'
import FormInput from '../../../../components/form/FormInput'
import { useFormContext } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import useSaveDataGroup from './useSaveDataGroup'

const DataGroupEditHeader = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const form = useFormContext()
  const handleSave = useSaveDataGroup()

  const handleCancel = () => {
    navigate(-1)
  }

  const handleSubmit = form.handleSubmit(values => {
    const { search, ...formValues } = values
    handleSave(formValues)
  })

  return (
    <Box
      py={1}
      px={8}
      bg={'#F1F3F5'}
      position="sticky"
      top={0}
      zIndex={100}
      justifyContent="space-between"
      alignItems="center">
      <Heading
        text={id ? `Edit group #${id}` : 'Create group'}
        weight="600"
        size="h3"
      />
      <Box alignItems="center" gap={2}>
        <FormInput
          size="md"
          name="search"
          control={form.control}
          label=""
          iconLeft={<Icon name="falSearch" color="#698091" h={16} ml={0.5} />}
          placeholder="Search"
          borderRadius={6}
        />
        <Divider isVertical width={2} h={24} />
        <Box gap={1}>
          <Button text="Cancel" variant="blueOutlined" onClick={handleCancel} />
          <Button
            text="Save changes"
            disabled={!form.formState.isValid}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default DataGroupEditHeader

