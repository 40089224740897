import { Box, Button, Divider, Heading, Icon } from '@veracity/vui'
import { useFormContext } from 'react-hook-form'
import FormInput from '../../../components/form/FormInput'

interface RegionsManagementHeaderProps {
  onCreate: () => void
}
const RegionsManagementHeader = ({
  onCreate
}: RegionsManagementHeaderProps) => {
  const form = useFormContext()

  return (
    <Box
      py={1}
      px={8}
      bg={'#F1F3F5'}
      position="sticky"
      top={0}
      zIndex={100}
      alignItems="center"
      justifyContent="space-between">
      <Heading text="Regions" weight="600" size="h3" />
      <Box alignItems="center" gap={2}>
        <FormInput
          size="md"
          name="search"
          control={form.control}
          label=""
          iconLeft={<Icon name="falSearch" color="#698091" h={16} ml={0.5} />}
          borderRadius={6}
          placeholder="Search"
        />
        <Divider isVertical width={2} h={24} />
        <Button iconLeft="falPlus" text="Add region" onClick={onCreate} />
      </Box>
    </Box>
  )
}

export default RegionsManagementHeader
