import { Link, Spinner, VuiProvider } from '@veracity/vui'
import { useMemo } from 'react'
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  AreaChart,
  BarChart,
  LineChart
} from 'recharts'
import { ChartConfig, ChartType, DataSetType } from './types'

const processData = (
  data: string[][],
  combineRanges?: Record<string, [string, string]>
) => {
  const columns = data[0]
  const rows = data.slice(1)
  const dataObjects = rows.map(row =>
    columns.reduce<Record<string, string | number>>((obj, column, index) => {
      const columnData = row[index]

      if (typeof columnData === 'string' && !columnData) return obj

      const numberValue = Number(columnData)
      const value = isNaN(numberValue) ? columnData : numberValue

      if (value !== null) {
        obj[column] = value
      }

      return obj
    }, {})
  )

  if (!combineRanges) {
    return dataObjects
  }

  return dataObjects.map(dataObject => {
    const newDataObject: Record<string, any> = { ...dataObject }

    Object.entries(combineRanges).forEach(([key, [fromKey, toKey]]) => {
      newDataObject[key] = [newDataObject[fromKey], newDataObject[toKey]]
    })

    return newDataObject
  })
}

interface ChartRendererProps {
  config: ChartConfig
  data: string[][]
  downloadLink?: string
  loading?: boolean
}

const ChartRenderer = (props: ChartRendererProps) => {
  const { downloadLink, config, loading } = props

  const {
    type,
    size,
    options,
    xAxis,
    yAxis,
    tooltip,
    legend,
    grid,
    dataSets,
    combineRanges
  } = config

  const data = useMemo(
    () => processData(props.data, combineRanges),
    [props.data, combineRanges]
  )

  const Chart = {
    [ChartType.Area]: AreaChart,
    [ChartType.Bar]: BarChart,
    [ChartType.Line]: LineChart,
    [ChartType.Composed]: ComposedChart
  }[type]

  return (
    <>
      {(loading || downloadLink) && (
        <VuiProvider>
          {downloadLink && (
            <Link
              text="Download CSV"
              iconLeft="falFileCsv"
              href={downloadLink}
              position="absolute"
              zIndex={1}
              right={0}
            />
          )}
          {loading && <Spinner size="sm" />}
        </VuiProvider>
      )}
      {!loading && (
        <ResponsiveContainer {...size}>
          <Chart data={data} {...options}>
            {xAxis?.map(xAxisConfig => (
              <XAxis key={'xAxis' + xAxisConfig.xAxisId} {...xAxisConfig} />
            ))}
            {yAxis?.map(yAxisConfig => (
              <YAxis key={'yAxis' + yAxisConfig.yAxisId} {...yAxisConfig} />
            ))}
            {tooltip && <Tooltip {...tooltip} />}
            {legend && <Legend {...legend} />}
            {grid && <CartesianGrid {...grid} />}
            {dataSets?.map(({ dataKey, type, name, props }) => {
              const Component = {
                [DataSetType.Area]: Area,
                [DataSetType.Bar]: Bar,
                [DataSetType.Line]: Line
              }[type] as any

              return (
                <Component
                  key={name ? dataKey + type + name : dataKey + type}
                  dataKey={dataKey}
                  name={name}
                  {...props}
                />
              )
            })}
          </Chart>
        </ResponsiveContainer>
      )}
    </>
  )
}

export default ChartRenderer
