import { Box } from '@veracity/vui'
import { FormProvider, useForm } from 'react-hook-form'
import { useDebounce } from '../../../hooks/useDebounce'
import { useGetRegionsListQuery } from '../../../store/services/RegionsService'
import RegionsManagementHeader from './Header'
import RegionsList from './List'
import useCreateRegion from './useCreateRegion'
import useDeleteRegion from './useDeleteRegion'
import useEditRegion from './useEditRegion'

const RegionsManagementPage = () => {
  const form = useForm({
    defaultValues: {
      search: ''
    }
  })

  const search = useDebounce(form.watch('search'), 300)

  const { data, isFetching } = useGetRegionsListQuery({ search })

  const handleCreate = useCreateRegion()
  const handleEdit = useEditRegion()
  const handleDelete = useDeleteRegion()

  return (
    <Box column w="100%">
      <FormProvider {...form}>
        <RegionsManagementHeader onCreate={handleCreate} />
      </FormProvider>
      <RegionsList
        regions={data ?? []}
        isLoading={isFetching}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Box>
  )
}

export default RegionsManagementPage
