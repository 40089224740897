import { Select, SelectProps } from '@veracity/vui'
import { Control, useController } from 'react-hook-form'
import FormLabel from './FormLabel'

interface FormSelectProps extends SelectProps {
  name: string
  label: string
  control: Control<any>
  w?: string | number
}
const FormSelect = ({ name, label, control, w, ...props }: FormSelectProps) => {
  const {
    field: { value, onChange }
  } = useController({ name, control })

  return (
    <FormLabel text={label} w={w}>
      <Select {...props} value={value} onChange={onChange} />
    </FormLabel>
  )
}

export default FormSelect

