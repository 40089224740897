import { Box, Heading, Icon } from '@veracity/vui'
import { useFormContext } from 'react-hook-form'
import FormInput from '../../../components/form/FormInput'

const UsersManagementHeader = () => {
  const form = useFormContext()

  return (
    <Box
      py={1}
      px={8}
      bg={'#F1F3F5'}
      position="sticky"
      top={0}
      zIndex={100}
      alignItems="center"
      justifyContent="space-between">
      <Heading text="Users" weight="600" size="h3" />
      <FormInput
        size="md"
        name="search"
        control={form.control}
        label=""
        iconLeft={<Icon name="falSearch" color="#698091" h={16} ml={0.5} />}
        borderRadius={6}
        placeholder="Search"
      />
    </Box>
  )
}

export default UsersManagementHeader
