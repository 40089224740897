import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useUpsertSubsectionMutation } from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const usePublishSubSection = (sectionId?: number, subSectionId?: number) => {
  const [publishSubSection] = useUpsertSubsectionMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'publish-sub-section',
      loadingMessage: 'Publishing sub section',
      successMessage: 'Sub section published successfully',
      errorMessage: 'Publishing sub section failed'
    })

  return () => {
    if (isLoading || !sectionId || !subSectionId) return

    setLoading(true)
    publishSubSection({ sectionId, subSectionId, isPublished: true })
      .then(
        handlePromiseError(
          () => operationSuccess(),
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default usePublishSubSection
