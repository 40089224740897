import NavigationTab from './NavigationTab'

interface TabNavigationProps {
  tabs: {
    label: string
    href: string
  }[]
}
const TabNavigation = ({ tabs }: TabNavigationProps) => (
  <>
    {tabs.map(tab => (
      <NavigationTab key={tab.href} {...tab} />
    ))}
  </>
)

export default TabNavigation
