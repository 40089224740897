import { Box, Heading, P } from '@veracity/vui'

const AboutPage = () => (
  <Box column my={15}>
    <Heading text="A Little About Power Analytics" align="center" />
    <P
      mt={3}
      textAlign="center"
      text="Power Analytics is a web-based platform that covers the need of having permanent access to updated forecasts required to take sound investment decisions. Through an annual subscription, we provide market-leading information on various energy markets, including Spain and Portugal."
      w="800px"
      mx="auto"
    />
    <P
      mt={3}
      textAlign="center"
      text="Power Analytics provides immediate and up-to-date access to updated Day-ahead Market Price forecast in the long-term run (20years). With a user-friendly design, both input and results are available for the Client in web format, as well as in PDF & Excel Files ready to download (same deliverables as in Stand-Alone study)."
      w="800px"
      mx="auto"
    />
  </Box>
)

export default AboutPage
