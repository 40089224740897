import { Box, Button, Heading } from '@veracity/vui'
import { useState } from 'react'
import CollapseWrapper from '../../../../../components/CollapseWrapper'
import {
  ContentTreeSubSection,
  UserAccess
} from '../../../../../store/services/ContentAccessService'
import TabNode from './TabNode'

interface SubSectionAccessTreeProps {
  countryId: number
  chapterId: number
  sectionId: number
  subSection: ContentTreeSubSection
  userAccess: UserAccess[]
  globalAccess: boolean
}
const SubSectionAccessTree = ({
  countryId,
  chapterId,
  sectionId,
  subSection,
  userAccess,
  globalAccess
}: SubSectionAccessTreeProps) => {
  const [collapsed, setCollapsed] = useState(true)

  const parentAccess = userAccess.some(
    ua =>
      ua.countryId === countryId &&
      ua.chapterId === chapterId &&
      ua.sectionId === sectionId &&
      !ua.subSectionId &&
      !ua.tabId
  )
  const disabled = !(
    globalAccess ||
    parentAccess ||
    userAccess.some(ua => ua.subSectionId === subSection.subSectionId)
  )

  return (
    <Box column>
      <Box alignItems="center" gap={0.5}>
        <Button
          size="sm"
          icon="falChevronDown"
          variant="tertiaryDark"
          transform={`rotate(${collapsed ? '0deg' : '180deg'})`}
          isRound
          onClick={() => setCollapsed(state => !state)}
          disabled={disabled}
        />
        <Heading
          size="h5"
          text={subSection.title}
          color={disabled ? 'grey.40' : undefined}
        />
      </Box>
      <CollapseWrapper column collapsed={collapsed}>
        <Box column ml={4}>
          {subSection.tabs.map(tab => (
            <TabNode
              key={tab.tabId}
              countryId={countryId}
              chapterId={chapterId}
              sectionId={sectionId}
              subSectionId={subSection.subSectionId}
              tab={tab}
              userAccess={userAccess}
            />
          ))}
        </Box>
      </CollapseWrapper>
    </Box>
  )
}

export default SubSectionAccessTree
