import NavChapter from './NavChapter'
import { Box } from '@veracity/vui'
import useNavMode from './useNavMode'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { useParams } from 'react-router-dom'
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd'
import { useEffect, useState } from 'react'
import {
  ContentChapter,
  useUpsertChapterMutation
} from '../../../store/services/ContentService'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'
import { reorderList } from '../../../utils/list'
import { applyDemoMode } from '../../../utils/content'

interface NavChaptersProps {
  onNavigate: (path: string[]) => void
}

const NavChapters = ({ onNavigate }: NavChaptersProps) => {
  const { country } = useParams()
  const chapters = useSelector<RootState, ContentChapter[]>(
    state => state.content.chapters ?? []
  )
  const countryId = useSelector<RootState, number | undefined>(
    state => state.content.countryInfo?.id
  )
  const isDemoMode = useSelector<RootState, boolean>(
    state => state.user.isDemoMode
  )
  const mode = useNavMode()

  const [navigationItems, setNavigationItems] = useState(chapters)
  const [moveChapter] = useUpsertChapterMutation()

  useEffect(() => {
    setNavigationItems(applyDemoMode(isDemoMode, chapters))
  }, [chapters, isDemoMode])

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'move-chapter',
      loadingMessage: 'Saving changes',
      successMessage: 'Changes saved successfully',
      errorMessage: 'Saving changes failed'
    })

  const handleDragEnd: OnDragEndResponder = result => {
    if (isLoading || !result.destination || !countryId) return

    const movedChapter = navigationItems[result.source.index]
    const order = result.destination.index
    const items = reorderList(
      navigationItems,
      result.source.index,
      result.destination.index
    )

    setNavigationItems(items)

    setLoading(true)
    moveChapter({ chapterId: movedChapter.id, order, countryId })
      .then(
        handlePromiseError(
          () => operationSuccess(),
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="navigation-chapters">
        {provided => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            column
            overflowY="auto"
            minH="calc(100% - 144px)"
            maxH="calc(100% - 144px)"
            pb={8}>
            {navigationItems.map((chapter, index) => (
              <Draggable
                key={chapter.id}
                draggableId={`chapter-item-${chapter.id}`}
                index={index}>
                {provided => (
                  <NavChapter
                    chapter={chapter}
                    countryId={countryId}
                    country={country}
                    key={chapter.id}
                    mode={mode}
                    onNavigate={onNavigate}
                    draggable={provided}
                  />
                )}
              </Draggable>
            ))}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default NavChapters

