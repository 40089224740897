import { Box, Spinner } from '@veracity/vui'
import { Navigate, useSearchParams } from 'react-router-dom'
import Viewer from '../../../components/editor/Viewer'
import FileListViewer from '../../../components/FileList/Viewer'
import useContentStructure from '../../../hooks/useContentStructure'
import {
  ContentTab,
  useGetTabContentQuery
} from '../../../store/services/ContentService'
import EmptyStructure from './EmptyStructure'
import DetailsHeader from './Header'
import DetailsTabs from './Tabs'
import ContentRestricted from './ContentRestricted'

const PowerAnalyticsDetailsPage = () => {
  const {
    tabs = [],
    pathToFirst,
    contentPath,
    isAccessible,
    tab
  } = useContentStructure()
  const [, setURLSearchParams] = useSearchParams()

  const activeTabId = tab?.id

  const noActiveTab = !tab
  const { data: content, isFetching: contentIsLoading } = useGetTabContentQuery(
    activeTabId!,
    { skip: noActiveTab }
  )

  const handleTabChange = ({ slugId }: ContentTab) => {
    setURLSearchParams({ tab: slugId })
  }

  const noTabs = !tabs.length

  if (pathToFirst) return <Navigate to={pathToFirst} />

  return (
    <Box column w="100%">
      <DetailsHeader />
      {!noTabs && (
        <Box column overflow="auto">
          <DetailsTabs tabs={tabs} onTabChange={handleTabChange} />
          {isAccessible && (
            <Box column w="100%" py={2} px={4}>
              {contentIsLoading && <Spinner size="md" />}
              {!contentIsLoading && content && content.markdown !== null && (
                <Viewer value={content.markdown} />
              )}
              {!contentIsLoading && content && content.files !== null && (
                <FileListViewer value={content.files} />
              )}
            </Box>
          )}
          {!isAccessible && <ContentRestricted contentPath={contentPath} />}
        </Box>
      )}
      {noTabs && isAccessible && <EmptyStructure />}
      {noTabs && !isAccessible && (
        <ContentRestricted contentPath={contentPath} />
      )}
    </Box>
  )
}

export default PowerAnalyticsDetailsPage
