import { Box } from '@veracity/vui'
import ContentTab from '../../../components/ContentTab'
import useContentStructure from '../../../hooks/useContentStructure'
import { ContentTab as ContentTabData } from '../../../store/services/ContentService'

interface DetailsTabsProps {
  tabs: ContentTabData[]
  onTabChange: (tab: ContentTabData) => void
}
const DetailsTabs = ({ tabs, onTabChange }: DetailsTabsProps) => {
  const { tab: activeTab } = useContentStructure()

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      gap={1}
      py={2}
      wrap
      bg="#F1F3F5"
      w="100%"
      mb={2}>
      {tabs.map(tab => (
        <ContentTab
          key={tab.id}
          tab={tab}
          isActive={tab.id === activeTab?.id}
          onClick={onTabChange}
        />
      ))}
    </Box>
  )
}

export default DetailsTabs

