import { Box, useDialog } from '@veracity/vui'
import { useParams } from 'react-router-dom'
import ContentTab from '../../../components/ContentTab'
import useEditorControls from '../../../components/editor/useEditorControls'
import useContentStructure from '../../../hooks/useContentStructure'
import {
  ContentLocation,
  ContentTab as ContentTabData,
  TabContent
} from '../../../store/services/ContentService'
import TabCreate from './TabCreate'
import usePublishTab from './usePublishTab'

interface EditTabsProps {
  tabs: ContentTabData[]
  onTabChange: (tab: ContentTabData) => void
  onTabDelete: (tab: ContentTabData) => void
  onTabCreate: () => void
  onTabEdit: (tab: ContentTabData) => void
  onTabMove?: (tab: ContentTabData, location: ContentLocation) => void
  onTabCopyTo?: (tab: ContentTabData, location: ContentLocation) => void
  onTabClone?: (tab: ContentTabData) => void
  onContentCompare: (currentContent: TabContent) => {
    equalMarkdown: boolean
    equalFiles: boolean
  }
}
const EditTabs = ({
  tabs,
  onTabChange,
  onTabDelete,
  onTabCreate,
  onTabEdit,
  onTabMove,
  onTabCopyTo,
  onTabClone,
  onContentCompare
}: EditTabsProps) => {
  const { country } = useParams()
  const { getContent } = useEditorControls()
  const { showConfirm } = useDialog()
  const { tab: activeTab } = useContentStructure()

  const handleTabChange = (tab: ContentTabData) => {
    const markdown = getContent()
    const files = null
    const { equalMarkdown, equalFiles } = onContentCompare({ markdown, files })
    if (!equalMarkdown || !equalFiles) {
      showConfirm(
        'Changing tab',
        'Changing tabs will result in the loss of unsaved changes. Are you sure?'
      ).then(confirm => {
        if (confirm) {
          onTabChange(tab)
        }
      })
    } else {
      onTabChange(tab)
    }
  }

  const handlePublishTab = usePublishTab()

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      gap={1}
      py={2}
      wrap
      bg="#F1F3F5"
      w="100%"
      mb={2}>
      {tabs.map(tab => (
        <ContentTab
          key={tab.id}
          tab={tab}
          countryCode={country}
          isActive={tab.id === activeTab?.id}
          onClick={handleTabChange}
          onDelete={onTabDelete}
          onEdit={onTabEdit}
          onClone={onTabClone}
          onMove={onTabMove}
          onCopyTo={onTabCopyTo}
          onPublish={!tab.isPublished ? handlePublishTab : undefined}
        />
      ))}
      <TabCreate onCreate={onTabCreate} />
    </Box>
  )
}

export default EditTabs

