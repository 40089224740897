import { Dialog, useVuiContext } from '@veracity/vui'
import { ContentLocation } from '../../store/services/ContentService'
import CountryCopyPromptForm from './Form'

export const useCountryCopyPrompt = (countryCode: string) => {
  const { setPortalSlot } = useVuiContext()

  return (title: string): Promise<ContentLocation | false> =>
    new Promise(resolve => {
      let inputValue: ContentLocation | null = null
      setPortalSlot(
        <Dialog
          body={
            <CountryCopyPromptForm
              countryCode={countryCode}
              onChange={moveLocation => (inputValue = moveLocation)}
            />
          }
          cancelButton={{
            onClick: () => {
              resolve(false)
              setPortalSlot(null)
            },
            text: 'Cancel'
          }}
          closeButton={false}
          isOpen
          submitButton={{
            onClick: () => {
              if (!inputValue) return

              resolve(inputValue)
              setPortalSlot(null)
            },
            text: 'Submit'
          }}
          title={title}
        />
      )
    })
}
