import { useToast } from '@veracity/vui'
import {
  CustomerKey,
  useLazyGetCustomerKeySecretQuery
} from '../../../../store/services/ApiKeysService'

const useCustomerKeyCopySecret = () => {
  const [getKeySecret] = useLazyGetCustomerKeySecretQuery()
  const { showInfo } = useToast()

  return ({ id }: CustomerKey) => {
    getKeySecret(id)
      .unwrap()
      .then(value => {
        navigator.clipboard.writeText(value)
        showInfo('Key copied to clipboard')
      })
  }
}

export default useCustomerKeyCopySecret
