import {
  Box,
  Input,
  InputProps,
  SelectOptionData,
  SelectValue,
  Spinner,
  Tag
} from '@veracity/vui'
import { AutoComplete } from 'antd'
import { useMemo, useState } from 'react'
import { Control, useController } from 'react-hook-form'
import FormLabel from '../FormLabel'
import './style.css'

interface FormTagMultiselectProps extends InputProps {
  name: string
  label: string
  control: Control<any>
  options: SelectOptionData[]
  initOptions?: SelectOptionData[]
  loading?: boolean
  column?: boolean
  optionsName?: string
  onSearch?: (search: string) => void
}
const FormTagMultiselect = ({
  name,
  label,
  control,
  options,
  initOptions,
  loading,
  column = true,
  optionsName,
  onSearch,
  ...props
}: FormTagMultiselectProps) => {
  const {
    field: { value, onChange }
  } = useController({ name, control, defaultValue: [] })
  const {
    field: { onChange: onOptionsChange }
  } = useController({ name: optionsName ?? `${name}Options`, control })

  const [staticOptions, setStaticOptions] = useState(
    initOptions
      ? initOptions.map(o => ({ ...o, value: o.value.toString() }))
      : []
  )
  const [selectedValues, setSelectedValues] = useState<SelectValue[]>(
    value.map(String)
  )
  const [searchValue, setSearchValue] = useState('')

  const filteredOptions = useMemo(() => {
    if (onSearch)
      return options.map(({ text, value }) => ({
        label: text,
        value: value.toString()
      }))

    return options
      .filter(
        ({ text, value }) =>
          text.toLowerCase().includes(searchValue.toLowerCase()) &&
          !selectedValues.includes(value)
      )
      .map(({ text, value }) => ({ label: text, value: value.toString() }))
  }, [options, searchValue, selectedValues, onSearch])

  const handleSetSearch = (val: string) => {
    onSearch?.(val)
    setSearchValue(val)
  }

  const handleSearch = (val: string) => {
    handleSetSearch(val)
  }

  const handleSelect = (_: string, opt: { label: string; value: string }) => {
    handleSetSearch('')

    const option = { text: opt.label, value: opt.value }
    const newStaticOptions = [
      option,
      ...staticOptions.filter(o => o.value !== option.value)
    ]
    const newSelectedValues = [
      option.value,
      ...selectedValues.filter(v => v !== option.value)
    ]

    if (optionsName) {
      const newSelectedOptions = newSelectedValues
        .map(val => newStaticOptions.find(({ value }) => val === value))
        .filter(Boolean)
      onOptionsChange(newSelectedOptions)
    }

    setStaticOptions(newStaticOptions)
    setSelectedValues(newSelectedValues)
    onChange(newSelectedValues)
  }

  const handleDelete = (val: SelectValue) => {
    const newStaticOptions = staticOptions.filter(
      option => option.value !== val
    )
    const newSelectedValues = selectedValues.filter(
      selectedValue => selectedValue !== val
    )

    if (optionsName) {
      const newSelectedOptions = newSelectedValues
        .map(val => newStaticOptions.find(({ value }) => val === value))
        .filter(Boolean) as SelectOptionData[]
      onOptionsChange(newSelectedOptions)
    }

    setStaticOptions(newStaticOptions)
    setSelectedValues(newSelectedValues)
    onChange(newSelectedValues)
  }

  const selectedOptions = selectedValues
    .map(val => staticOptions.find(({ value }) => val === value))
    .filter(Boolean) as SelectOptionData[]

  return (
    <FormLabel text={label}>
      <Box className="form-tag-multiselect" gap={1} column={column}>
        <AutoComplete
          defaultActiveFirstOption
          value={searchValue}
          onSearch={handleSearch}
          onSelect={handleSelect}
          options={filteredOptions}>
          <Input
            itemRight={loading && <Spinner size="sm" mr={1} />}
            {...props}
          />
        </AutoComplete>
        <Box gap={1} wrap>
          {selectedOptions.map(option => (
            <Tag
              key={option.value}
              text={option.text}
              onDelete={() => handleDelete(option.value)}
            />
          ))}
        </Box>
      </Box>
    </FormLabel>
  )
}

export default FormTagMultiselect
