import { Box, Select } from '@veracity/vui'
import { ContentLocation } from '../../store/services/ContentService'
import * as R from 'ramda'
import { useGetCountriesListQuery } from '../../store/services/CountryService'

interface LocationOption extends ContentLocation {
  value: string
  text: string
}

interface CountryCopyPromptFormProps {
  countryCode: string
  onChange: (location: ContentLocation) => void
}
const CountryCopyPromptForm = ({
  onChange,
  countryCode
}: CountryCopyPromptFormProps) => {
  const { data } = useGetCountriesListQuery()

  const options =
    data
      ?.map(country => ({
        text: country.name,
        value: country.code,
        countryId: country.id
      }))
      .filter(({ value }) => value !== countryCode) ?? []

  const handleChange = (value: string) => {
    const location = options.find(opt => opt.value === value)

    if (location) {
      onChange(
        R.omit<LocationOption, keyof LocationOption>(
          ['text', 'value'],
          location
        )
      )
    }
  }

  return (
    <Box px={3} py={2}>
      <Select onChange={e => handleChange(e.target.value)} options={options} />
    </Box>
  )
}

export default CountryCopyPromptForm

