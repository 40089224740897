import { Box, Button, Card, Tooltip } from '@veracity/vui'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import config from '../../config'
import { RootState } from '../../store'
import { Country, CountryState } from '../../store/services/CountryService'
import CountryFlag from './CountryFlag'

const EMAIL = config.RequestAccessEmail

const getSubject = (customerEmail: string, country: string) =>
  encodeURI(`User (${customerEmail}) requesting access to ${country}`)
const getBody = (
  customerName: string,
  customerEmail: string,
  country: string
) =>
  encodeURI(
    `Dear DNV team,

We would like to have access to ${country}. Please let us know the information needed to complete process.

Best regards,

${customerName}
${customerEmail}`
  )

const getMailto = (
  customerName: string,
  customerEmail: string,
  country: string
) =>
  `mailto:${EMAIL}?subject=${getSubject(customerEmail, country)}&body=${getBody(
    customerName,
    customerEmail,
    country
  )}`

interface CountryCardProps {
  country: Country
}

const CountryCard = ({ country }: CountryCardProps) => {
  const navigate = useNavigate()
  const { name, code, state, isAccessible } = country
  const disabled = !isAccessible
  const onClick = () => {
    navigate(`/power-analytics/${code}`)
  }
  const customerEmail = useSelector<RootState, string>(
    state => state.user.userInfo?.email ?? 'EMAIL'
  )
  const customerName = useSelector<RootState, string>(
    state => state.user.userInfo?.displayName ?? 'NAME'
  )

  const handleRequestAccess = () => {
    window.location.href = getMailto(customerName, customerEmail, name)
  }

  return (
    <Card
      p={3}
      h={144}
      isInteractive={!disabled}
      justifyContent="space-between"
      onClick={disabled ? undefined : onClick}>
      <CountryFlag name={name} code={code} />
      <Box column justifyContent="space-between" alignItems="flex-end">
        <div />
        {!disabled && (
          <Button icon="falArrowRight" variant="tertiaryDark" isRound />
        )}
        {disabled && state === CountryState.RestrictedAccess && (
          <Tooltip text="Request access">
            <Button
              icon="falUnlockAlt"
              variant="tertiaryDark"
              isRound
              onClick={handleRequestAccess}
            />
          </Tooltip>
        )}
      </Box>
    </Card>
  )
}

export default CountryCard

