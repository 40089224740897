import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { invalidateContentAccessTags } from '../../../store/services/ContentAccessService'
import { useUpsertSubsectionMutation } from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useEditSubSection = (
  subSectionId?: number,
  sectionId?: number,
  sectionSlugId?: string,
  chapterSlugId?: string,
  countryCode?: string
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [upsertSubsection] = useUpsertSubsectionMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'edit-subsection-page',
      loadingMessage: 'Saving changes',
      errorMessage: 'Saving changes failed',
      successMessage: 'Changes saved successfully'
    })

  return {
    handler: (values: { title: string; groupsId: number[] }) => {
      if (
        isLoading ||
        !subSectionId ||
        !sectionId ||
        !sectionSlugId ||
        !chapterSlugId ||
        !countryCode
      )
        return
      setLoading(true)
      upsertSubsection({
        sectionId,
        subSectionId,
        ...values
      })
        .then(
          handlePromiseError(
            ({ data }) => {
              operationSuccess()
              dispatch(invalidateContentAccessTags([{ type: 'ContentTree' }]))
              navigate(
                `/power-analytics/edit/${countryCode}/${chapterSlugId}/${sectionSlugId}/${data.slugId}`
              )
            },
            () => operationFail()
          )
        )
        .finally(() => setLoading(false))
    },
    isLoading
  }
}

export default useEditSubSection

