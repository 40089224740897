import { useToast } from '@veracity/vui'
import { useEffect, useState } from 'react'

interface AsyncOperationToastOptions {
  id: string
  loadingMessage: string
  successMessage: string
  errorMessage: string
}
const useAsyncOperationToast = ({
  id,
  loadingMessage,
  successMessage,
  errorMessage
}: AsyncOperationToastOptions) => {
  const [isLoading, setLoading] = useState<boolean>()
  const { showToast, hideToast, showSuccess, showError } = useToast()

  useEffect(() => {
    if (isLoading === true) {
      showToast({
        id,
        status: 'loading',
        text: loadingMessage,
        duration: 'sticky'
      })
    }

    return () => {
      hideToast(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const operationSuccess = (message?: string) => {
    showSuccess(message ?? successMessage)
  }

  const operationFail = (message?: string) => {
    showError(message ?? errorMessage)
  }

  return {
    isLoading,
    setLoading,
    operationSuccess,
    operationFail
  }
}

export default useAsyncOperationToast
