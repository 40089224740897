import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import {
  ContentLocation,
  ContentTab,
  useCopyContentMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useTabCopy = () => {
  const [copyTab] = useCopyContentMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'tab-copy',
      loadingMessage: 'Copying tab',
      successMessage: 'Tab copied successfully',
      errorMessage: 'Copying tab failed'
    })

  return (tab: ContentTab, location: ContentLocation) => {
    if (isLoading) return

    setLoading(true)
    copyTab({ item: { tabId: tab.id }, newPlace: location })
      .then(
        handlePromiseError(
          () => operationSuccess(),
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default useTabCopy
