import { Button, Card, Heading } from '@veracity/vui'

interface ManagementCardProps {
  text: string
  onClick?: () => void
}
const ManagementCard = ({ text, onClick }: ManagementCardProps) => {
  return (
    <Card
      p={3}
      h={144}
      justifyContent="space-between"
      column
      isInteractive={Boolean(onClick)}
      onClick={onClick}>
      <Heading size="h4" text={text} />
      <Button
        alignSelf="flex-end"
        icon="falArrowRight"
        variant="tertiaryDark"
        isRound
      />
    </Card>
  )
}

export default ManagementCard
