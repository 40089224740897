import { Box, Button, Popover } from '@veracity/vui'
import iconPickerOptions from './options'

interface IconPickerProps {
  value: string
  onChange: (value: string) => void
  options?: string[]
}
const IconPicker = ({
  value,
  onChange,
  options = iconPickerOptions
}: IconPickerProps) => {
  return (
    <Popover>
      {({ close }) => (
        <>
          <Popover.Trigger
            as={Button}
            iconLeft={value}
            iconRight="falChevronDown"
            variant="tertiaryDark"
          />
          <Popover.Content>
            <Box wrap gap={1} maxW={208} p={1}>
              {options.map(iconName => (
                <Button
                  key={iconName}
                  icon={iconName}
                  variant="tertiaryDark"
                  borderRadius={3}
                  onClick={() => {
                    onChange(iconName)
                    close()
                  }}
                />
              ))}
            </Box>
          </Popover.Content>
        </>
      )}
    </Popover>
  )
}

export default IconPicker
