import { Box, useToast } from '@veracity/vui'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDebounce } from '../../../../hooks/useDebounce'
import { useGetCustomerKeyListQuery } from '../../../../store/services/ApiKeysService'
import useCustomerKeyEdit from './Edit/useCustomerKeyEdit'
import CustomerKeysFilters from './Filters'
import CustomerKeysHeader from './Header'
import CustomerKeysList from './List'
import useCustomerKeyCopySecret from './useCustomerKeyCopySecret'
import useCustomerKeyDelete from './useCustomerKeyDelete'

const CustomerKeysPage = () => {
  const form = useForm()
  const { showWarning } = useToast()
  const search = useDebounce(form.watch('search'), 300)
  const userGroups = form.watch('userGroups')
  const [pageNumber, setPageNumber] = useState(1)
  const pageSize = 20
  const { data, isFetching } = useGetCustomerKeyListQuery({
    search,
    pageSize,
    pageNumber,
    userGroups
  })
  const customerKeys = data?.items ?? []
  const total = data?.total ?? 0

  const handleEdit = useCustomerKeyEdit()
  const handleCreate = () => handleEdit()
  const handleDelete = useCustomerKeyDelete()

  const handleCopy = useCustomerKeyCopySecret()

  const handleShare = () => {
    showWarning('Not implemented yet')
  }

  return (
    <Box column w="100%">
      <FormProvider {...form}>
        <CustomerKeysHeader onCreate={handleCreate} />
        <CustomerKeysFilters />
      </FormProvider>
      <CustomerKeysList
        customerKeys={customerKeys}
        isLoading={isFetching}
        page={pageNumber}
        pageSize={pageSize}
        total={total}
        onPageChange={setPageNumber}
        onCopy={handleCopy}
        onEdit={handleEdit}
        onShare={handleShare}
        onDelete={handleDelete}
      />
    </Box>
  )
}

export default CustomerKeysPage
