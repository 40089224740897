import { useDialog } from '@veracity/vui'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useDeleteChapterMutation } from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useDeleteChapter = (chapterId?: number) => {
  const [deleteChapter] = useDeleteChapterMutation()
  const { showConfirm } = useDialog()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'chapter-delete',
      loadingMessage: 'Deleting chapter',
      successMessage: 'Chapter deleted successfully',
      errorMessage: 'Deleting chapter failed'
    })

  return () => {
    if (!chapterId || isLoading) return

    showConfirm(
      'Delete chapter',
      'Are you sure you want to delete this chapter?'
    ).then(agree => {
      if (agree) {
        setLoading(true)
        deleteChapter(chapterId)
          .then(
            handlePromiseError(
              () => operationSuccess(),
              () => operationFail()
            )
          )
          .finally(() => setLoading(false))
      }
    })
  }
}

export default useDeleteChapter
