import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Card, SelectOptionData, T } from '@veracity/vui'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import FormInput from '../../../components/form/FormInput'
import FormSelect from '../../../components/form/FormSelect'
import FormTagMultiselect from '../../../components/form/FormTagMultiselect'
import useGroupOptions from '../../../hooks/useGroupOptions'
import { TabsStrategy } from '../../../store/services/ContentService'

interface FormInputs {
  title: string
  tabsStrategy?: TabsStrategy
  groupsId: number[]
}

const extendedSchema = yup
  .object({
    title: yup.string().min(3).required(),
    tabsStrategy: yup.number().required(),
    groupsId: yup.array(yup.number())
  })
  .required()

const schema = yup
  .object({
    title: yup.string().min(3).required(),
    groupsId: yup.array(yup.number())
  })
  .required()

interface SubsectionFormProps {
  initialValues?: FormInputs
  initOptions?: SelectOptionData[]
  isLoading?: boolean
  parentWithTabs?: boolean
  onSubmit: (values: FormInputs) => void
}
const SubsectionForm = ({
  onSubmit,
  initialValues,
  initOptions = [],
  isLoading,
  parentWithTabs
}: SubsectionFormProps) => {
  const form = useForm<FormInputs>({
    defaultValues: initialValues ?? {
      title: ''
    },
    resolver: yupResolver(parentWithTabs ? extendedSchema : schema),
    mode: 'onChange'
  })

  const handleSubmit = () => {
    form.handleSubmit(values => {
      onSubmit(values)
    })()
  }

  const { options: groupOptions, onSearch, loading } = useGroupOptions()

  useEffect(() => {
    form.reset(initialValues)
  }, [form, initialValues])

  return (
    <Card column maxW={528} flex={1} p={4}>
      <Box column gap={3}>
        <FormInput
          size="md"
          name="title"
          label="Sub section title"
          control={form.control}
        />
        {parentWithTabs && (
          <Box column>
            <T size="sm" text="This section have tabs with content." />
            <FormSelect
              label=""
              name="tabsStrategy"
              options={[
                { value: TabsStrategy.Move, text: 'Move tabs' },
                { value: TabsStrategy.Remove, text: 'Remove tabs' }
              ]}
              control={form.control}
            />
          </Box>
        )}
        <FormTagMultiselect
          name="groupsId"
          label="Group"
          control={form.control}
          options={groupOptions}
          initOptions={initOptions}
          size="md"
          iconLeft="falSearch"
          onSearch={onSearch}
          loading={loading}
        />
        <Button
          variant="secondaryDark"
          borderRadius={6}
          disabled={!form.formState.isValid || isLoading}
          onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Card>
  )
}

export default SubsectionForm

