import { Menu } from '@veracity/vui'
import { stopPropagation } from '../../../utils/event'
import useNavMode, { Mode } from './useNavMode'

interface CountryControlProps {
  onAddChapter: () => void
}
const CountryControl = ({ onAddChapter }: CountryControlProps) => {
  const mode = useNavMode()

  if (mode === Mode.Read) return null

  const disabled = mode === Mode.New

  return (
    <Menu>
      <Menu.Button icon="falEllipsisV" isRound disabled={disabled} />
      <Menu.List w={240}>
        <Menu.Item iconLeft="falPlus" onClick={stopPropagation(onAddChapter)}>
          Add chapter
        </Menu.Item>
      </Menu.List>
    </Menu>
  )
}

export default CountryControl

