import { Box, Spinner, useLoadLinks } from '@veracity/vui'
import { useSelector } from 'react-redux'
import AppRouter from './AppRouter'
import useUserSession from './hooks/useUserSession'
import { RootState } from './store'

const App = () => {
  const env = 'Prod'
  const isLoggedIn = useSelector<RootState, boolean>(
    state => state.user.isLoggedIn
  )
  const isLoading = useSelector<RootState, boolean>(
    state => state.user.isLoading
  )

  useUserSession()
  useLoadLinks(env, isLoggedIn)

  if (isLoading)
    return (
      <Box mt={4} w="100%" center>
        <Spinner text="Loading app..." />
      </Box>
    )

  return <AppRouter />
}

export default App

