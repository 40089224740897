import { Button } from '@veracity/vui'

interface TabCreateProps {
  onCreate: () => void
}
const TabCreate = ({ onCreate }: TabCreateProps) => (
  <Button
    size="sm"
    icon="falPlus"
    variant="tertiaryDark"
    isRound
    onClick={() => onCreate()}
  />
)

export default TabCreate

