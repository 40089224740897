import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Heading } from '@veracity/vui'
import { FormProvider, useForm } from 'react-hook-form'
import AccessControl from './AccessControl'
import AccessTree from './AccessTree'
import CustomerList from './CustomerList'
import * as yup from 'yup'
import { useGetCustomersListQuery } from '../../../store/services/ContentAccessService'
import { useMemo } from 'react'
import { UserData } from '../../../store/services/UsersService'
import { mapToUserAccess } from './utils'
import useUpsertAccess from './useUpsertAccess'

const schema = yup
  .object({
    users: yup.array(yup.number()).min(1).required(),
    access: yup.array(yup.string()).required(),
    endAccessDate: yup.date().min(new Date()).required(),
    search: yup.string().optional()
  })
  .required()

interface FormInputs {
  users: number[]
  access: string[]
  endAccessDate: string
  search: string
}

const ContentAccessManagementPage = () => {
  const form = useForm<FormInputs>({
    defaultValues: {
      users: [],
      access: [],
      endAccessDate: undefined,
      search: ''
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const { data: customers, isFetching } = useGetCustomersListQuery()

  const search = form.watch('search')
  const filteredCustomers = useMemo(
    () =>
      customers?.filter((user: UserData) => {
        const {
          userInfo: { firstName, lastName, email },
          role: { displayName },
          groups
        } = user
        return [
          `${firstName} ${lastName}`,
          email,
          displayName,
          ...groups.map(group => group.name)
        ]
          .filter(Boolean)
          .some(value => value.toLowerCase().includes(search.toLowerCase()))
      }) ?? [],
    [search, customers]
  )

  const handleUpsertAccess = useUpsertAccess()

  const handleSubmit = () => {
    form.handleSubmit(({ users, access, endAccessDate }) => {
      const userIds = users
      const items = mapToUserAccess(access, endAccessDate)

      handleUpsertAccess(userIds, items)
    })()
  }

  return (
    <Box column w="100%">
      <Box
        px={8}
        minH={52}
        alignItems="center"
        bg={'#F1F3F5'}
        position="sticky"
        top={0}
        zIndex={100}>
        <Heading text="Content Access Management" weight="600" size="h3" />
      </Box>
      <FormProvider {...form}>
        <Box column px={8}>
          <CustomerList customers={filteredCustomers} isLoading={isFetching} />
          <AccessTree />
          <AccessControl onSubmit={handleSubmit} />
        </Box>
      </FormProvider>
    </Box>
  )
}

export default ContentAccessManagementPage

