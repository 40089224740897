import { Box } from '@veracity/vui'
import { useEffect, useState } from 'react'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import {
  ContentSection,
  useUpsertSectionMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'
import { reorderList } from '../../../utils/list'
import NavSection from './NavSection'
import useNavMode from './useNavMode'

interface NavSectionsProps {
  sections: ContentSection[]
  country: string
  chapter: string
  chapterId: number
  onNavigate: (path: string[]) => void
}

const NavSections = ({
  sections,
  country,
  chapter,
  chapterId,
  onNavigate
}: NavSectionsProps) => {
  const mode = useNavMode()

  const [navigationItems, setNavigationItems] = useState(sections)
  const [moveSection] = useUpsertSectionMutation()

  useEffect(() => {
    setNavigationItems(sections)
  }, [sections])

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'move-section',
      loadingMessage: 'Saving changes',
      successMessage: 'Changes saved successfully',
      errorMessage: 'Saving changes failed'
    })

  const handleMoveSection = (sourceIndex: number, destinationIndex: number) => {
    if (isLoading) return

    const movedSection = navigationItems[sourceIndex]
    const items = reorderList(navigationItems, sourceIndex, destinationIndex)

    setNavigationItems(items)

    setLoading(true)
    moveSection({
      sectionId: movedSection.id,
      order: destinationIndex,
      chapterId
    })
      .then(
        handlePromiseError(
          () => operationSuccess(),
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }

  return (
    <Box column mt={sections.length ? 1 : 0}>
      {navigationItems.map((section, index) => (
        <NavSection
          key={section.id}
          section={section}
          mode={mode}
          country={country}
          chapter={chapter}
          chapterId={chapterId}
          onNavigate={onNavigate}
          onMoveUp={
            index > 0 ? () => handleMoveSection(index, index - 1) : undefined
          }
          onMoveDown={
            index < navigationItems.length - 1
              ? () => handleMoveSection(index, index + 1)
              : undefined
          }
        />
      ))}
    </Box>
  )
}

export default NavSections

