import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { userActions } from '../../store/slices/user'

const UnauthenticatedPage = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userActions.setUser(null))
  }, [dispatch])

  return <Navigate to="/" />
}

export default UnauthenticatedPage
