import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@veracity/vui'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FormTagMultiselect from '../../../../../components/form/FormTagMultiselect'
import useDataGroupOptions from '../../../../../hooks/useDataGroupOptions'
import * as yup from 'yup'
import FormInput from '../../../../../components/form/FormInput'
import { IntegrationKey } from '../../../../../store/services/ApiKeysService'

const schema = yup
  .object({
    name: yup.string().min(1).required(),
    dataGroupIds: yup.array(yup.number()).required(),
    validTill: yup.date().min(new Date()).required()
  })
  .required()

interface Props {
  initValues?: IntegrationKey
  onChange: (values: any) => void
  onValid: (value: boolean) => void
}
const IntegrationKeyEditForm = ({ initValues, onChange, onValid }: Props) => {
  const form = useForm({
    defaultValues: {
      name: initValues?.name,
      dataGroupIds: initValues?.dataGroups.map(({ id }) => id),
      validTill: initValues?.validTill
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const dataGroupInitOptions = initValues?.dataGroups.map(group => ({
    text: group.name,
    value: group.id
  }))

  const values = form.watch()

  useEffect(() => {
    onValid(form.formState.isValid)
  }, [onValid, form.formState.isValid])

  useEffect(() => {
    onChange(values)
  }, [onChange, values])

  const {
    onSearch: onDataGroupSearch,
    options: dataGroupOptions,
    loading: dataGroupOptionsLoading
  } = useDataGroupOptions()

  return (
    <Box px={3} column gap={1}>
      <FormInput
        name="name"
        label="Key name"
        control={form.control}
        size="md"
      />
      <FormTagMultiselect
        name="dataGroupIds"
        label="Data group"
        control={form.control}
        options={dataGroupOptions}
        onSearch={onDataGroupSearch}
        loading={dataGroupOptionsLoading}
        size="md"
        iconLeft="falSearch"
        initOptions={dataGroupInitOptions}
      />
      <FormInput
        name="validTill"
        label="Valid till"
        control={form.control}
        type="datetime-local"
        size="md"
      />
    </Box>
  )
}

export default IntegrationKeyEditForm
