import { Control, useController } from 'react-hook-form'
import type { Key } from 'antd/es/table/interface'
import type { TreeProps } from 'antd'
import Tree from '../Tree'
import { useMemo } from 'react'
import { TreeDataHelper } from './formStrictTreeUtils'

interface FormStrictTreeProps extends TreeProps {
  name: string
  control: Control
}
const FormStrictTree = ({
  name,
  control,
  treeData = [],
  ...props
}: FormStrictTreeProps) => {
  const {
    field: { value, onChange }
  } = useController({
    name,
    control
  })

  const treeDataHelper = useMemo(() => new TreeDataHelper(treeData), [treeData])

  const handleCheck = (
    result: { checked: Key[]; halfChecked: Key[] } | Key[]
  ) => {
    const { checked } = result as {
      checked: string[]
    }

    const currentChecked = treeDataHelper.getChecked()
    const lowerHalfChecked = treeDataHelper.getLowerHalfChecked()

    const toUncheck = [
      ...lowerHalfChecked.filter(key => checked.includes(key)),
      ...currentChecked.filter(key => !checked.includes(key))
    ]

    const toCheck = checked.filter(
      key => !toUncheck.includes(key) && !currentChecked.includes(key)
    )

    toUncheck.forEach(key => treeDataHelper.uncheck(key))
    toCheck.forEach(key => treeDataHelper.check(key))

    onChange(treeDataHelper.getChecked())
  }

  const checkedKeys = useMemo(() => {
    treeDataHelper.clear()
    value.forEach((key: string) => treeDataHelper.check(key))

    return {
      checked: treeDataHelper.getChecked(),
      halfChecked: treeDataHelper.getHalfChecked()
    }
  }, [value, treeDataHelper])

  return (
    <Tree
      {...props}
      checkable
      checkStrictly
      checkedKeys={checkedKeys}
      selectable={false}
      treeData={treeData}
      onCheck={handleCheck}
    />
  )
}

export default FormStrictTree
