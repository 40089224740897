import { Box, Button, Divider, Heading, Icon } from '@veracity/vui'
import FormInput from '../../../components/form/FormInput'
import { useFormContext } from 'react-hook-form'
import { Link } from 'react-router-dom'

const DataGroupsManagementHeader = () => {
  const form = useFormContext()

  return (
    <Box
      py={1}
      px={8}
      bg={'#F1F3F5'}
      position="sticky"
      top={0}
      zIndex={100}
      justifyContent="space-between"
      alignItems="center">
      <Heading text="Data groups" weight="600" size="h3" />
      <Box alignItems="center" gap={2}>
        <FormInput
          size="md"
          name="search"
          control={form.control}
          label=""
          iconLeft={<Icon name="falSearch" color="#698091" h={16} ml={0.5} />}
          placeholder="Search"
          borderRadius={6}
        />
        <Divider isVertical width={2} h={24} />
        <Button
          text="Create group"
          as={Link}
          to={'/management/data-groups/create'}
        />
      </Box>
    </Box>
  )
}

export default DataGroupsManagementHeader

