import { Box, Menu } from '@veracity/vui'
import { stopPropagation } from '../../../utils/event'
import useNavMode, { Mode } from './useNavMode'

interface SubsectionControlProps {
  onEditSubsection: () => void
  onDeleteSubsection: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
  onPublish?: () => void
}
const SubsectionControl = ({
  onEditSubsection,
  onDeleteSubsection,
  onMoveUp,
  onMoveDown,
  onPublish
}: SubsectionControlProps) => {
  const mode = useNavMode()

  if (mode !== Mode.Edit) return null

  return (
    <Box>
      <Menu>
        <Menu.Button icon="falEllipsisV" isRound />
        <Menu.List w={240}>
          {onPublish && (
            <Menu.Item
              iconLeft="culGlobePlus"
              onClick={stopPropagation(onPublish)}>
              Publish sub section
            </Menu.Item>
          )}
          <Menu.Item
            iconLeft="falPencil"
            onClick={stopPropagation(onEditSubsection)}>
            Edit sub section
          </Menu.Item>
          <Menu.Item
            iconLeft="falTrashAlt"
            onClick={stopPropagation(onDeleteSubsection)}>
            Delete sub section
          </Menu.Item>
          {onMoveUp && (
            <Menu.Item
              iconLeft="falArrowAltUp"
              onClick={stopPropagation(onMoveUp)}>
              Move up
            </Menu.Item>
          )}
          {onMoveDown && (
            <Menu.Item
              iconLeft="falArrowAltDown"
              onClick={stopPropagation(onMoveDown)}>
              Move down
            </Menu.Item>
          )}
        </Menu.List>
      </Menu>
    </Box>
  )
}

export default SubsectionControl

