import ErrorCard from '../../components/ErrorCard'

const NotFoundPage = () => (
  <ErrorCard
    title="Sorry, we couldn't find that page"
    description="The page you're searching for has either been moved or the link is
  broken."
  />
)

export default NotFoundPage
