import { useDialog } from '@veracity/vui'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useDeleteGroupMutation } from '../../../store/services/GroupsService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useHandleDelete = () => {
  const { showConfirm } = useDialog()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'delete-group',
      loadingMessage: 'Deleting group',
      successMessage: 'Group deleted successfully',
      errorMessage: 'Deleting group failed'
    })

  const [deleteGroup] = useDeleteGroupMutation()

  return async (id: number) => {
    const response = await showConfirm(
      'Delete Group',
      'Are you sure you want to delete this group?'
    )
    if (!response || !id || isLoading) return

    setLoading(true)

    try {
      handlePromiseError(
        () => operationSuccess(),
        () => operationFail()
      )(await deleteGroup(id))
    } catch {
      operationFail()
    } finally {
      setLoading(false)
    }
  }
}

export default useHandleDelete
