import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { invalidateContentAccessTags } from '../../../store/services/ContentAccessService'
import { useUpsertSectionMutation } from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useEditSection = (
  sectionId?: number,
  chapterId?: number,
  chapterSlugId?: string,
  countryCode?: string
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [upsertSection] = useUpsertSectionMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'edit-section-page',
      loadingMessage: 'Saving changes',
      errorMessage: 'Saving changes failed',
      successMessage: 'Changes saved successfully'
    })

  return {
    handler: (values: { title: string; groupsId: number[] }) => {
      if (
        isLoading ||
        !chapterId ||
        !sectionId ||
        !chapterSlugId ||
        !countryCode
      )
        return
      setLoading(true)
      upsertSection({
        chapterId,
        sectionId,
        ...values
      })
        .then(
          handlePromiseError(
            ({ data }) => {
              operationSuccess()
              dispatch(invalidateContentAccessTags([{ type: 'ContentTree' }]))
              navigate(
                `/power-analytics/edit/${countryCode}/${chapterSlugId}/${data.slugId}`
              )
            },
            () => operationFail()
          )
        )
        .finally(() => setLoading(false))
    },
    isLoading
  }
}

export default useEditSection

