import { Box } from '@veracity/vui'
import CSVDataSource from '../CSVDataSource'
import DBDataSource from '../DBDataSource'
import NewDataSourceHeader from './Header'
import ManualDataSource from '../ManualDataSource'
import DataSourceTypeSwitcher from '../TypeSwitcher'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useEffect } from 'react'
import { DataSourceType } from '../../../../store/services/DataSourceService'

const schema = yup
  .object({
    name: yup.string().min(1).required(),
    type: yup
      .number()
      .oneOf([DataSourceType.CSV, DataSourceType.Manual, DataSourceType.DB])
      .required(),
    data: yup.array(yup.array(yup.string())),
    preview: yup.array(yup.array(yup.string())),
    parameters: yup.object()
  })
  .required()

const NewDataSourcePage = () => {
  const form = useForm({
    defaultValues: {
      name: '',
      type: DataSourceType.Manual,
      data: [['']],
      preview: [],
      file: null,
      parameters: {}
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const type = form.watch('type')

  useEffect(() => {
    form.setValue('preview', [])
  }, [type, form])

  return (
    <Box column w="100%">
      <FormProvider {...form}>
        <NewDataSourceHeader />
        <DataSourceTypeSwitcher />
        {type === DataSourceType.CSV && <CSVDataSource />}
        {type === DataSourceType.Manual && <ManualDataSource />}
        {type === DataSourceType.DB && <DBDataSource />}
      </FormProvider>
    </Box>
  )
}

export default NewDataSourcePage
