import { Box } from '@veracity/vui'
import { useParams } from 'react-router-dom'
import { useGetUserDetailsQuery } from '../../../../../store/services/UsersService'
import EditUserForm from './Form'
import EditUserHeader from './Header'
import useEditUser from './useEditUser'

const EditUserPage = () => {
  const { id } = useParams()
  const { data: user, isFetching } = useGetUserDetailsQuery(Number(id), {
    skip: isNaN(Number(id))
  })

  const handleSave = useEditUser(isNaN(Number(id)) ? undefined : Number(id))

  const handleSubmit = (values: { role: number; groupsId: number[] }) => {
    handleSave({ roleId: values.role, groupsId: values.groupsId })
  }

  return (
    <Box column w="100%">
      <EditUserHeader />
      <Box px={8} py={4}>
        <EditUserForm
          key={user ? 'loaded-user' : 'empty-user'}
          onSubmit={handleSubmit}
          isLoading={isFetching}
          initValues={
            user
              ? {
                  name: user.userInfo.lastName,
                  email: user.userInfo.email,
                  role: user.role.roleId,
                  groups: user.groups,
                  groupsId: user.groups.map(group => group.id)
                }
              : {
                  name: '',
                  email: '',
                  role: 0,
                  groups: [],
                  groupsId: []
                }
          }
        />
      </Box>
    </Box>
  )
}

export default EditUserPage

