import { Box, Button, Card, Spinner } from '@veracity/vui'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import FormInput from '../../../../../components/form/FormInput'
import FormSelect from '../../../../../components/form/FormSelect'
import FormTagMultiselect from '../../../../../components/form/FormTagMultiselect'
import useGroupOptions from '../../../../../hooks/useGroupOptions'
import usePermissions from '../../../../../hooks/usePermissions'
import { Group } from '../../../../../store/services/GroupsService'
import { UserRole } from '../../../../../store/services/UsersService'

interface FormValues {
  name: string
  email: string
  groupsId: number[]
  groups: Group[]
  role: UserRole
}

interface EditUserFormProps {
  isLoading: boolean
  initValues: FormValues
  onSubmit: (values: FormValues) => void
}
const EditUserForm = ({
  onSubmit,
  isLoading,
  initValues
}: EditUserFormProps) => {
  const form = useForm({
    defaultValues: initValues
  })
  const { isKeymaster } = usePermissions()
  const navigate = useNavigate()
  const { options: groupOptions, onSearch, loading } = useGroupOptions()

  const handleSubmit = () => {
    form.handleSubmit(values => onSubmit(values))()
  }

  return (
    <Card column p={2} w={528} gap={2}>
      <FormInput
        size="md"
        name="name"
        label="Name"
        control={form.control}
        disabled
      />
      <FormInput
        size="md"
        name="email"
        label="Email"
        control={form.control}
        disabled
      />
      <FormSelect
        name="role"
        label="Role"
        control={form.control}
        options={[
          { text: 'Customer', value: UserRole.Customer },
          { text: 'Content Manager', value: UserRole.ContentManager },
          { text: 'Keymaster', value: UserRole.Keymaster }
        ]}
        disabled={!isKeymaster}
      />
      <FormTagMultiselect
        size="md"
        name="groupsId"
        label="Group"
        control={form.control}
        options={groupOptions}
        initOptions={initValues.groups.map(group => ({
          value: group.id,
          text: group.name
        }))}
        onSearch={onSearch}
        loading={loading}
        iconLeft="falSearch"
      />
      <Box gap={1} justifyContent="flex-end" mt={4}>
        {isLoading && <Spinner size="sm" />}
        <Button
          text="Cancel"
          variant="secondaryDark"
          borderRadius={6}
          onClick={() => navigate(-1)}
        />
        <Button
          text="Save"
          variant="primaryDark"
          borderRadius={6}
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </Box>
    </Card>
  )
}

export default EditUserForm

