import { useDialog } from '@veracity/vui'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import {
  DataGroupListItem,
  useDeleteDataGroupMutation
} from '../../../store/services/DataGroupsService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useDeleteDataGroup = () => {
  const [deleteDataGroup] = useDeleteDataGroupMutation()
  const { isLoading, setLoading, operationFail, operationSuccess } =
    useAsyncOperationToast({
      id: 'delete-data-group',
      loadingMessage: 'Deleting data group',
      successMessage: 'Data group deleted successfully',
      errorMessage: 'Deleting data group failed'
    })
  const { showConfirm } = useDialog()

  return (data: DataGroupListItem) => {
    showConfirm(
      'Delete data group',
      'Are you sure you want to delete this data group?'
    ).then(confirm => {
      if (confirm) {
        if (isLoading) return

        setLoading(true)
        deleteDataGroup(data.id)
          .then(
            handlePromiseError(
              () => operationSuccess(),
              () => operationFail()
            )
          )
          .finally(() => setLoading(false))
      }
    })
  }
}

export default useDeleteDataGroup
