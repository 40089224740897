import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { invalidateContentAccessTags } from '../../../store/services/ContentAccessService'
import { useUpsertChapterMutation } from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useCreateChapter = (countryId?: number, countryCode?: string) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [createChapter] = useUpsertChapterMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'add-chapter-page',
      loadingMessage: 'Creating new chapter',
      errorMessage: 'Creating new chapter failed',
      successMessage: 'Chapter created successfully'
    })

  return {
    handler: (values: { title: string; icon: string; groupsId: number[] }) => {
      if (isLoading || !countryId || !countryCode) return

      setLoading(true)
      createChapter({ countryId, ...values })
        .then(
          handlePromiseError(
            ({ data }) => {
              operationSuccess()
              dispatch(invalidateContentAccessTags([{ type: 'ContentTree' }]))
              navigate(`/power-analytics/edit/${countryCode}/${data.slugId}`)
            },
            () => operationFail
          )
        )
        .finally(() => setLoading(false))
    },
    isLoading
  }
}

export default useCreateChapter

