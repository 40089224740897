import { Button } from '@veracity/vui'
import styled from 'styled-components'

const BackButton = styled(Button)`
  border-radius: 6px;

  .vui-icon {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`

export default BackButton
