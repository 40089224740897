import { Box } from '@veracity/vui'
import { useEffect, useState } from 'react'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import {
  ContentSubSection,
  useUpsertSubsectionMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'
import { reorderList } from '../../../utils/list'
import NavSubsection from './NavSubsection'
import useNavMode from './useNavMode'

interface NavSubsectionsProps {
  subSections: ContentSubSection[]
  country: string
  chapter: string
  section: string
  sectionId: number
  onNavigate: (path: string[]) => void
}

const NavSubsections = ({
  subSections,
  country,
  chapter,
  section,
  sectionId,
  onNavigate
}: NavSubsectionsProps) => {
  const mode = useNavMode()

  const [navigationItems, setNavigationItems] = useState(subSections)
  const [moveSubSection] = useUpsertSubsectionMutation()

  useEffect(() => {
    setNavigationItems(subSections)
  }, [subSections])

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'move-subsection',
      loadingMessage: 'Saving changes',
      successMessage: 'Changes saved successfully',
      errorMessage: 'Saving changes failed'
    })

  const handleMoveSubSection = (
    sourceIndex: number,
    destinationIndex: number
  ) => {
    if (isLoading) return

    const movedSubSection = navigationItems[sourceIndex]
    const items = reorderList(navigationItems, sourceIndex, destinationIndex)

    setNavigationItems(items)

    setLoading(true)
    moveSubSection({
      subSectionId: movedSubSection.id,
      order: destinationIndex,
      sectionId
    })
      .then(
        handlePromiseError(
          () => operationSuccess(),
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }

  return (
    <Box column>
      {navigationItems.map((subSection, index) => (
        <NavSubsection
          key={subSection.id}
          subSection={subSection}
          mode={mode}
          country={country}
          chapter={chapter}
          section={section}
          sectionId={sectionId}
          onNavigate={onNavigate}
          onMoveUp={
            index > 0 ? () => handleMoveSubSection(index, index - 1) : undefined
          }
          onMoveDown={
            index < navigationItems.length - 1
              ? () => handleMoveSubSection(index, index + 1)
              : undefined
          }
        />
      ))}
    </Box>
  )
}

export default NavSubsections

