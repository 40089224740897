import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { parseQuerystring } from '../../utils/url'
import { DataSource } from './DataSourceService'
import { axiosBaseQuery } from './utils/axiosBaseQuery'

export interface DataGroup {
  id: number
  name: string
  dataSources: DataSource[]
}

export interface DataGroupListItem {
  id: number
  name: string
  dataSourceIds: number[]
}

interface GetDataGroupsRequest {
  search?: string
  pageSize?: number
  pageNumber?: number
}

interface GetDataGroupsResponse {
  items: DataGroupListItem[]
  total: number
  pageSize: number
  pageNumber: number
}

interface UpsertDataGroupRequest {
  id?: number
  name?: string
  dataSourceIds?: number[]
}

const dataGroupsApi = createApi({
  reducerPath: 'dataGroupsApi',
  tagTypes: ['DataGroups', 'DataGroup'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/DataGroups/'
  }),
  endpoints: builder => ({
    getDataGroups: builder.query<GetDataGroupsResponse, GetDataGroupsRequest>({
      query: filters => ({ url: `GetAll?${parseQuerystring(filters)}` }),
      providesTags: (_, __, filters) => [
        { type: 'DataGroups', id: parseQuerystring(filters) }
      ]
    }),
    getDataGroup: builder.query<DataGroup, number>({
      query: id => ({ url: `${id}` }),
      providesTags: response =>
        response ? [{ type: 'DataGroup', id: response.id }] : ['DataGroup']
    }),
    upsertDataGroup: builder.mutation<DataGroup, UpsertDataGroupRequest>({
      query: data => ({ url: 'Upsert', method: 'POST', data }),
      invalidatesTags: response =>
        response
          ? ['DataGroups', { type: 'DataGroup', id: response.id }]
          : ['DataGroups', 'DataGroup']
    }),
    deleteDataGroup: builder.mutation<void, number>({
      query: id => ({ url: `${id}`, method: 'DELETE' }),
      invalidatesTags: (_, __, id) => ['DataGroups', { type: 'DataGroup', id }]
    })
  })
})

export const {
  reducerPath: dataGroupsApiReducerPath,
  reducer: dataGroupsApiReducer,
  middleware: dataGroupsApiMiddleware,
  useGetDataGroupQuery,
  useGetDataGroupsQuery,
  useUpsertDataGroupMutation,
  useDeleteDataGroupMutation
} = dataGroupsApi
