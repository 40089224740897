import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useFormModal } from '../../../hooks/useFormModal'
import { useUpsertGroupMutation } from '../../../store/services/GroupsService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'
import GroupForm from './GroupForm'

const useEditGroup = () => {
  const openModal = useFormModal<{ id?: number; name: string }>(
    (initValues, onChange) => (
      <GroupForm initValues={initValues} onChange={onChange} />
    )
  )
  const [editGroup] = useUpsertGroupMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'edit-group',
      loadingMessage: 'Editing group',
      successMessage: 'Group edited successfully',
      errorMessage: 'Editing group failed'
    })

  return async (initValues: { id: number; name: string }) => {
    const response = await openModal(initValues, 'Edit Group')
    if (!response || isLoading || !response?.id) return

    setLoading(true)
    // Weird async/then mix due to typing issue
    // createGroup({ groupName: response.name })
    //   .then(handlePromiseError(() => operationSuccess(), () => operationFail()))
    //   .finally(() => setLoading(false))
    try {
      handlePromiseError(
        () => operationSuccess(),
        () => operationFail()
      )(await editGroup({ groupName: response.name, groupId: +response.id }))
    } catch {
      operationFail()
    } finally {
      setLoading(false)
    }
  }
}

export default useEditGroup
