import { Box } from '@veracity/vui'
import { useNavigate, useParams } from 'react-router-dom'
import NavChapters from './NavChapters'
import NavCountry from './NavCountry'
import NavHeader from './NavHeader'
import { Mode, NavModeProvider } from './useNavMode'

interface SideNavProps {
  mode: Mode
}

const SideNav = ({ mode }: SideNavProps) => {
  const { country } = useParams()
  const navigate = useNavigate()
  const handleNavigate = (path: string[]) => {
    const url = [country, ...path].join('/')
    if (mode === Mode.Edit) {
      navigate('/power-analytics/edit/' + url)
    } else {
      navigate('/power-analytics/' + url)
    }
  }

  return (
    <NavModeProvider value={mode}>
      <Box
        w={296}
        column
        boxShadow="0px 1px 5px rgba(105, 128, 145, 0.4)"
        zIndex={101}>
        <NavHeader />
        <NavCountry />
        <NavChapters onNavigate={handleNavigate} />
      </Box>
    </NavModeProvider>
  )
}

export default SideNav
