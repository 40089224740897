import {
  ContentTreeChapter,
  ContentTreeCountry,
  ContentTreeSection,
  ContentTreeSubSection,
  UserAccess
} from '../../../store/services/ContentAccessService'
import * as R from 'ramda'

const getSubSectionChildren = (
  subSectionKey: string,
  subSection: ContentTreeSubSection
) => {
  if (!R.isEmpty(subSection.tabs) && !R.isNil(subSection.tabs)) {
    return subSection.tabs.map(tab => ({
      key: `${subSectionKey}-[tabId]${tab.tabId}`,
      title: tab.title
    }))
  }

  return []
}

const getSectionChildren = (
  sectionKey: string,
  section: ContentTreeSection
) => {
  if (!R.isEmpty(section.subSections) && !R.isNil(section.subSections)) {
    return section.subSections.map(subSection => {
      const key = `${sectionKey}-[subSectionId]${subSection.subSectionId}`
      const children = getSubSectionChildren(key, subSection)

      return {
        key,
        title: subSection.title,
        children
      }
    })
  }
  if (!R.isEmpty(section.tabs) && !R.isNil(section.tabs)) {
    return section.tabs.map(tab => ({
      key: `${sectionKey}-[tabId]${tab.tabId}`,
      title: tab.title
    }))
  }

  return []
}

const getChapterChildren = (
  chapterKey: string,
  chapter: ContentTreeChapter
) => {
  if (!R.isEmpty(chapter.sections) && !R.isNil(chapter.sections)) {
    return chapter.sections.map(section => {
      const key = `${chapterKey}-[sectionId]${section.sectionId}`
      const children = getSectionChildren(key, section)

      return {
        key,
        title: section.title,
        children
      }
    })
  }
  if (!R.isEmpty(chapter.tabs) && !R.isNil(chapter.tabs)) {
    return chapter.tabs.map(tab => ({
      key: `${chapterKey}-[tabId]${tab.tabId}`,
      title: tab.title
    }))
  }

  return []
}

const getCountryChildren = (countryKey: string, country: ContentTreeCountry) =>
  country.chapters.map(chapter => {
    const key = `${countryKey}-[chapterId]${chapter.chapterId}`
    const children = getChapterChildren(key, chapter)

    return {
      key,
      title: chapter.title,
      children
    }
  })

export const mapToAccessTree = (contentTree: ContentTreeCountry[]) =>
  contentTree.map(country => {
    const key = `[countryId]${country.countryId}`
    const children = getCountryChildren(key, country)

    return {
      key,
      title: country.title,
      children
    }
  }) ?? []

export const mapToUserAccess = (
  access: string[],
  accessEndDate: string
): UserAccess[] =>
  access.map(accessKey => {
    return accessKey.split('-').reduce<any>(
      (obj, fragment) => {
        const [value] = fragment.match(/\d+/g) ?? [undefined]
        const [key] = fragment.match(/[a-zA-Z]+/g) ?? [undefined]

        if (key && value) {
          obj[key] = +value
        }

        return obj
      },
      { accessEndDate }
    )
  })

export const parseUserAccess = (item: UserAccess) => {
  return [
    { value: item.countryId, type: 'countryId' },
    { value: item.chapterId, type: 'chapterId' },
    { value: item.sectionId, type: 'sectionId' },
    { value: item.subSectionId, type: 'subSectionId' },
    { value: item.tabId, type: 'tabId' }
  ]
    .filter(({ value }) => value)
    .map(({ type, value }) => `[${type}]${value}`)
    .join('-')
}
