import { useState } from 'react'
import { useGetGroupListQuery } from '../store/services/GroupsService'
import { useDebounce } from './useDebounce'

const useGroupOptions = () => {
  const [search, setSearch] = useState('')
  const searchName = useDebounce(search, 300)
  const { data, isFetching: loading } = useGetGroupListQuery({
    searchName,
    pageSize: 10,
    pageNumber: 1
  })
  const groups = data?.groups ?? []
  const options = groups.map(group => ({ value: group.id, text: group.name }))

  return {
    options,
    onSearch: setSearch,
    loading
  }
}

export default useGroupOptions
