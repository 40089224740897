import { Box } from '@veracity/vui'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FormTagMultiselect from '../../../../components/form/FormTagMultiselect'
import { useFormModal } from '../../../../hooks/useFormModal'
import useGroupOptions from '../../../../hooks/useGroupOptions'

const useUserGroupPicker = () => {
  return useFormModal<Option[] | undefined, Values>((initValues, onChange) => (
    <Form initValues={initValues} onChange={onChange} />
  ))
}

interface Option {
  text: string
  value: number
}

interface Values {
  userGroupIds: number[]
  userGroupOptions: Option[]
}

interface Props {
  initValues?: Option[]
  onChange: (values: Values) => void
}
function Form({ initValues = [], onChange }: Props) {
  const { options, onSearch, loading } = useGroupOptions()
  const form = useForm<Values>({
    defaultValues: {
      userGroupIds: initValues?.map(({ value }) => value),
      userGroupOptions: initValues
    }
  })

  const userGroupIds = form.watch('userGroupIds')
  const userGroupOptions = form.watch('userGroupOptions')

  useEffect(() => {
    onChange({ userGroupIds, userGroupOptions })
  }, [userGroupIds, userGroupOptions, onChange])

  return (
    <Box px={3} column>
      <FormTagMultiselect
        name="userGroupIds"
        optionsName="userGroupOptions"
        label=""
        control={form.control}
        onSearch={onSearch}
        loading={loading}
        options={options}
        size="md"
        placeholder="User Group"
        initOptions={initValues}
      />
    </Box>
  )
}

export default useUserGroupPicker
