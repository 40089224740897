const iconPickerOptions = [
  'falChartBar',
  'falChartLine',
  'falChartLineDown',
  'falChartNetwork',
  'falChartPie',
  'falChartPieAlt',
  'falChartScatter',
  'falAnalytics',
  'falWindTurbine',
  'falMapMarkerAlt',
  'falMapMarkerCheck',
  'falWrench',
  'falWind',
  'falWater',
  'falUserChart',
  'falTemperatureHigh',
  'falSolarPanel',
  'falSnowflake',
  'falPlug',
  'falUsers',
  'falBroadcastTower',
  'falTachometer',
  'falBell',
  'falCodeMerge',
  'culFileDownloadAlt'
]

export default iconPickerOptions
