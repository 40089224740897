import { Box, Button, Card, T } from '@veracity/vui'
import { useFormContext } from 'react-hook-form'
import FormInput from '../../../components/form/FormInput'

interface AccessControlProps {
  onSubmit: () => void
}
const AccessControl = ({ onSubmit }: AccessControlProps) => {
  const form = useFormContext()

  return (
    <Box column pb={8} pt={2}>
      <Card p={2} alignItems="center" justifyContent="space-between">
        <Box alignItems="center">
          <T text="Access until:" size="md" weight={600} mr={2} />
          <FormInput
            size="md"
            name="endAccessDate"
            label=""
            control={form.control}
            type="date"
          />
        </Box>
        <Button
          text="Grant access"
          disabled={!form.formState.isValid}
          onClick={onSubmit}
        />
      </Card>
    </Box>
  )
}

export default AccessControl
