import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { parseQuerystring } from '../../utils/url'
import { DataGroupListItem } from './DataGroupsService'
import { Group } from './GroupsService'
import { UserData } from './UsersService'
import { axiosBaseQuery } from './utils/axiosBaseQuery'

export interface CustomerKey {
  id: number
  isActive: boolean
  validTill: string
  dataGroups: DataGroupListItem[]
  user: UserData
  group: Group
}

export interface IntegrationKey {
  id: number
  isActive: boolean
  validTill: string
  dataGroups: DataGroupListItem[]
  name: string
}

interface GetCustomerKeyListRequest {
  search?: string
  pageSize?: number
  pageNumber?: number
  userGroups?: number[]
}

interface GetCustomerKeyListResponse {
  items: CustomerKey[]
  pageNumber: number
  pageSize: number
  total: number
}

interface UpsertCustomerKeyRequest {
  apiKeyId?: number
  isActive?: boolean
  validTill?: string
  userId?: number
  groupId?: number
  dataGroupIds?: number[]
}

interface GetIntegrationKeyListRequest {
  search?: string
  pageSize?: number
  pageNumber?: number
}

interface GetIntegrationKeyListResponse {
  items: IntegrationKey[]
  pageNumber: number
  pageSize: number
  total: number
}

interface UpsertIntegrationKeyRequest {
  apiKeyId?: number
  isActive?: boolean
  validTill?: string
  dataGroupIds?: number[]
  name?: string
}

const apiKeysApi = createApi({
  reducerPath: 'apiKeysApi',
  tagTypes: [
    'CustomerKeys',
    'CustomerKeySecret',
    'IntegrationKeys',
    'IntegrationKeySecret'
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/ApiKey/'
  }),
  endpoints: builder => ({
    getCustomerKeyList: builder.query<
      GetCustomerKeyListResponse,
      GetCustomerKeyListRequest
    >({
      query: filters => ({ url: `Customers?${parseQuerystring(filters)}` }),
      providesTags: (_, __, filters) => [
        { type: 'CustomerKeys', id: parseQuerystring(filters) }
      ]
    }),
    getCustomerKeySecret: builder.query<string, number>({
      query: keyId => ({ url: `Customer/${keyId}/secret` }),
      providesTags: (_, __, id) => [{ type: 'CustomerKeySecret', id }]
    }),
    upsertCustomerKey: builder.mutation<CustomerKey, UpsertCustomerKeyRequest>({
      query: data => ({ url: 'Customer', method: 'POST', data }),
      invalidatesTags: ['CustomerKeys']
    }),
    deleteCustomerKey: builder.mutation<boolean, number>({
      query: keyId => ({ url: `Customer/${keyId}`, method: 'DELETE' }),
      invalidatesTags: ['CustomerKeys']
    }),
    getIntegrationKeyList: builder.query<
      GetIntegrationKeyListResponse,
      GetIntegrationKeyListRequest
    >({
      query: filters => ({ url: `Integrations?${parseQuerystring(filters)}` }),
      providesTags: (_, __, filters) => [
        { type: 'IntegrationKeys', id: parseQuerystring(filters) }
      ]
    }),
    getIntegrationKeySecret: builder.query<string, number>({
      query: keyId => ({ url: `Integration/${keyId}/secret` }),
      providesTags: (_, __, id) => [{ type: 'IntegrationKeySecret', id }]
    }),
    upsertIntegrationKey: builder.mutation<
      IntegrationKey,
      UpsertIntegrationKeyRequest
    >({
      query: data => ({ url: 'Integration', method: 'POST', data }),
      invalidatesTags: ['IntegrationKeys']
    }),
    deleteIntegrationKey: builder.mutation<boolean, number>({
      query: keyId => ({ url: `Integration/${keyId}`, method: 'DELETE' }),
      invalidatesTags: ['IntegrationKeys']
    })
  })
})

export const {
  reducerPath: apiKeysApiReducerPath,
  reducer: apiKeysApiReducer,
  middleware: apiKeysApiMiddleware,
  useGetCustomerKeyListQuery,
  useLazyGetCustomerKeySecretQuery,
  useUpsertCustomerKeyMutation,
  useDeleteCustomerKeyMutation,
  useGetIntegrationKeyListQuery,
  useLazyGetIntegrationKeySecretQuery,
  useUpsertIntegrationKeyMutation,
  useDeleteIntegrationKeyMutation
} = apiKeysApi
