import { Box } from '@veracity/vui'

interface ProgressBarProps {
  value: number
}
const ProgressBar = ({ value }: ProgressBarProps) => (
  <Box h={6} overflow="hidden" position="relative" bg="hsla(210, 17%, 95%, 1)">
    <Box
      position="absolute"
      left={-3}
      h={6}
      w={`calc(${value}% + 6px)`}
      bg={value < 100 ? 'hsla(209, 91%, 72%, 1)' : 'hsla(113, 41%, 45%, 1)'}
      radius={3}
      transition="width .2s ease-in-out, background 1s ease-in-out"
    />
  </Box>
)

export default ProgressBar
