import {
  HeaderAccount,
  HeaderSignIn,
  List,
  LoggedInHeader,
  LoggedOutHeader
} from '@veracity/vui'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import config from '../config'
import usePermissions from '../hooks/usePermissions'
import { RootState } from '../store'
import { userActions } from '../store/slices/user'

const mainLinks = [
  { linkProps: { as: NavLink, to: '/' }, text: 'Home' },
  { linkProps: { as: NavLink, to: '/about' }, text: 'About' },
  {
    linkProps: { href: `mailto:${config.TechnicalSupportEmail}` },
    text: 'Support'
  }
]

const Header = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector<RootState, boolean>(
    state => state.user.isLoggedIn
  )
  const displayName = useSelector<RootState, string | undefined>(
    state => state.user.userInfo?.displayName
  )
  const role = useSelector<RootState, string | undefined>(
    state => state.user.role?.displayName
  )
  const isDemoMode = useSelector<RootState, boolean>(
    state => state.user.isDemoMode
  )

  const { isCustomer } = usePermissions()

  const isDemoModeAvailable = !isCustomer

  const handleEnterDemoMode = () => {
    dispatch(userActions.setDemoMode(true))
  }

  const handleLeaveDemoMode = () => {
    dispatch(userActions.setDemoMode(false))
  }

  return isLoggedIn ? (
    <LoggedInHeader
      mainLinks={mainLinks}
      account={
        <HeaderAccount
          sections={
            <List isInteractive>
              <List.Divider />
              {isDemoModeAvailable && !isDemoMode && (
                <List.Item
                  iconLeft="falEye"
                  text="Enter demonstration mode"
                  onClick={handleEnterDemoMode}
                />
              )}
              {isDemoModeAvailable && isDemoMode && (
                <List.Item
                  iconLeft="falEyeSlash"
                  text="Leave demonstration mode"
                  onClick={handleLeaveDemoMode}
                />
              )}
              <a href="/api/Account/SignOut">
                <List.Item iconLeft="falSignOut" text="Sign out" />
              </a>
            </List>
          }
          userInfo={{ displayName, companyName: role }}
        />
      }
    />
  ) : (
    <LoggedOutHeader
      mainLinks={mainLinks}
      signIn={<HeaderSignIn url="/api/Account/SignIn" />}
    />
  )
}

export default Header

