import { Box } from '@veracity/vui'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useContentStructure from '../../../hooks/useContentStructure'
import { RootState } from '../../../store'
import ChapterForm from './ChapterForm'
import EditHeader from './Header'
import useEditChapter from './useEditChapter'

const EditChapterPage = () => {
  const { country } = useParams()
  const countryId = useSelector(
    (state: RootState) => state.content.countryInfo?.id
  )
  const { chapter } = useContentStructure()

  const { handler: handleSubmit, isLoading } = useEditChapter(
    chapter?.id,
    countryId,
    country
  )

  const initialValues = chapter
    ? {
        title: chapter.title,
        icon: chapter.iconName,
        type: chapter.type,
        groupsId: chapter.groups?.map(group => group.id)
      }
    : undefined

  return (
    <Box column w="100%">
      <EditHeader title="Edit chapter" actions={<div />} />
      <Box column w="100%" flex={1} p={8}>
        <ChapterForm
          key={chapter?.id}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          initOptions={chapter?.groups?.map(group => ({
            value: group.id,
            text: group.name
          }))}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}

export default EditChapterPage

