import { Box } from '@veracity/vui'
import { useParams } from 'react-router-dom'
import useContentStructure from '../../../hooks/useContentStructure'
import EditHeader from './Header'
import SubsectionForm from './SubsectionForm'
import useCreateSubSection from './useCreateSubSection'

const AddSubsectionPage = () => {
  const { country, chapter } = useParams()
  const { section } = useContentStructure()

  const parentWithTabs = (section?.tabs?.length ?? 0) > 0

  const { handler: handleSubmit, isLoading } = useCreateSubSection(
    section?.id,
    section?.slugId,
    chapter,
    country
  )

  return (
    <Box column w="100%">
      <EditHeader title="Add sub section" actions={<div />} />
      <Box column w="100%" flex={1} p={8}>
        <SubsectionForm
          onSubmit={handleSubmit}
          isLoading={isLoading}
          parentWithTabs={parentWithTabs}
        />
      </Box>
    </Box>
  )
}

export default AddSubsectionPage

