import { Box, BoxProps } from '@veracity/vui'
import { ReactNode, useRef, useState } from 'react'
import ResizeWatcher from './ResizeWatcher'

interface CollapseWrapperProps extends BoxProps {
  collapsed: boolean
  children: ReactNode
}

const CollapseWrapper = ({
  collapsed,
  children,
  ...boxProps
}: CollapseWrapperProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [scrollHeight, setScrollHeight] = useState(0)
  const handleResize = () => {
    setScrollHeight(ref.current?.scrollHeight ?? 0)
  }

  return (
    <Box
      {...boxProps}
      ref={ref}
      maxH={collapsed ? 0 : scrollHeight}
      overflow={'hidden'}
      transition="max-height .3s">
      <ResizeWatcher onResize={handleResize}>{children}</ResizeWatcher>
    </Box>
  )
}

export default CollapseWrapper
