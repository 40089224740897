import { Box, Button, Tag } from '@veracity/vui'
import CompactTagList from '../../../../components/CompactTagList'
import Table from '../../../../components/Table'
import { CustomerKey } from '../../../../store/services/ApiKeysService'
import { DataGroupListItem } from '../../../../store/services/DataGroupsService'
import { formatDateTime } from '../../../../utils/format'

interface Props {
  customerKeys: CustomerKey[]
  page: number
  pageSize: number
  total: number
  isLoading?: boolean
  onPageChange: (page: number) => void
  onCopy: (record: any) => void
  onShare: (record: any) => void
  onEdit: (record: any) => void
  onDelete: (record: any) => void
}
const CustomerKeysList = ({
  customerKeys,
  isLoading,
  page,
  pageSize,
  total,
  onPageChange,
  onCopy,
  onShare,
  onEdit,
  onDelete
}: Props) => {
  return (
    <Box column px={8}>
      <Table
        loading={isLoading}
        columns={[
          { key: 'id', dataIndex: 'id' },
          {
            key: 'user',
            dataIndex: ['user', 'userInfo', 'displayName'],
            title: 'User'
          },
          {
            key: 'userGroup',
            dataIndex: ['group', 'name'],
            title: 'User group',
            render(value: string) {
              return <Tag text={value} />
            }
          },
          {
            key: 'dataGroups',
            dataIndex: 'dataGroups',
            title: 'Data groups',
            render(value: DataGroupListItem[]) {
              const tags = value.map(({ name }) => name)

              return <CompactTagList tags={tags} />
            }
          },
          {
            key: 'validTill',
            dataIndex: 'validTill',
            title: 'Valid till',
            render(value) {
              return formatDateTime(value)
            }
          },
          {
            dataIndex: 'id',
            key: 'actions',
            // width: 236,
            width: 196,
            render: (_, record) => (
              <Box alignItems="center" gap={1}>
                <Button
                  variant="secondaryDark"
                  borderRadius={6}
                  text="Copy key"
                  className="show-on-row-hover"
                  onClick={() => onCopy(record)}
                />
                {/* <Button
                  variant="tertiaryDark"
                  isRound
                  icon="falShareAlt"
                  className="show-on-row-hover"
                  onClick={() => onShare(record)}
                /> */}
                <Button
                  variant="subtleRed"
                  isRound
                  icon="falTrashAlt"
                  className="show-on-row-hover"
                  onClick={() => onDelete(record)}
                />
                <Button
                  variant="tertiaryDark"
                  isRound
                  icon="falPen"
                  className="show-on-row-hover"
                  onClick={() => onEdit(record)}
                />
              </Box>
            )
          }
        ]}
        dataSource={customerKeys.map(customerKey => ({
          key: customerKey.id,
          ...customerKey
        }))}
        pagination={{
          pageSize,
          current: page,
          total,
          onChange: onPageChange,
          hideOnSinglePage: true,
          showSizeChanger: false
        }}
      />
    </Box>
  )
}

export default CustomerKeysList
