import { Box, Spinner } from '@veracity/vui'
import { useParams } from 'react-router-dom'
import { useGetDataGroupQuery } from '../../../../store/services/DataGroupsService'
import DataGroupEditForm from './Form'

const DataGroupEditPage = () => {
  const { id } = useParams()
  const { data, isFetching: isLoading } = useGetDataGroupQuery(Number(id), {
    skip: !id
  })

  return (
    <Box column w="100%">
      <Box w="100%" h={64} bg="white" />
      {isLoading ? (
        <Box column p={4}>
          <Spinner size="md" />
        </Box>
      ) : (
        <DataGroupEditForm
          key={id ? `edit-${id}` : 'create'}
          initialData={data}
        />
      )}
    </Box>
  )
}

export default DataGroupEditPage
