import { Box } from '@veracity/vui'
import CSVDataSourceForm from './CSVDataSourceForm'
import DataSourcePreview from './DataSourcePreview'

const CSVDataSource = () => {
  return (
    <Box w="100%" h="100%" overflow="hidden">
      <CSVDataSourceForm />
      <DataSourcePreview />
    </Box>
  )
}

export default CSVDataSource
