import { Editor as TUIEditor } from '@toast-ui/react-editor'
import '@toast-ui/editor/dist/toastui-editor.css'
import './style.css'
import { useLayoutEffect } from 'react'
import chartPlugin from './plugins/chartPlugin'
import useEditorControls from './useEditorControls'
import tablePlugin from './plugins/tablePlugin'
import { TabContent } from '../../store/services/ContentService'
import { useLazyGetDataSourceDataQuery } from '../../store/services/DataSourceService'

interface EditorProps {
  content: TabContent
}

const Editor = ({ content }: EditorProps) => {
  const { ref } = useEditorControls()

  const [fetchDataSource] = useLazyGetDataSourceDataQuery()

  useLayoutEffect(() => {
    ref.current.getInstance().setMarkdown(content.markdown)
  }, [content, ref])

  return (
    <TUIEditor
      ref={ref}
      plugins={[
        chartPlugin({ fetchDataSource, debounce: 1000 }) as any,
        tablePlugin({ fetchDataSource, debounce: 1000 }) as any
      ]}
      hideModeSwitch
      initialEditType="markdown"
      height="420px"
      toolbarItems={[
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        ['image', 'link'],
        ['code', 'codeblock']
      ]}
    />
  )
}

export default Editor
