import { Box } from '@veracity/vui'
import dayjs from 'dayjs'
import { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  useGetUserAccessQuery,
  UserAccess
} from '../../../../../store/services/ContentAccessService'
import { mapToUserAccess, parseUserAccess } from '../../../ContentAccess/utils'
import EditAccessForm from './Form'
import EditAccessHeader from './Header'
import useUpsertAccess from './useUpsertAccess'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object({
  access: yup.array(yup.string()),
  endAccessDate: yup.string().required()
})

interface FormInputs {
  access: string[]
  endAccessDate: string
}

const EditAccessPage = () => {
  const { id } = useParams()
  const { data: userAccess } = useGetUserAccessQuery(Number(id), { skip: !id })

  const access = useMemo(
    () => userAccess?.map(accessItem => parseUserAccess(accessItem)) ?? [],
    [userAccess]
  )
  const endAccessDate =
    userAccess
      ?.map(({ accessEndDate }: UserAccess) => dayjs(accessEndDate))
      .reduce<dayjs.Dayjs | undefined>((earliest, date) => {
        if (date.isBefore(earliest)) return date

        return earliest
      }, undefined)
      ?.format('YYYY-MM-DD') ?? ''

  const form = useForm<FormInputs>({
    defaultValues: {
      access: [],
      endAccessDate: undefined
    },
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  useEffect(() => {
    form.setValue('access', access)
  }, [access, form])

  useEffect(() => {
    form.setValue('endAccessDate', endAccessDate)
  }, [endAccessDate, form])

  const handleUpsertAccess = useUpsertAccess(
    isNaN(Number(id)) ? undefined : Number(id)
  )

  const handleSubmit = () => {
    form.handleSubmit(({ access, endAccessDate }) => {
      const items = mapToUserAccess(access, endAccessDate)
      handleUpsertAccess(items)
    })()
  }

  return (
    <Box column w="100%">
      <EditAccessHeader />
      <Box px={8} py={4}>
        <FormProvider {...form}>
          <EditAccessForm onSubmit={handleSubmit} />
        </FormProvider>
      </Box>
    </Box>
  )
}

export default EditAccessPage
