import { Box } from '@veracity/vui'
import { useParams } from 'react-router-dom'
import useContentStructure from '../../../hooks/useContentStructure'
import EditHeader from './Header'
import SubsectionForm from './SubsectionForm'
import useEditSubSection from './useEditSubSection'

const EditSubsectionPage = () => {
  const { country, chapter } = useParams()
  const { section, subsection } = useContentStructure()

  const { handler: handleSubmit, isLoading } = useEditSubSection(
    subsection?.id,
    section?.id,
    section?.slugId,
    chapter,
    country
  )

  const initialValues = subsection
    ? {
        title: subsection.title,
        groupsId: subsection.groups?.map(group => group.id)
      }
    : undefined

  return (
    <Box column w="100%">
      <EditHeader title="Edit sub section" actions={<div />} />
      <Box column w="100%" flex={1} p={8}>
        <SubsectionForm
          key={subsection?.id}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          initOptions={subsection?.groups?.map(group => ({
            text: group.name,
            value: group.id
          }))}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  )
}

export default EditSubsectionPage

