import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { invalidateContentAccessTags } from '../../../store/services/ContentAccessService'
import {
  TabsStrategy,
  useUpsertSectionMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useCreateSection = (
  chapterId?: number,
  chapterSlugId?: string,
  countryCode?: string
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [createSection] = useUpsertSectionMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'add-section-page',
      loadingMessage: 'Creating new section',
      errorMessage: 'Creating new section failed',
      successMessage: 'Section created successfully'
    })

  return {
    handler: (values: {
      title: string
      tabsStrategy?: TabsStrategy
      groupsId: number[]
    }) => {
      if (isLoading || !chapterId || !chapterSlugId || !countryCode) return

      setLoading(true)
      createSection({ chapterId, ...values })
        .then(
          handlePromiseError(
            ({ data }) => {
              operationSuccess()
              dispatch(invalidateContentAccessTags([{ type: 'ContentTree' }]))
              navigate(
                `/power-analytics/edit/${countryCode}/${chapterSlugId}/${data.slugId}`
              )
            },
            () => operationFail()
          )
        )
        .finally(() => {
          setLoading(false)
        })
    },
    isLoading
  }
}

export default useCreateSection

