import { Box } from '@veracity/vui'
import UsersManagementHeader from './Header'
import { FormProvider, useForm } from 'react-hook-form'
import UserList from './UserList'
import { useGetAllUsersQuery } from '../../../store/services/UsersService'
import { useMemo } from 'react'

interface FormInputs {
  search: string
}
const UsersManagementPage = () => {
  const { data: users, isLoading } = useGetAllUsersQuery()
  const form = useForm<FormInputs>({
    defaultValues: {
      search: ''
    }
  })
  const search = form.watch('search')

  const filteredUsers = useMemo(
    () =>
      users?.filter(user => {
        const {
          userInfo: { firstName, lastName, email },
          role: { displayName },
          groups
        } = user
        return [
          `${firstName} ${lastName}`,
          email,
          displayName,
          ...groups.map(group => group.name)
        ]
          .filter(Boolean)
          .some(value => value.toLowerCase().includes(search.toLowerCase()))
      }) ?? [],
    [search, users]
  )
  return (
    <Box column w="100%">
      <FormProvider {...form}>
        <UsersManagementHeader />
        <UserList users={filteredUsers} isLoading={isLoading} />
      </FormProvider>
    </Box>
  )
}

export default UsersManagementPage

