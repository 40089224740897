import { useNavigate } from 'react-router-dom'
import useAsyncOperationToast from '../../../../../hooks/useAsyncOperationToast'
import {
  UserAccess,
  useUpsertUserAccessMutation
} from '../../../../../store/services/ContentAccessService'
import { handlePromiseError } from '../../../../../store/services/utils/errorHandling'

const useUpsertAccess = (userId?: number) => {
  const navigate = useNavigate()
  const [upsertAccess] = useUpsertUserAccessMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'grant-access',
      loadingMessage: 'Saving user access',
      successMessage: 'User access saved successfully',
      errorMessage: 'Saving user access failed'
    })

  return (items: UserAccess[]) => {
    if (!userId || isLoading) return

    setLoading(true)
    upsertAccess({ userIds: [userId], items })
      .then(
        handlePromiseError(
          () => {
            operationSuccess()
            navigate(-1)
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default useUpsertAccess
