import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { parseQuerystring } from '../../utils/url'
import { axiosBaseQuery } from './utils/axiosBaseQuery'

export interface Region {
  id: number
  name: string
  order?: number
}

interface GetRegionsListRequest {
  search?: string
}

type GetRegionsListResponse = Region[]

type GetRegionResponse = Region

type UpsertRegionResponse = Region

interface UpsertRegionRequest {
  id?: number
  name?: string
  order?: number
}

const regionsApi = createApi({
  reducerPath: 'regionsApi',
  tagTypes: ['Regions', 'Region'],
  baseQuery: axiosBaseQuery({
    baseUrl: '/api/Region/'
  }),
  endpoints: builder => ({
    getRegionsList: builder.query<
      GetRegionsListResponse,
      GetRegionsListRequest
    >({
      query: filters => ({ url: `GetAll?${parseQuerystring(filters)}` }),
      providesTags: (_, __, filters) => [
        { type: 'Regions', id: parseQuerystring(filters) }
      ]
    }),
    getRegion: builder.query<GetRegionResponse, number>({
      query: id => ({ url: `${id}` }),
      providesTags: (_, __, id) => [{ type: 'Region', id }]
    }),
    upsertRegion: builder.mutation<UpsertRegionResponse, UpsertRegionRequest>({
      query: data => ({ url: 'Upsert', method: 'POST', data }),
      invalidatesTags: response =>
        response
          ? ['Regions', { type: 'Region', id: response.id }]
          : ['Regions', 'Region']
    }),
    deleteRegion: builder.mutation<void, number>({
      query: id => ({ url: `${id}`, method: 'DELETE' }),
      invalidatesTags: ['Regions']
    })
  })
})

export const {
  reducerPath: regionsApiReducerPath,
  reducer: regionsApiReducer,
  middleware: regionsApiMiddleware,
  useGetRegionsListQuery,
  useGetRegionQuery,
  useLazyGetRegionQuery,
  useUpsertRegionMutation,
  useDeleteRegionMutation
} = regionsApi
