import { Box, Spinner } from '@veracity/vui'
import DataSourcesGrid from './DataSourcesGrid'
import DataSourcesManagementHeader from './Header'
import { useForm, FormProvider } from 'react-hook-form'
import { useGetDataSourceListQuery } from '../../../store/services/DataSourceService'
import { useDebounce } from '../../../hooks/useDebounce'
import { Pagination } from 'antd'
import { useState } from 'react'

const DataSourcesManagementPage = () => {
  const pageSize = 12
  const [pageNumber, setPageNumber] = useState(1)
  const form = useForm({
    defaultValues: {
      search: ''
    }
  })
  const searchName = useDebounce(form.watch('search'), 500)
  const { data, isFetching } = useGetDataSourceListQuery({
    searchName,
    pageSize,
    pageNumber
  })
  const { dataSourceList, total } = data ?? { dataSourceList: [], total: 0 }

  const handlePageChange = (page: number) => {
    setPageNumber(page)
  }

  return (
    <Box column w="100%">
      <FormProvider {...form}>
        <DataSourcesManagementHeader />
        {isFetching && <Spinner size="md" />}
        {!isFetching && <DataSourcesGrid dataSources={dataSourceList} />}
        <Box px={8} pb={4}>
          <Pagination
            total={total}
            pageSize={pageSize}
            current={pageNumber}
            onChange={handlePageChange}
            disabled={isFetching}
            hideOnSinglePage
          />
        </Box>
      </FormProvider>
    </Box>
  )
}

export default DataSourcesManagementPage
