import { Box, Card, Icon, T } from '@veracity/vui'
import ProgressBar from '../ProgressBar'

export interface UploadingFileType {
  id: number | string
  displayName: string
  type: string
  progress: number
}
interface UploadingFileProps {
  file: UploadingFileType
}
const UploadingFile = ({ file }: UploadingFileProps) => {
  const finished = file.progress >= 100
  const progress = file.progress
  const typeIcon =
    {
      'text/csv': 'falFileCsv',
      'application/pdf': 'falFilePdf',
      'application/vnd.ms-excel': 'falFileExcel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        'falFileExcel'
    }[file.type as string] ?? 'falFileAlt'

  return (
    <Card column p={1.5} w={472}>
      <Box justifyContent="space-between" alignItems="center">
        <Box alignItems="center">
          <Icon name={typeIcon} my={1} mr={2} />
          <T
            text={file.displayName}
            fontWeight={600}
            maxW={200}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          />
        </Box>
        <Box>
          <T
            text={progress + '%'}
            fontWeight={600}
            mr={1}
            color={
              finished ? 'hsla(113, 41%, 45%, 1)' : 'hsla(209, 91%, 72%, 1)'
            }
          />
          <T text={finished ? 'uploaded' : 'uploading...'} fontWeight={600} />
        </Box>
      </Box>
      <ProgressBar value={progress} />
    </Card>
  )
}

export default UploadingFile
