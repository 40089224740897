import { Box, Select } from '@veracity/vui'
import { useMemo } from 'react'
import {
  ChapterType,
  ContentChapter,
  ContentLocation,
  useGetContentStructureQuery
} from '../../store/services/ContentService'
import * as R from 'ramda'

interface LocationOption extends ContentLocation {
  value: string
  text: string
}

const mapStructureToLocations = (chapters?: ContentChapter[]) =>
  chapters
    ?.filter(({ type }) => type === ChapterType.Regular)
    .reduce<LocationOption[]>((acc, chapter) => {
      if (!chapter.sections.length) {
        acc.push({
          chapterId: chapter.id,
          text: chapter.title,
          value: `[chapterId]${chapter.id}`
        })
      }
      chapter.sections.forEach(section => {
        if (!section.subSections.length) {
          acc.push({
            chapterId: chapter.id,
            sectionId: section.id,
            text: `${chapter.title} > ${section.title}`,
            value: `[chapterId]${chapter.id}-[sectionId]${section.id}`
          })
        }
        section.subSections.forEach(subSection => {
          acc.push({
            chapterId: chapter.id,
            sectionId: section.id,
            subSectionId: subSection.id,
            text: `${chapter.title} > ${section.title} > ${subSection.title}`,
            value: `[chapterId]${chapter.id}-[sectionId]${section.id}-[subSectionId]${subSection.id}`
          })
        })
      })

      return acc
    }, []) ?? []

interface MovePromptFormProps {
  onChange: (location: ContentLocation) => void
  countryCode?: string
}
const MovePromptForm = ({ countryCode, onChange }: MovePromptFormProps) => {
  const { data } = useGetContentStructureQuery(countryCode!, {
    skip: !countryCode
  })

  const options = useMemo(() => mapStructureToLocations(data?.chapters), [data])

  const handleChange = (value: string) => {
    const location = options.find(opt => opt.value === value)

    if (location) {
      onChange(
        R.omit<LocationOption, keyof LocationOption>(
          ['text', 'value'],
          location
        )
      )
    }
  }

  return (
    <Box px={3} py={2}>
      <Select onChange={e => handleChange(e.target.value)} options={options} />
    </Box>
  )
}

export default MovePromptForm
