import { useNavigate } from 'react-router-dom'
import useAsyncOperationToast from '../../../../hooks/useAsyncOperationToast'
import { DataGroupListItem } from '../../../../store/services/DataGroupsService'
import { useUpsertDataGroupMutation } from '../../../../store/services/DataGroupsService'
import { handlePromiseError } from '../../../../store/services/utils/errorHandling'

const useSaveDataGroup = () => {
  const [upsertDataGroup] = useUpsertDataGroupMutation()
  const navigate = useNavigate()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'saving-data-group',
      loadingMessage: 'Saving data group',
      successMessage: 'Data group saved successfully',
      errorMessage: 'Saving data group failed'
    })

  return (data: Partial<DataGroupListItem>) => {
    if (isLoading) return

    setLoading(true)
    upsertDataGroup(data)
      .then(
        handlePromiseError(
          () => {
            navigate(-1)
            operationSuccess()
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }
}

export default useSaveDataGroup
