import styled from 'styled-components'

const Divider = styled.hr`
  height: 144px;
  min-width: 3px;
  background-color: #e6e6e5;
  margin: 0 30px;
`

export default Divider
