import { useNavigate } from 'react-router-dom'
import ContentHeader from '../../../../../components/layouts/Content/ContentHeader'

const EditUserHeader = () => {
  const navigate = useNavigate()

  return (
    <ContentHeader
      displayPath={['Edit User']}
      variant="dark"
      onBack={() => navigate(-1)}
    />
  )
}

export default EditUserHeader
