import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import AnonymousUserPage from './AnonymousUser'
import SignedInUserPage from './SignedIn'

const HomePage = () => {
  const isLoggedIn = useSelector<RootState, boolean>(
    state => state.user.isLoggedIn
  )

  return <div>{isLoggedIn ? <SignedInUserPage /> : <AnonymousUserPage />}</div>
}

export default HomePage
