import { Box } from '@veracity/vui'
import { ContentFile } from '../../store/services/ContentService'
import FileListItem from './FileListItem'

interface FileListViewerProps {
  value: ContentFile[]
}
const FileListViewer = ({ value }: FileListViewerProps) => {
  return (
    <Box column gap={1}>
      {value.map(file => (
        <FileListItem key={file.id} file={file} />
      ))}
    </Box>
  )
}

export default FileListViewer
