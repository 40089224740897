import { useDialog } from '@veracity/vui'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useDeleteDataSourceMutation } from '../../../store/services/DataSourceService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useDeleteDataSource = (dataSourceId?: number) => {
  const { showConfirm } = useDialog()
  const [deleteDataSource] = useDeleteDataSourceMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'list-data-source-delete',
      loadingMessage: 'Deleting data source',
      successMessage: 'Data source deleted successfully',
      errorMessage: 'Deleting data source failed'
    })

  return () => {
    if (!dataSourceId || isLoading) return

    showConfirm(
      'Delete data source',
      'Are you sure you want to remove this data source?'
    ).then(confirm => {
      if (confirm) {
        setLoading(true)
        deleteDataSource(dataSourceId)
          .then(
            handlePromiseError(
              () => operationSuccess(),
              () => operationFail()
            )
          )
          .finally(() => setLoading(false))
      }
    })
  }
}

export default useDeleteDataSource
