import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@veracity/vui'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FormInput from '../../../components/form/FormInput'
import { Region } from '../../../store/services/RegionsService'
import * as yup from 'yup'

const schema = yup
  .object({
    name: yup.string().min(1).required()
  })
  .required()

interface RegionFormProps {
  initValue: Partial<Region>
  onChange: (value: Partial<Region>) => void
  onValidChange: (valid: boolean) => void
}
const RegionForm = ({
  initValue,
  onChange,
  onValidChange
}: RegionFormProps) => {
  const form = useForm({
    defaultValues: initValue,
    resolver: yupResolver(schema),
    mode: 'onChange'
  })

  const values = form.watch()
  const valid = form.formState.isValid

  useEffect(() => {
    onChange(values)
  }, [values, onChange])

  useEffect(() => {
    onValidChange(valid)
  }, [valid, onValidChange])

  return (
    <Box column px={3}>
      <FormInput name="name" label="Name" control={form.control} size="md" />
    </Box>
  )
}

export default RegionForm
