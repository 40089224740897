import { Box, Menu } from '@veracity/vui'
import { stopPropagation } from '../../../utils/event'
import useNavMode, { Mode } from './useNavMode'

interface ChapterControlProps {
  onEditChapter: () => void
  onDeleteChapter?: () => void
  onCloneChapter?: () => void
  onCopyChapter?: () => void
  onAddSection?: () => void
  onPublish?: () => void
}
const ChapterControl = ({
  onAddSection,
  onEditChapter,
  onDeleteChapter,
  onCloneChapter,
  onCopyChapter,
  onPublish
}: ChapterControlProps) => {
  const mode = useNavMode()
  const disabled = mode === Mode.New

  if (mode === Mode.Read) return null

  return (
    <Box>
      <Menu>
        <Menu.Button icon="falEllipsisV" isRound disabled={disabled} />
        <Menu.List w={240}>
          {onPublish && (
            <Menu.Item
              iconLeft="culGlobePlus"
              onClick={stopPropagation(onPublish)}>
              Publish chapter
            </Menu.Item>
          )}
          {onAddSection && (
            <Menu.Item
              iconLeft="falPlus"
              onClick={stopPropagation(onAddSection)}>
              Add section
            </Menu.Item>
          )}
          <Menu.Item
            iconLeft="falPencil"
            onClick={stopPropagation(onEditChapter)}>
            Edit chapter
          </Menu.Item>
          {onCloneChapter && (
            <Menu.Item
              iconLeft="falCopy"
              onClick={stopPropagation(onCloneChapter)}>
              Clone chapter
            </Menu.Item>
          )}
          {onCopyChapter && (
            <Menu.Item
              iconLeft="falShareAll"
              onClick={stopPropagation(onCopyChapter)}>
              Copy chapter
            </Menu.Item>
          )}
          {onDeleteChapter && (
            <Menu.Item
              iconLeft="falTrashAlt"
              onClick={stopPropagation(onDeleteChapter)}>
              Delete chapter
            </Menu.Item>
          )}
        </Menu.List>
      </Menu>
    </Box>
  )
}

export default ChapterControl

