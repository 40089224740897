import { useDialog } from '@veracity/vui'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { useDeleteSectionMutation } from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'

const useDeleteSection = (sectionId?: number) => {
  const { showConfirm } = useDialog()
  const [deleteSection] = useDeleteSectionMutation()
  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'section-delete',
      loadingMessage: 'Deleting section',
      successMessage: 'Section deleted successfully',
      errorMessage: 'Deleting section failed'
    })

  return () => {
    if (!sectionId || isLoading) return

    showConfirm(
      'Delete section',
      'Are you sure you want to delete this section?'
    ).then(agree => {
      if (agree) {
        setLoading(true)
        deleteSection(sectionId)
          .then(
            handlePromiseError(
              () => operationSuccess(),
              () => operationFail()
            )
          )
          .finally(() => setLoading(false))
      }
    })
  }
}

export default useDeleteSection
