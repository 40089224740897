import ErrorCard from '../../components/ErrorCard'

const ErrorPage = () => (
  <ErrorCard
    title="Something went wrong"
    description="Please contact technical support if the error still occurs."
  />
)

export default ErrorPage
