import { Box, Button, Card, DragAndDrop, Heading, Icon, T } from '@veracity/vui'
import { useFormContext, useController } from 'react-hook-form'
import { csvParseRows } from 'd3'
import { up } from '@xstyled/system'

const CSVDataSourceForm = () => {
  const form = useFormContext()
  const {
    field: { value: uploadedFile, onChange: onFileUpload }
  } = useController({ name: 'file', control: form.control })
  const handleUploadFile = ([file]: File[]) => {
    onFileUpload(file)
    applyFile(file)
  }

  const applyFile = async (file: File) => {
    if (file) {
      const csvString = await new Promise<string>((res, rej) => {
        const reader = new FileReader()
        reader.onerror = ev => rej(ev.target?.error)
        reader.onload = ev => res(ev.target?.result as string)
        reader.readAsText(file as Blob)
      })
      const data = csvParseRows(csvString)
      form.setValue('preview', data)
    }
  }

  if (uploadedFile){
    applyFile(uploadedFile)
  }

  return (
    <Box bg="white" column alignItems="center" overflow="auto" w={364}>
      <DragAndDrop
        accept=".csv"
        alignItems="center"
        borderRadius="50%"
        multiple={false}
        h={250}
        mx="auto"
        w={250}
        m={4}
        onFilesAdded={handleUploadFile}>
        <Box column alignItems="center">
          <Icon mb={3} name="falFileCsv" size="xl" />
          <T fontWeight={600}>Drop csv file here</T>
          <T>or click to upload</T>
        </Box>
      </DragAndDrop>
      {uploadedFile && (
        <Box
          w="100%"
          column
          px={4}
          mb={4}
          justifyContent="space-between"
          position="relative">
          <Box mb={1} justifyContent="space-between" alignItems="center">
            <Heading
              size="h6"
              text={
                'Uploaded file:'
              }
            />
            <Button
              size="sm"
              icon="falTrashAlt"
              variant="tertiaryDark"
              isRound
              onClick={() => {onFileUpload(null)}}
            />
          </Box>
          <Card w="100%" p={1}>
            <Heading
              size="h6"
              text={uploadedFile.name}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            />
          </Card>
        </Box>
      )}
    </Box>
  )
}

export default CSVDataSourceForm
