import useAsyncOperationToast from '../../../../../hooks/useAsyncOperationToast'
import { useFormModal } from '../../../../../hooks/useFormModal'
import {
  CustomerKey,
  useUpsertCustomerKeyMutation
} from '../../../../../store/services/ApiKeysService'
import { handlePromiseError } from '../../../../../store/services/utils/errorHandling'
import CustomerKeyEditForm from './Form'

interface FormResponse {
  id?: number
  userGroupId: number
  userId: number
  dataGroupIds: number[]
  validTill: string
}

interface UpsertRequest {
  apiKeyId?: number
  groupId: number
  userId: number
  dataGroupIds: number[]
  validTill: string
}

const useCustomerKeyEdit = () => {
  const [upsertKey] = useUpsertCustomerKeyMutation()
  const { isLoading, setLoading, operationFail, operationSuccess } =
    useAsyncOperationToast({
      id: 'upsert-customer-key',
      loadingMessage: 'Submitting form',
      successMessage: 'Form submitted successfully',
      errorMessage: 'Form submitting failed'
    })
  const openModal = useFormModal<CustomerKey | undefined, FormResponse>(
    (initValues, onChange, onValid) => (
      <CustomerKeyEditForm
        initValues={initValues}
        onChange={onChange}
        onValid={onValid}
      />
    )
  )

  return (initValues?: CustomerKey) =>
    openModal(
      initValues,
      !initValues ? 'Create Customer Key' : 'Edit Customer Key'
    ).then(values => {
      if (!values || isLoading) return

      const data: UpsertRequest = {
        userId: values.userId,
        groupId: values.userGroupId,
        dataGroupIds: values.dataGroupIds,
        validTill: values.validTill
      }

      if (initValues?.id !== undefined) {
        data.apiKeyId = initValues.id
      }

      setLoading(true)
      upsertKey(data)
        .then(
          handlePromiseError(
            () => operationSuccess(),
            () => operationFail()
          )
        )
        .finally(() => setLoading(false))
    })
}

export default useCustomerKeyEdit
