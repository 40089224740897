import { Control, useController } from 'react-hook-form'
import IconPicker from '../IconPicker'
import FormLabel from './FormLabel'

interface FormIconPickerProps {
  name: string
  label: string
  control: Control<any>
}
const FormIconPicker = ({ name, label, control }: FormIconPickerProps) => {
  const {
    field: { value, onChange }
  } = useController({ name, control })

  return (
    <FormLabel text={label}>
      <IconPicker value={value} onChange={onChange} />
    </FormLabel>
  )
}

export default FormIconPicker
