import { Box, Icon } from '@veracity/vui'
import { useEffect, useMemo } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import useAsyncOperationToast from '../../../hooks/useAsyncOperationToast'
import { RootState } from '../../../store'
import {
  ChapterType,
  ContentChapter,
  useCopyContentMutation
} from '../../../store/services/ContentService'
import { handlePromiseError } from '../../../store/services/utils/errorHandling'
import { contentActions } from '../../../store/slices/content'
import { useCountryCopyPrompt } from '../../CountryCopyPrompt'
import EllipsisText from '../../EllipsisText'
import UpdateIndicator from '../../UpdateIndicator'
import ChapterControl from './ChapterControl'
import NavSections from './NavSections'
import useCloneChapter from './useCloneChapter'
import useDeleteChapter from './useDeleteChapter'
import { Mode } from './useNavMode'
import usePublishChapter from './usePublishChapter'

interface NavChapterProps {
  chapter: ContentChapter
  mode: Mode
  country?: string
  countryId?: number
  draggable: DraggableProvided
  onNavigate: (path: string[]) => void
}

const NavChapter = ({
  chapter,
  country,
  countryId,
  mode,
  draggable,
  onNavigate
}: NavChapterProps) => {
  const {
    id,
    slugId,
    iconName,
    title,
    sections,
    type,
    isAccessible,
    isPublished,
    modificationStatus
  } = chapter
  const dispatch = useDispatch()
  const params = useParams()
  const active =
    params.chapter === slugId &&
    params.section === undefined &&
    params.subsection === undefined
  const editMode = mode === Mode.Edit
  const readMode = mode === Mode.Read
  const disabledMode = mode === Mode.New
  const haveChildNodes = sections.length > 0
  const isLocked = !isAccessible && !haveChildNodes
  const isViewed = useSelector<RootState, boolean>(state =>
    state.content.viewedChapters.includes(id)
  )

  const background = useMemo(() => {
    if (disabledMode) return 'hsla(210, 17%, 95%, 1)'
    if (active) return 'blue.10'

    return undefined
  }, [disabledMode, active])

  const navigate = useNavigate()
  const showCopyPrompt = useCountryCopyPrompt(country ?? '')
  const [copyChapter] = useCopyContentMutation()

  useEffect(() => {
    if (haveChildNodes) {
      dispatch(contentActions.setViewedChapter(id))
    }
  }, [haveChildNodes, id, dispatch])

  const handleNavigate = () => {
    if (type === ChapterType.Updates) {
      return navigate(`/power-analytics/updates/${country}/${slugId}`)
    }
    if (!disabledMode && !haveChildNodes) {
      dispatch(contentActions.setViewedChapter(id))
      return onNavigate([slugId])
    }
  }

  const handleEditChapter = () => {
    if (!country) return

    navigate(`/power-analytics/edit/${country}/${slugId}/edit-chapter`)
  }

  const handleDeleteChapter = useDeleteChapter(id)

  const handleCloneChapter = useCloneChapter(id)

  const handlePublishChapter = usePublishChapter(countryId, id)

  const handleAddSection = () => {
    if (!country) return

    navigate(`/power-analytics/edit/${country}/${slugId}/add-section`)
  }

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'copy-chapter',
      loadingMessage: 'Copying chapter',
      successMessage: 'Chapter copied successfully',
      errorMessage: 'Copying chapter failed'
    })

  const handleCopyChapter = () => {
    showCopyPrompt('Copy chapter to').then(location => {
      if (location && !isLoading) {
        setLoading(true)
        copyChapter({
          item: { countryId, chapterId: id },
          newPlace: location
        })
          .then(
            handlePromiseError(
              () => operationSuccess(),
              () => operationFail()
            )
          )
          .finally(() => setLoading(false))
      }
    })
  }

  return (
    <Box
      {...draggable.draggableProps}
      ref={draggable.innerRef}
      pl={2.5}
      pr={1}
      py={1}
      justifyContent="center"
      borderBottom="solid 1px"
      borderBottomColor="#E1E6E9"
      column>
      <Box
        pl={2.5}
        pr={1}
        py={1}
        ml={-2.5}
        mr={-1}
        my={-1}
        alignItems="center"
        justifyContent="space-between"
        bg={background}
        hoverBg={background ?? 'blue.10'}
        cursor={disabledMode || haveChildNodes ? 'default' : 'pointer'}
        onClick={handleNavigate}>
        <Box>
          {readMode && <Icon name={iconName} />}
          <Box
            {...draggable.dragHandleProps}
            display={editMode ? undefined : 'none'}>
            <Icon name="falBars" />
          </Box>
          {disabledMode && <Icon color="hsl(0, 0%, 57%, 1)" name="falBars" />}
          <UpdateIndicator
            isViewed={isViewed || editMode}
            status={modificationStatus}
            ml={2.5}>
            <EllipsisText
              size="md"
              weight={600}
              text={title}
              textTransform="uppercase"
              color={disabledMode ? 'hsl(0, 0%, 57%, 1)' : undefined}
            />
          </UpdateIndicator>
        </Box>
        <ChapterControl
          onAddSection={
            type === ChapterType.Regular ? handleAddSection : undefined
          }
          onEditChapter={handleEditChapter}
          onDeleteChapter={
            type !== ChapterType.Updates ? handleDeleteChapter : undefined
          }
          onCloneChapter={
            type !== ChapterType.Updates ? handleCloneChapter : undefined
          }
          onCopyChapter={
            type !== ChapterType.Updates ? handleCopyChapter : undefined
          }
          onPublish={!isPublished ? handlePublishChapter : undefined}
        />
        {isLocked && <Icon name="falLockAlt" h={20} w={20} />}
      </Box>
      {!disabledMode && country && (
        <NavSections
          sections={sections}
          onNavigate={(path: string[]) => onNavigate([slugId, ...path])}
          country={country}
          chapter={slugId}
          chapterId={id}
        />
      )}
    </Box>
  )
}

export default NavChapter

