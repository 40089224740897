import { Box, Button, Tag } from '@veracity/vui'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import Table from '../../../components/Table'
import { Group } from '../../../store/services/GroupsService'
import { UserData } from '../../../store/services/UsersService'
import { formatDateTime } from '../../../utils/format'
import AlterRole from './AlterRole'

interface UserListProps {
  users: UserData[]
  isLoading: boolean
}
const UserList = ({ users, isLoading }: UserListProps) => {
  const dataSource = users.map(user => ({
    ...user,
    key: user.id,
    name: `${user.userInfo.firstName} ${user.userInfo.lastName}`
  }))

  return (
    <Box column px={8} py={1.5}>
      <Table
        loading={isLoading}
        style={{ width: '100%' }}
        pagination={{
          hideOnSinglePage: true
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            className: 'bold'
          },
          {
            title: 'Email',
            dataIndex: ['userInfo', 'email'],
            key: 'email',
            sorter: true
          },
          {
            title: 'Group',
            dataIndex: 'groups',
            key: 'groups',
            render: value => (
              <Box gap={1}>
                {value.map((group: Group) => (
                  <Tag key={group.id} text={group.name} />
                ))}
              </Box>
            )
          },
          {
            title: 'Role',
            dataIndex: ['role', 'roleId'],
            key: 'role',
            render: (value, { id }) => <AlterRole role={value} userId={id} />
          },
          {
            title: 'Last login',
            dataIndex: 'lastLoginTime',
            key: 'lastLogin',
            sorter: (a, b) =>
              dayjs(a.lastLoginTime).isBefore(dayjs(b.lastLoginTime)) ? -1 : 1,
            render: value => formatDateTime(value)
          },
          {
            title: null,
            dataIndex: 'id',
            key: 'actions',
            width: 32,
            render: id => (
              <Link to={`${id}`}>
                <Button
                  variant="tertiaryDark"
                  isRound
                  icon="falGlassesAlt"
                  className="show-on-row-hover"
                />
              </Link>
            )
          }
        ]}
        dataSource={dataSource}
      />
    </Box>
  )
}

export default UserList

