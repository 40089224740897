import { useNavigate } from 'react-router-dom'
import useAsyncOperationToast from '../../../../hooks/useAsyncOperationToast'
import {
  DataSourceType,
  useUpsertDataSourceWithDBMutation,
  useUpsertDataSourceWithFileMutation,
  useUpsertDataSourceWithStringMutation
} from '../../../../store/services/DataSourceService'
import { handlePromiseError } from '../../../../store/services/utils/errorHandling'

interface CallbackParams {
  id: number
  type: DataSourceType
  name: string
  data: string[][]
  file: File
  parameters: {
    dbName?: string
    entityName?: string
  }
}

const useUpdateDataSource = () => {
  const navigate = useNavigate()

  const [updateWithFile] = useUpsertDataSourceWithFileMutation()
  const [updateWithData] = useUpsertDataSourceWithStringMutation()
  const [updateWithDB] = useUpsertDataSourceWithDBMutation()

  const { isLoading, setLoading, operationSuccess, operationFail } =
    useAsyncOperationToast({
      id: 'update-data-source',
      loadingMessage: 'Saving data source',
      successMessage: 'Data source saved successfully',
      errorMessage: 'Saving data source failed'
    })

  const updateDataSourceFromCSVFile = (
    id: number,
    name: string,
    file: File
  ) => {
    if (isLoading || !id) return
    const data = new FormData()
    data.append('name', name)
    data.append('id', id.toString())
    if (file) {
      data.append('content', file)
    }
    setLoading(true)
    updateWithFile(data)
      .then(
        handlePromiseError(
          () => {
            operationSuccess()
            navigate(-1)
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }

  const updateDataSourceFromManualData = (
    id: number,
    name: string,
    data: string[][]
  ) => {
    if (isLoading || !id) return
    setLoading(true)
    updateWithData({ id, name, data })
      .then(
        handlePromiseError(
          () => {
            operationSuccess()
            navigate(-1)
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }

  const updateDataSourceFromDBConfig = (
    id: number,
    name: string,
    dbName?: string,
    entityName?: string
  ) => {
    if (isLoading || !dbName || !entityName) return

    setLoading(true)
    updateWithDB({ id, name, parameters: { dbName, entityName } })
      .then(
        handlePromiseError(
          () => {
            operationSuccess()
            navigate(-1)
          },
          () => operationFail()
        )
      )
      .finally(() => setLoading(false))
  }

  return ({ id, type, name, data, file, parameters }: CallbackParams) => {
    if (type === DataSourceType.CSV)
      return updateDataSourceFromCSVFile(id, name, file)
    if (type === DataSourceType.Manual)
      return updateDataSourceFromManualData(id, name, data)
    if (type === DataSourceType.DB)
      return updateDataSourceFromDBConfig(
        id,
        name,
        parameters.dbName,
        parameters.entityName
      )
  }
}

export default useUpdateDataSource
